/**
 * 路外停车场
 */
const paikingPark = [
  // 路外停车场 —— 停车场信息 Parking Information
  {
    path: '/routw-parking-information',
    name: 'routwParkingInformation',
    component: () => import('../view/parkingManagement/views/outPark/outYardManagement/ParkingInformation/index.vue')
  },
  // // 路外停车场 —— 停车场信息 -- 通行策略
  // {
  //   path: '/parking-access-strategy/:parkId',
  //   name: 'parkingAccessStrategy',
  //   component: () => import('../view/outParking/accessStrategy.vue'),
  //   props: true
  // },
  // // 路外停车场 —— 停车场信息 Parking Information 查看停车场
  // {
  //   path: '/routw-look-parking',
  //   name: 'routwlookParking',
  //   component: () => import('../view/outParking/lookOutParkingInfo.vue')
  // },
  // // 路外停车场 —— 停车场信息 Parking Information 设备配置
  // {
  //   path: '/routw-device-layout/:id/:name',
  //   name: 'routwdeviceLayout',
  //   component: () => import('../view/outParking/deviceLayout.vue'),
  //   props: true
  // },
  // // 路外停车场 —— 停车场信息 Billing configuration 计费配置
  // {
  //   path: '/routw-billing-configuration',
  //   name: 'routwbillingConfiguration',
  //   component: () => import('../view/curbPark/billingConfiguration.vue'),
  //   props: true
  // },
  // 出入记录 - 停车记录
  {
    path: '/routw-accessre-cordpage',
    name: 'routwaccessrecordpage',
    component: () => import('../view/parkingManagement/views/outPark/outTrafficRecords/accessrecordpage/index.vue')
  },
  // // 出入记录 -  查看记录
  // {
  //   path: '/routw-search-recordpage/:id',
  //   name: 'routwsearchrecordpage',
  //   component: () => import('../view/curbPark/accessRecords/searchrecordpage.vue'),
  //   props: true
  // },
  // 出入记录 -  抓拍记录
  {
    path: '/capture-record',
    name: 'captureRecord',
    component: () => import('../view/parkingManagement/views/outPark/outTrafficRecords/captureRecord/index.vue'),
    props: true
  },
  // 卡片管理 -  计时卡 /time-card
  {
    path: '/routw-time-card',
    name: 'routwtimeCard',
    component: () => import('../view/parkingManagement/views/cardManagement/timeCard/index.vue')
  },
  // // 卡片管理 -  新增计时卡 /add-time-card
  // {
  //   path: '/routw-add-time-card/:id',
  //   name: 'routwaddTimeCard',
  //   component: () => import('../view/curbPark/cardmanage/addTimeCard.vue'),
  //   props: true
  // },
  // // 卡片管理 -  查看卡片信息 /look-card-detail
  // {
  //   path: '/routw-look-card-detail/:cardType/:id',
  //   name: 'routwlookCardDetail',
  //   component: () => import('../view/curbPark/cardmanage/lookCardDetail.vue'),
  //   props: true
  // },
  // 卡片管理 -  月租卡 /month-card
  {
    path: '/routw-month-card',
    name: 'routwmonthCard',
    component: () => import('../view/parkingManagement/views/cardManagement/monthCards/index.vue')
  },
  // // 卡片管理 -  新增月租卡 /add-month-card
  // {
  //   path: '/routw-add-month-card/:id',
  //   name: 'routwaddMonthCard',
  //   component: () => import('../view/curbPark/cardmanage/addMonthCard.vue'),
  //   props: true
  // },
  // // 卡片管理 -  月租卡续费 /month-card-renewals
  // {
  //   path: '/routw-month-card-renewals/:id',
  //   name: 'routwmonthCardRenewals',
  //   component: () => import('../view/curbPark/cardmanage/monthCardRenewals.vue'),
  //   props: true
  // },
  // 卡片管理 -  储值卡 /stored-card
  {
    path: '/routw-stored-card',
    name: 'routwstoredCard',
    component: () => import('../view/parkingManagement/views/cardManagement/storedCard/index.vue')
  },
  // // 卡片管理 -  新增储值卡 /add-stored-card
  // {
  //   path: '/routw-add-stored-card/:id',
  //   name: 'routwaddStoredCard',
  //   component: () => import('../view/curbPark/cardmanage/addStoredCard.vue'),
  //   props: true
  // },
  // // 卡片管理 -  储值卡充值 /stored-card-pay
  // {
  //   path: '/routw-stored-card-pay/:id',
  //   name: 'routwstoredCardPay',
  //   component: () => import('../view/curbPark/cardmanage/storedCardPay.vue'),
  //   props: true
  // },
  // 卡片管理 -  贵宾卡 /vip-card
  {
    path: '/routw-vip-card',
    name: 'routwvipCard',
    component: () => import('../view/parkingManagement/views/cardManagement/vipCard/index.vue')
  },
  // // 卡片管理 -  新增贵宾卡 /add-vip-card
  // {
  //   path: '/routw-add-vip-card/:id',
  //   name: 'routwaddVipCard',
  //   component: () => import('../view/curbPark/cardmanage/addVipCard.vue'),
  //   props: true
  // },
  // 订单管理 -  异常订单
  {
    path: '/routw-abnormal-order',
    name: 'routwabnormalorder',
    component: () => import('../view/parkingManagement/views/orderManagement/abnormalorder/index.vue')
  },
  // // 订单管理 -  异常订单/配置规则
  // {
  //   path: '/routw-abnormal-order-config',
  //   name: 'routwabnormalorderconfig',
  //   component: () => import('../view/curbPark/ordermanage/abnormalorderconfig.vue')
  // },
  // 订单管理 -  订单申诉
  {
    path: '/routw-order-appeal',
    name: 'routworderappeal',
    component: () => import('../view/parkingManagement/views/orderManagement/orderappeal/index.vue')
  },
  // 订单管理 -  退款记录
  {
    path: '/routw-refund-list',
    name: 'routwrefundList',
    component: () => import('../view/parkingManagement/views/orderManagement/refundList/index.vue')
  },
  // // 订单管理 -  退款详情
  // {
  //   path: '/routw-refund-detail/:id',
  //   name: 'routwrefundDetail',
  //   component: () => import('../view/curbPark/ordermanage/refundDetail.vue'),
  //   props: true
  // },
  // 订单管理 -  订单信息
  {
    path: '/routw-order-info',
    name: 'routworderinfo',
    component: () => import('../view/parkingManagement/views/orderManagement/orderinfo/index.vue')
  },
  // // 订单管理 -  订单信息/查看
  // {
  //   path: '/routw-order-info-look/:id',
  //   name: 'routworderinfolook',
  //   component: () => import('../view/curbPark/ordermanage/orderinfolook.vue'),
  //   props: true
  // },
  // 设备管理 ———— 车牌识别相机 camera
  {
    path: '/routw-camera',
    name: 'routCamera',
    component: () => import('../view/parkingManagement/views/outPark/outEquipmentManagement/camera/index.vue')
  },
  // // 设备管理 ———— 查看车牌识别相机 /look-camera
  // {
  //   path: '/routw-look-camera/:id',
  //   name: 'routwlookCamera',
  //   component: () => import('../view/deviceManagement/lookCamera.vue'),
  //   props: true
  // },
  // 设备管理 ———— 显示屏 /led-screen
  {
    path: '/led-screen',
    name: 'ledScreen',
    component: () => import('../view/parkingManagement/views/outPark/outEquipmentManagement/ledScreen/index.vue')
  },
  // // 设备管理 ———— 查看显示屏  /look-led-screen
  // {
  //   path: '/look-led-screen/:id',
  //   name: 'lookLedScreen',
  //   component: () => import('../view/deviceManagement/lookLedScreen.vue'),
  //   props: true
  // },
  // // 设备管理 ———— 显示屏设备配置  /led-screen-layout
  // {
  //   path: '/led-screen-layout/:id/:name',
  //   name: 'ledScreenLayout',
  //   component: () => import('../view/deviceManagement/ledScreenLayout.vue'),
  //   props: true
  // },
  // 设备管理 ———— 一体机 /oneness-machine
  {
    path: '/oneness-machine',
    name: 'onenessMachine',
    component: () => import('../view/parkingManagement/views/outPark/outEquipmentManagement/onenessMachine/index.vue')
  },
  // // 设备管理 ———— 查看一体机  /look-oneness-machine
  // {
  //   path: '/look-oneness-machine/:id',
  //   name: 'lookOnenessMachine',
  //   component: () => import('../view/deviceManagement/lookOnenessMachine.vue'),
  //   props: true
  // },
  // 计费方案 -- 临停计费方案 timekeeping-project
  {
    path: '/routw-timekeeping-project',
    name: 'routwtimekeepingProject',
    component: () => import('../view/parkingManagement/views/billingScheme/timekeepingProject/index.vue')
  },
  // // 计费方案 -- 新增编辑计时卡计费方案 add-timekeeping-project
  // {
  //   path: '/routw-add-timekeeping-project',
  //   name: 'routwaddTimekeepingProject',
  //   component: () => import('../view/billingProject/addTimekeepingProject.vue')
  // },
  // 计费方案 -- 月租卡计费方案 month-project
  {
    path: '/routw-month-project',
    name: 'routwmonthProject',
    component: () => import('../view/parkingManagement/views/billingScheme/monthProject/index.vue')
  },
  // // 计费方案 -- 新增编辑月租卡计费方案 add-month-project
  // {
  //   path: '/routw-add-month-project',
  //   name: 'routwaddMonthProject',
  //   component: () => import('../view/billingProject/addMonthProject.vue')
  // },
  // 计费方案 -- 时段计费方案 interval-project
  {
    path: '/routw-interval-project',
    name: 'routwintervalProject',
    component: () => import('../view/parkingManagement/views/billingScheme/intervalProject/index.vue')
  },
  // // 计费方案 -- 新增修改时段计费方案 add-interval-project
  // {
  //   path: '/routw-add-interval-project',
  //   name: 'routwaddIntervalProject',
  //   component: () => import('../view/billingProject/addIntervalProject.vue')
  // },
  // 计费方案 -- 车型计费方案 model-project
  {
    path: '/routw-model-project',
    name: 'routwmodelProject',
    component: () => import('../view/parkingManagement/views/billingScheme/modelProject/index.vue')
  }
  // // 计费方案 -- 新增修改车型计费方案 add-model-project
  // {
  //   path: '/routw-add-model-project',
  //   name: 'routwAddModelProject',
  //   component: () => import('../view/billingProject/addModelProject.vue')
  // }
]
export default paikingPark
