import Vue from 'vue'
import VueRouter from 'vue-router'
import utils from '../utils/utils'
import curbPark from './curbPark'
import financialStatement from './financialStatement'
import systemModule from './systemModule'
import warningList from './warning'
import administration from './administration'
import monitoring from './monitoring'
import assetsManagement from './assetsManagement'
import maintenanceCenter from './maintenanceCenter'
import paikingPark from './parkingPark'
import discountCouponList from './discountCoupon'
import security from './security'
import dataAnalysis from './dataAnalysis'
import juewuAiChatList from './juewuAiChat'
Vue.use(VueRouter)
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  // mode: 'history',
  base: '/webmanage/',
  // scrollBehavior: () => {
  //   history.pushState(null, null, document.URL)
  // },
  routes: [
    { path: '/', redirect: '/home' },
    // 登录页面
    {
      path: '/login',
      name: 'Login',
      component: () => import('../view/Login.vue')
    },
    // 主页
    {
      path: '/home',
      name: 'Home',
      // redirect: '/commend-center',
      component: () => import('../view/homeNavigation/views/index.vue'),
      children: [
        // 远程呼叫
        {
          path: '/home/ychjgzt',
          name: 'ychjpage',
          component: () => import('../view/homeNavigation/views/remoteCallViews/index.vue')
        },
        {
          path: '/home/call-recording',
          name: 'callRecording',
          component: () => import('../view/homeNavigation/views/remoteCallViews/callRecording.vue')
        },
        {
          path: '/home/switch-record',
          name: 'switchRecord',
          component: () => import('../view/homeNavigation/views/remoteCallViews/switchRecord.vue')
        },
        // 明厨亮灶显示页面
        {
          path: '/custom-big-screen/mclz',
          name: 'mclzPage',
          component: () => import('../view/otherPage/mclzViews/index.vue')
        },
        {
          path: '/custom-big-screen/mclz-light',
          name: 'mclzLightPage',
          component: () => import('../view/otherPage/mclzViews/lightIndex.vue')
        },
        // // 大屏 —— 城市级停车指挥中心
        // {
        //   path: '/commend-center',
        //   name: 'commendCenter',
        //   // component: () => import('../view/bigScreen/commandCenterModule/commendCenter.vue')
        //   component: () => import('../view/bigScreen/cityParkCommandCenter/index.vue')
        // },
        // 自定义大屏页
        {
          path: '/custom-big-screen',
          name: 'customBigScreen',
          // component: () => import('../view/bigScreen/commandCenterModule/commendCenter.vue')
          // component: () => import('../view/bigScreen/customBigScreen/index.vue')
          component: () => import('../view/homeNavigation/views/customBigScreen/index.vue')
        },
        // 消息通知 message-notification
        {
          path: '/message-notification',
          name: 'messageNotification',
          component: () => import('../view/homeNavigation/views/messageNotification/index.vue')
        },
        // // 配置场景
        // {
        //   path: '/scene-add-configuration',
        //   name: 'addSceneConfiguration',
        //   component: () => import('../view/scene/addSceneConfiguration.vue')
        // },
        // 大屏选择
        {
          path: '/screen-selection',
          name: 'screenSelection',
          component: () => import('../view/homeNavigation/views/sreenDisplay/index.vue')
        },
        // 场景
        {
          path: '/scene-configuration',
          name: 'sceneConfiguration',
          component: () => import('../view/homeNavigation/views/sceneConfiguration/index.vue')
        },
        // //  Miracle chat
        // {
        //   path: '/miracle-chat',
        //   name: 'miracleChat',
        //   component: () => import('../view/homeNavigation/views/miracleChat/index.vue')
        // },
        // 路内停车场
        ...curbPark,
        // 路外停车场
        ...paikingPark,
        // 安防
        ...security,
        // 账务报表
        ...financialStatement,
        // 系统
        ...systemModule,
        // 告警
        ...warningList,
        // 行政
        ...administration,
        // 监控
        ...monitoring,
        // 资产
        ...assetsManagement,
        // 运维中心
        ...maintenanceCenter,
        // 优惠劵
        ...discountCouponList,
        // 数据分析模块
        ...dataAnalysis,
        // 觉悟大模型
        ...juewuAiChatList
      ]
    }
  ]
})
// 挂载路由导航守卫
// to 表示将要访问的路径
// from 表示从哪里来
// next 是下一个要做的操作
router.beforeEach((to, from, next) => {
  // console.log(to);
  // 若访问的是 /login 登录页面，直接旅行，让用户可以访问
  if (to.path === '/login') {
    return next()
  }
  // 获取 token
  const token = sessionStorage.getItem('token') || utils.getQueryString('access_toke')
  // console.log(token);
  // 若 token 没有值，则强制跳转到登录页面
  if (!token) {
    return next('/login')
  } else {
    sessionStorage.setItem('token', token)
    sessionStorage.setItem('refreshtoken', utils.getQueryString('refresh_token'))
    next()
  }
})
export default router
