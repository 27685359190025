<template>
  <div class="count_box">
    <template v-if="hint">
      <el-tooltip :content="hint" placement="top" effect="light" popper-class="testtooltip">
        <i :class="`iconfont ${icon} count-${type}`">
        </i>
      </el-tooltip>
    </template>
    <template v-else>
      <i :class="`iconfont ${icon} count-${type}`"></i>
    </template>
    <span class="label">{{ title }}：</span>
    <span class="value">￥{{ count.toFixed(2) }}</span>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'leCount',
  props: {
    type: {
      type: String,
      default: 'one'
    },
    icon: { type: String, default: 'lebo-huizong' },
    title: { type: String, default: '汇总' },
    count: { type: Number },
    hint: { type: String }
  },
  data () {
    return {}
  },
  created () { },
  mounted () { },
  computed: {},
  watch: {},
  methods: {}
}
</script>
<style scoped lang="less">
.count_box {
  display: inline-block;
  margin-left: 25px;

  .iconfont {
    font-size: 16px;
    padding: 6px;
    box-sizing: border-box;
    border-radius: 50%;
    margin-right: 5px;
  }

  .label {
    font-size: 16px;
    color: #999999;
  }

  .value {
    font-size: 18px;
    font-weight: 700;
    color: #666666;
  }
}

.count-one {
  color: #33c0d5;
  border: 3px solid rgba(51, 192, 213, 0.2);
}

.count-two {
  color: #1acd90;
  border: 3px solid rgba(26, 205, 144, 0.2);
}

.count-three {
  color: #e39f28;
  border: 3px solid rgba(227, 159, 40, 0.2);
}

.count-four {
  color: #f55265;
  border: 3px solid rgba(245, 82, 101, 0.2);
}
</style>
