/**
 * 监控
 */

const monitoring = [
  // 监控 -- 场景管理 scene-management
  {
    path: '/scene-management',
    name: 'sceneManagement',
    component: () => import('../view/videoSurveillanceManagement/views/sceneManagement/sceneManagement/index.vue')
  },
  // // 监控 -- 场景管理-- 预览配置 previewConfig
  // {
  //   path: '/preview-config/:scene_name/:id',
  //   name: 'previewConfig',
  //   component: () => import('../view/monitoring/previewConfig.vue'),
  //   props: true
  // },
  // 监控 -- NVR管理 NVR-management
  {
    path: '/NVR-management',
    name: 'NVRManagement',
    component: () => import('../view/videoSurveillanceManagement/views/nvrManagement/NVRManagement/index.vue')
  },
  // 监控 -- 设备管理 device-management
  {
    path: '/device-management',
    name: 'deviceManagement',
    component: () => import('../view/videoSurveillanceManagement/views/equipmentManagement/deviceManagement/index.vue')
  },
  // 监控 -- 视频预览 video-preview
  {
    path: '/video-preview',
    name: 'videoPreview',
    component: () => import('../view/videoSurveillanceManagement/views/videoPreview/videoPreview/index.vue')
  },
  // 监控 -- 视频回放 -- 视频录制规则
  {
    path: '/video-record-rules',
    name: 'videoRecordRules',
    component: () => import('../view/videoSurveillanceManagement/views/videoPlaybackPlan/videoRecordRules/index.vue')
  },
  // // 监控 -- 视频回放 -- 视频录制规则 -- 新增编辑
  // {
  //   path: '/video-record-rules-edit/:id',
  //   name: 'videoRecordRulesEdit',
  //   component: () => import('../view/monitoring/videoRecordRulesEdit.vue'),
  //   props: true
  // },
  // 监控 -- 视频回放 -- 视频录制记录
  {
    path: '/video-recorded',
    name: 'videoRecorded',
    component: () => import('../view/videoSurveillanceManagement/views/videoPlaybackPlan/videoRecorded/index.vue')
  },
  // 监控 -- 图片抓拍 -- 抓拍规则
  {
    path: '/picture-capture-rules',
    name: 'pictureCaptureRules',
    component: () => import('../view/videoSurveillanceManagement/views/pictureCapturePlan/pictureCaptureRules/index.vue')
  },
  // // 监控 -- 图片抓拍 -- 抓拍规则 -- 新增编辑
  // {
  //   path: '/picture-capture-rules-edit/:id',
  //   name: 'pictureCaptureRulesEdit',
  //   component: () => import('../view/monitoring/pictureCaptureRulesEdit.vue'),
  //   props: true
  // },
  // 监控 -- 图片抓拍 -- 抓拍记录
  {
    path: '/picture-recorded',
    name: 'pictureRecorded',
    component: () => import('../view/videoSurveillanceManagement/views/pictureCapturePlan/pictureRecorded/index.vue')
  },
  // 监控 -- 国际ID授权
  {
    path: '/international-id',
    name: 'internationalID',
    component: () => import('../view/videoSurveillanceManagement/views/nationalStandardAuthorization/internationalID/index.vue')
  },
  // 行为分析 --- 边缘分析盒 /edge-analysis
  {
    path: '/edge-analysis',
    name: 'edgeAnalysis',
    component: () => import('../view/videoSurveillanceManagement/views/behaviorAnalysis/edgeAnalysisBox/index.vue')
  },
  // 行为分析 --- 识别记录 /recognition-record
  {
    path: '/recognition-record',
    name: 'recognitionRecord',
    component: () => import('../view/videoSurveillanceManagement/views/behaviorAnalysis/recognitionRecord/index.vue')
  },
  // 行为分析 --- 以图搜图
  {
    path: '/monitor-search',
    name: 'monitorSearch',
    component: () => import('../view/videoSurveillanceManagement/views/behaviorAnalysis/monitorSearch/index.vue')
  }
]

export default monitoring
