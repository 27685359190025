var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hint)?_c('el-tooltip',{staticClass:"item",attrs:{"popper-class":"lb-tooltip","effect":"light","enterable":false,"content":_vm.hint,"placement":"top"}},[_c('button',{staticClass:"lb-button hint",class:[
      ("lb-" + _vm.type + "-hint-button"),
      {
        'is-disabled': _vm.disabled,
      } ],on:{"click":_vm.handleClick}},[_c('i',{class:'iconfont lebo-' + _vm.icon})])]):_c('button',{staticClass:"lb-button",class:[
    _vm.fill ? ("lb-" + _vm.type + "-button") : ("lb-" + _vm.type + "-hint-button"),
    {
      'is-disabled': _vm.disabled,
    } ],on:{"click":_vm.handleClick}},[(_vm.icon)?_c('i',{class:'iconfont lebo-' + _vm.icon}):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }