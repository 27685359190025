<template>
    <div :class="['lebo-query', isMultiRow ? 'shadow_box' : '']">
        <slot></slot>
        <i :class="['open_box iconfont ', isOpen ? 'lebo-caret-top' : 'lebo-caret-bottom']" v-if="isMultiRow"
            @click="toggleChange"></i>
    </div>
</template>
<script>

export default {
  name: 'lebo-query',
  props: {
    isMultiRow: { type: Boolean, default: false }
  },
  data () {
    return {
      isOpen: true
    }
  },
  created () { },
  mounted () { },
  computed: {},
  watch: {},
  methods: {
    toggleChange () {
      this.isOpen = !this.isOpen
      this.$emit('toggleChange', this.isOpen)
    }
  }
}
</script>
<style scoped lang="less">
.lebo-query {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: relative;
    padding: 0px 10px;
    .open_box {
        font-size: 12px;
        color: #cccccc;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 20px;
        background-color: #fff;
        position: absolute;
        left: 50%;
        bottom: -10px;
        transform: translate(-50%, 0);
        border-radius: 10px;
        box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
        cursor: pointer;

    }

}

.shadow_box {
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}
</style>
