<template>
  <div class="lebo-card">
    <div class="card_heard_box">
      <div>
        <span class="title">{{title}}</span>
        <slot name="heard-left"></slot>
      </div>
      <slot name="heard-right"></slot>
    </div>
    <slot></slot>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'leCard',
  props: {
    title: {
      type: String,
      default: '报表'
    }
  },
  data () {
    return {}
  },
  created () { },
  mounted () { },
  computed: {},
  watch: {},
  methods: {}
}
</script>
<style scoped lang="less">
.lebo-card {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(1, 1, 1, 0.1);
  padding: 10px;
  box-sizing: border-box;
  .card_heard_box {
    padding-bottom: 8px;
    border-bottom: solid 2px RGBA(204, 237, 254, .8);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    .title {
      padding: 0px 10px;
      font-size: 16px;
      font-weight: 700;
      // font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      color:  #01A5FA;
      border-left: 4px solid #01a6fb;
      border-radius: 3px;
    }
  }
}
</style>
