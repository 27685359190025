/**
 * 财务报表
 */
// 路内
const inParkingList = [
  // 财务报表 -- 报表统计汇总 reportforms-count
  {
    path: '/reportforms-count',
    name: 'reportformsCount',
    component: () => import('../view/parkingManagement/views/financialStatements/reportformsCount/index.vue')
  },
  // 财务报表 -- 临停车缴费记录 /temporary-count
  {
    path: '/temporary-count',
    name: 'temporaryCount',
    component: () => import('../view/parkingManagement/views/financialStatements/temporaryCount/index.vue')
  },
  // 财务报表 --  计时卡缴费记录 timeCard-payment
  {
    path: '/timeCard-payment',
    name: 'timeCardPayment',
    component: () => import('../view/parkingManagement/views/financialStatements/timeCardPayment/index.vue')
  },
  // 财务报表 --  月租卡缴费记录 monthCard-payment
  {
    path: '/monthCard-payment',
    name: 'monthCardPayment',
    component: () => import('../view/parkingManagement/views/financialStatements/monthCardPayment/index.vue')
  },
  // 财务报表 --  储值卡缴费记录 storedCard-payment
  {
    path: '/storedCard-payment',
    name: 'storedCardPayment',
    component: () => import('../view/parkingManagement/views/financialStatements/storedCardPayment/index.vue')
  },
  // 财务报表 --  临停车缴费报表 timeCard-reportforms
  {
    path: '/timeCard-reportforms',
    name: 'timeCardReportforms',
    component: () => import('../view/parkingManagement/views/financialStatements/timeCardReportforms/index.vue')
  },
  // 财务报表 -- 巡检人员收费报表 /inspector-reportforms
  {
    path: '/inspector-reportforms',
    name: 'inspectorReportforms',
    component: () => import('../view/parkingManagement/views/financialStatements/inspectorReportforms/index.vue')
  },
  // // 财务报表 -- 查看巡检人员收费报表 /looking-inspector-reportforms
  // {
  //   path: '/looking-inspector-reportforms',
  //   name: 'lookingInspectorReportforms',
  //   component: () => import('../view/financialStatement/lookingInspectorReportforms.vue'),
  //   props: true
  // },
  // 财务报表 -- 开票管理 /invoice-management
  {
    path: '/invoice-management',
    name: 'invoiceManagement',
    component: () => import('../view/parkingManagement/views/invoicingManagement/invoiceManagement/index.vue')
  },
  // 财务报表 -- 开票记录 /invoice-element
  {
    path: '/invoice-element',
    name: 'invoiceElement',
    component: () => import('../view/parkingManagement/views/invoicingManagement/invoiceElement/index.vue')
  }
  // // 财务报表 -- 发票设置 /invoice-set
  // {
  //   path: '/invoice-set/:id/:groupId/:comefrom',
  //   name: 'invoiceSet',
  //   component: () => import('../view/financialStatement/invoiceSet.vue'),
  //   props: true
  // },
  // // 财务报表 -- 查看发票详情 /invoice-detail
  // {
  //   path: '/invoice-detail',
  //   name: 'invoiceDetail',
  //   component: () => import('../view/financialStatement/invoiceDetail.vue')
  // }
]

// 路外
const outParkingList = [
  // 财务报表 -- 报表统计汇总 reportforms-count
  {
    path: '/routw-reportforms-count',
    name: 'routwreportformsCount',
    component: () => import('../view/parkingManagement/views/financialStatements/reportformsCount/index.vue')
  },
  // 财务报表 -- 临停车缴费记录 /temporary-count
  {
    path: '/routw-temporary-count',
    name: 'routwtemporaryCount',
    component: () => import('../view/parkingManagement/views/financialStatements/temporaryCount/index.vue')
  },
  // 财务报表 --  计时卡缴费记录 timeCard-payment
  {
    path: '/routw-timeCard-payment',
    name: 'routwtimeCardPayment',
    component: () => import('../view/parkingManagement/views/financialStatements/timeCardPayment/index.vue')
  },
  // 财务报表 --  月租卡缴费记录 monthCard-payment
  {
    path: '/routw-monthCard-payment',
    name: 'routwmonthCardPayment',
    component: () => import('../view/parkingManagement/views/financialStatements/monthCardPayment/index.vue')
  },
  // 财务报表 --  储值卡缴费记录 storedCard-payment
  {
    path: '/routw-storedCard-payment',
    name: 'routwstoredCardPayment',
    component: () => import('../view/parkingManagement/views/financialStatements/storedCardPayment/index.vue')
  },
  // 财务报表 --  临停车缴费报表 timeCard-reportforms
  {
    path: '/routw-timeCard-reportforms',
    name: 'routwtimeCardReportforms',
    component: () => import('../view/parkingManagement/views/financialStatements/timeCardReportforms/index.vue')
  },
  // 财务报表 -- 巡检人员收费报表 /inspector-reportforms
  {
    path: '/routw-inspector-reportforms',
    name: 'routwinspectorReportforms',
    component: () => import('../view/parkingManagement/views/financialStatements/inspectorReportforms/index.vue')
  },
  // // 财务报表 -- 查看巡检人员收费报表 /looking-inspector-reportforms
  // {
  //   path: '/routw-looking-inspector-reportforms',
  //   name: 'routwlookingInspectorReportforms',
  //   component: () => import('../view/financialStatement/lookingInspectorReportforms.vue'),
  //   props: true
  // },
  // 财务报表 -- 开票管理 /invoice-management
  {
    path: '/routw-invoice-management',
    name: 'routwinvoiceManagement',
    component: () => import('../view/parkingManagement/views/invoicingManagement/invoiceManagement/index.vue')
  },
  // 财务报表 -- 开票记录 /invoice-element
  {
    path: '/routw-invoice-element',
    name: 'routwinvoiceElement',
    component: () => import('../view/parkingManagement/views/invoicingManagement/invoiceElement/index.vue')
  }
  // // 财务报表 -- 发票设置 /invoice-set
  // {
  //   path: '/routw-invoice-set/:id/:groupId/:comefrom',
  //   name: 'routwinvoiceSet',
  //   component: () => import('../view/financialStatement/invoiceSet.vue'),
  //   props: true
  // },
  // // 财务报表 -- 查看发票详情 /invoice-detail
  // {
  //   path: '/routw-invoice-detail',
  //   name: 'routwinvoiceDetail',
  //   component: () => import('../view/financialStatement/invoiceDetail.vue')
  // }
]

const financialStatement = [
  ...inParkingList,
  ...outParkingList
]
export default financialStatement
