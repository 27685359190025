<!-- 展示对话组件 -->
<template>
  <div class='dialogContent'>
    <div class="myQuestionBox">
      <div class="myLogo logoBox">
        <span>我</span>
      </div>
      <div class="mainQuestionBox">
        <div class="innerMianQuesion">
          <div class="showQuestion" v-for="(questionItem, questionIndex) in dialogDetial.question_answer"
            :key="questionItem.question_id">
            <template v-if="nowQuestionIndex === questionIndex">
              <div class="quesItem" v-if="!showEditInput">
                <template v-if="questionItem.question_type === 1 || questionItem.question_type === 2||questionItem.question_type === 7||questionItem.question_type === 8||questionItem.question_type === 9||questionItem.question_type === 10||questionItem.question_type === 500||questionItem.question_type === 501">
                  <span style="white-space: pre-wrap">{{ questionItem.question_name }}</span>
                </template>
                <template
                  v-if="questionItem.question_type === 3 || questionItem.question_type === 4 || questionItem.question_type === 5">
                  {{ questionItem.question_name }}
                  <template v-if="questionItem.question_attachment">
                    <el-image fit="contain"
                      :src="getFirstImage(JSON.parse(questionItem.question_attachment).question_file)" :z-index="10000"
                      @click="onpenQuestionImage(getFirstImage(JSON.parse(questionItem.question_attachment).question_file))"
                      class="imgAnswer">
                      <div slot="error" class="image-error">
                        <i class="iconfont lebo-picerr"></i>
                        <span>加载失败</span>
                      </div>
                    </el-image>
                    <cusImage v-if="showImage2" @close="showImage2 = false" :url-list="urlList2" style="width:100%;">
                    </cusImage>
                  </template>

                </template>
                <template v-if="questionItem.question_type === 6">
                  {{ questionItem.question_name }}
                  <template v-if="questionItem.question_attachment">
                    <video :src="getFirstImage(JSON.parse(questionItem.question_attachment).question_file)"
                      controls="controls" @dblclick="fullScreen" :id="questionItem.question_id"
                      class="videoQuestion"></video>
                  </template>
                </template>
              </div>
              <!-- 如果type=1,2展示 -->
              <!-- &&(questionItem.question_type===1||questionItem.question_type===2) -->
              <div class="editQuestion"
                v-if="dialogDetial.question_answer[nowQuestionIndex].answer_data[nowAnswerIndex].is_full && questionItem.question_type !== 6 && questionItem.question_type !== 7&&questionItem.question_type !== 8&& questionItem.question_type !== 9&& questionItem.question_type !== 10">
                <lbButton icon="bianji" hint="编辑问题" @click="editNowQuestion(questionItem, questionIndex)">
                </lbButton>
              </div>
              <!-- 编辑历史标题 -->
              <div class="editing" v-if="showEditInput" v-clickoutside="blurInput">
                <div>
                  <el-input v-model="editingValue" :placeholder="questionItem.question_name" class="editInput"
                    resize="none" type="textarea" :autosize="{ minRows: 1, maxRows: 4 }"
                    :id="questionItem.question_id ? questionItem.question_id : questionIndex">
                  </el-input>
                  <template
                    v-if="questionItem.question_type === 3 || questionItem.question_type === 4 || questionItem.question_type === 5">
                    <el-upload class="editQuestionFile" accept="image/png" action=""
                      :http-request="(params) => uploadPhoto(params, questionItem)" :show-file-list="false"
                      :before-upload="(params) => beforePhotpUpload(params, questionItem)">
                      <img fit="contain" :src="getFirstImage(JSON.parse(editQuestionAttachment).question_file)"
                        :z-index="10000" :key="switchImgType" alt="文件加载失败" title="点击更换文件" />
                      <div class="imgMask">
                        <i class="icon iconfont lebo-shangchuanwenjian"></i>
                      </div>
                    </el-upload>
                  </template>
                </div>
                <div class="handelBox">
                  <i class="el-icon-check" @mousedown="checkEdit(editingValue, questionItem, editQuestionAttachment)"></i>
                  <i class="el-icon-close" @mousedown="blurInput()"></i>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="handelQuestion"
          v-show="dialogDetial.question_answer.length > 1 && dialogDetial.question_answer[nowQuestionIndex].answer_data[nowAnswerIndex].is_full">
          <i class="el-icon-arrow-left" @click="backPage('question')"></i>
          <span class="pageNum">
            {{ nowQuestionIndex + 1 + '/' + dialogDetial.question_answer.length }}
          </span>
          <i class="el-icon-arrow-right" @click="overPage('question', dialogDetial.question_answer.length)"></i>
        </div>
      </div>
    </div>
    <div class="aiAnswerBox">
      <div class="aiLogo logoBox">
        <template
          v-if="dialogDetial.answer_type && dialogDetial.answer_type !== 0 && dialogDetial.question_answer[nowQuestionIndex].answer_data[nowAnswerIndex].answer_type">
          <i class="icon iconfont lebo-juewulogo"></i>
        </template>
        <template v-else>
          <div class="loadingLogo"></div>
        </template>
      </div>
      <!-- 判断 -->
      <div class="innerFlex">
        <div class="mainAnswerBox"
          v-if="dialogDetial.answer_type && dialogDetial.answer_type !== 0 && dialogDetial.question_answer[nowQuestionIndex].answer_data[nowAnswerIndex].answer_type">
          <div class="innerMainAnswer">
            <div class="showAnswer"
              v-for="(answerItem, answerIndex) in dialogDetial.question_answer[nowQuestionIndex].answer_data"
              :key="answerIndex">
              <div class="answeritem" v-if="nowAnswerIndex === answerIndex">
                <template
                  v-if="answerItem.answer_type === 1 || answerItem.answer_type === 3 || answerItem.answer_type === 6">
                  <!-- 文字类型 -->
                  {{ JSON.parse(answerItem.answer_content).textName }}
                </template>
                <template v-if="answerItem.answer_type === 501">
                  <!-- 文字类型 -->
                  {{ JSON.parse(answerItem.answer_content).textName }}
                  <div class="seaseAnswer"> {{ '已停止生成' }}</div>
                </template>
                <template
                  v-if="answerItem.answer_type === 2 || answerItem.answer_type === 4 || answerItem.answer_type === 5">
                  <!-- 图片类型 -->
                  <!-- <img :src="JSON.parse(answerItem.answer_content).textName" alt="" class="imgAnswer"> -->
                  <el-image fit="contain" :src="getFirstImage(JSON.parse(answerItem.answer_content).textName)"
                    :z-index="10000" @click="onpenImage(getFirstImage(JSON.parse(answerItem.answer_content).textName))"
                    class="imgAnswer">
                    <div slot="error" class="image-error">
                      <i class="iconfont lebo-picerr"></i>
                      <span>加载失败</span>
                    </div>
                  </el-image>
                  <cusImage v-if="showImage" @close="showImage = false"
                    :url-list="formatImage(getFirstImage(JSON.parse(answerItem.answer_content).textName))"
                    style="width:100%;">
                  </cusImage>
                </template>
                <template v-if="answerItem.answer_type === 7">
                  <div class="topTray">{{ '车牌号为【' + JSON.parse(answerItem.answer_content).textName.plate_number + '】的车辆在【'
                    +
                    JSON.parse(answerItem.answer_content).textName.go_in_time_start + '~' +
                    JSON.parse(answerItem.answer_content).textName.go_out_time_end + '】的轨迹如下：' }}
                  </div>
                  <div class="midTray">
                    <div class="trayTitle"><span>车辆停车轨迹图</span></div>
                    <div class="map-container" :id="dialogDetial._id"></div>
                  </div>
                  <div class="footTray">
                    <el-collapse v-model="activeNames" accordion>
                      <el-collapse-item title="详情" name="1">
                        <div v-for="(item, index) in JSON.parse(answerItem.answer_content).textName.data" :key="index"
                          class="footTrayDetial">
                          <span>{{ index + 1 + '、' + item.parking_name }}</span>
                          <template v-if="item.go_out_time && !item.go_out_time.startsWith('0001')">
                            <span>{{ '停车' + getDiffTime(item.go_out_time, item.go_in_time) }}</span>
                          </template>
                          <template v-else>
                            <span>车辆暂未出场</span>
                          </template>
                          <span>{{ $moment(item.go_in_time).format('YYYY-MM-DD HH:mm:ss') + '~' + (item.go_out_time && !item.go_out_time.startsWith('0001')?
                            $moment(item.go_out_time).format('YYYY-MM-DD HH:mm:ss') : '车辆暂未出场') }}</span>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                </template>
                <template v-if="answerItem.answer_type === 8">
                  <div class="attendanceTop">
                    {{
                      '姓名为【' + JSON.parse(answerItem.answer_content).textName.inspector_name + '】在【' +
                      JSON.parse(answerItem.answer_content).textName.sDate + '】有【'
                      + getAbnormalTimes(JSON.parse(answerItem.answer_content).textName.statistics_detail) + '】次考勤异常记录：'
                    }}
                  </div>
                  <div class="attendanceMid">
                    <div class="trayTitle"><span>人员当月考勤统计日历表</span></div>
                    <el-calendar v-model="JSON.parse(answerItem.answer_content).textName.sDate" :first-day-of-week="0"
                      class="calendar_box" :id="dialogDetial._id">
                      <template slot="dateCell" slot-scope="{date, data}">
                        {{ data.day.split('-').slice(2).join('') }}
                        <span v-if="false">{{ date }}</span>
                        <div v-for="(item, index) in clockDetailList" :key="index">
                          <div class="date_detail_cell" v-if="data.type == 'current-month' && item.date == data.day">
                            <div :class="['attanceDeial', determineAbnormal(item) === 1 ? '' : 'abnomalStatus']"></div>
                          </div>
                        </div>
                      </template>
                    </el-calendar>
                  </div>
                  <div class="attendanceFoot">
                    <el-collapse v-model="activeNames" accordion>
                      <el-collapse-item title="详情" name="1">
                        <div class="attendanceFootDetial" v-for="(item, index) in abnomalDetailList" :key="index">
                          <span>
                            <template v-if="index < 9">
                              {{ '0'+(index + 1 ) +'、日期：' + item.date}}
                            </template>
                            <template v-else>
                              {{ index + 1  +'、日期：' + item.date }}
                            </template>
                            <template>
                              {{($moment(item.workTime).format('YYYY-MM-DD') != '0001-01-01' ?
                                $moment(item.workTime).format(' HH:mm:ss') : '未打卡')}}
                            </template>
                          </span>
                          <!-- <span>{{ '打卡时间：' + ($moment(item.workTime).format('YYYY-MM-DD') != '0001-01-01' ?
                            $moment(item.workTime).format('YYYY-MM-DD') : '未打卡') }}</span> -->
                          <span >{{ item.describe }}</span>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                </template>
                <template v-if="answerItem.answer_type === 9">
                  <div class="newAddTop">   {{ '已成功创建了一个【'+JSON.parse(answerItem.answer_content).textName.parking_name+'】' }}</div>
                  <div class="newAddBox">
                    <div>{{ JSON.parse(answerItem.answer_content).textName.parking_name }}</div>
                    <div class="routerBox" @click="seeAddDetial(9)">
                      <i class="el-icon-right"></i>
                    </div>
                  </div>
                </template>
                <template v-if="answerItem.answer_type === 10">
                  <div class="newAddTop">已成功创建了一个【临停计时计费方案】</div>
                  <div class="newAddBox">
                    <div>临停计费方案</div>
                    <div class="routerBox" @click="seeAddDetial(10)">
                      <i class="el-icon-right"></i>
                    </div>
                  </div>
                </template>
                <template v-if="answerItem.answer_type === 500">
                  <!-- 无回答 -->
                  {{ JSON.parse(answerItem.answer_content).textName }}
                </template>
              </div>
              <div class="editAnswer"
                v-if="nowAnswerIndex === answerIndex && dialogDetial.question_answer[nowQuestionIndex].answer_data[nowAnswerIndex].is_full">
                <!-- <template v-if="answerItem.answer_type !== 6&&answerItem.answer_type !== 7&&answerItem.answer_type !== 8&&answerItem.answer_type !== 9&&answerItem.answer_type !== 10&&answerItem.answer_type !== 500">
                  <div @click="reBuildQuestion(answerItem, answerIndex, dialogDetial.question_answer[nowQuestionIndex])"
                    class="rebuildButton">重新生成</div>
                </template> -->
                <template >
                  <div></div>
                </template>
                <div class="copyBox">
                  <el-popover placement="bottom" width="100" trigger="click" popper-class="innerLogPopover">
                    <div class="innerlog">
                      <template
                        v-if="dialogDetial.question_answer[nowQuestionIndex].answer_data[nowAnswerIndex].answer_type === 500">
                        呃，网络不给力，请重试...
                      </template>
                      <template v-else>
                        <span class="fontspan">1.问题内容开始解析</span>
                        <br>
                        <span>2.问题内容解析完成</span>
                        <br>
                        <template
                          v-if="dialogDetial.question_answer[nowQuestionIndex].answer_data[nowAnswerIndex].answer_type === 1 || dialogDetial.question_answer[nowQuestionIndex].answer_data[nowAnswerIndex].answer_type === 3">
                          <span class="fontspan">3.文本内容已生成</span>
                        </template>
                        <template
                          v-if="dialogDetial.question_answer[nowQuestionIndex].answer_data[nowAnswerIndex].answer_type === 2 || dialogDetial.question_answer[nowQuestionIndex].answer_data[nowAnswerIndex].answer_type === 4 || dialogDetial.question_answer[nowQuestionIndex].answer_data[nowAnswerIndex].answer_type === 5">
                          <span class="fontspan">3.图片内容已生成</span>
                        </template>
                        <template   v-if="dialogDetial.question_answer[nowQuestionIndex].answer_data[nowAnswerIndex].answer_type === 501">
                          <span class="fontspan">3.已停止生成答案</span>
                        </template>
                        <br>
                        <span class="fontspan">4.问题内容生成结束</span>
                      </template>
                    </div>
                    <lbButton icon="a-tablernote" hint="查看日志" style="margin-right: 10px;" slot="reference"></lbButton>
                  </el-popover>
                  <lbButton icon="fuzhiwenben" hint="复制答案"
                    @click="copyClick(JSON.parse(answerItem.answer_content).textName)"
                    v-if="answerItem.answer_type === 1 || answerItem.answer_type === 3 || answerItem.answer_type === 6|| answerItem.answer_type === 501">
                  </lbButton>
                  <lbButton icon="xiazaiwenjian" hint="图片下载"
                    @click="downAnswer(JSON.parse(answerItem.answer_content).textName)"
                    v-if="answerItem.answer_type === 2 || answerItem.answer_type === 4 || answerItem.answer_type === 5">
                  </lbButton>
                  <lbButton icon="rerefresh" hint="重新生成" style="margin-left: 10px;"
                  @click="reBuildQuestion(answerItem, answerIndex, dialogDetial.question_answer[nowQuestionIndex])"
                  v-if="answerItem.answer_type !== 6&&answerItem.answer_type !== 7&&answerItem.answer_type !== 8&&answerItem.answer_type !== 9&&answerItem.answer_type !== 10&&answerItem.answer_type !== 500">
                  </lbButton>
                </div>
              </div>
            </div>
          </div>
          <div class="handelAnswer"
            v-show="dialogDetial.question_answer[nowQuestionIndex].answer_data.length > 1 && dialogDetial.question_answer[nowQuestionIndex].answer_data[nowAnswerIndex].is_full">
            <i class="el-icon-arrow-left" @click="backPage('answer')"></i>
            <span class="pageNum">
              {{ nowAnswerIndex + 1 + '/' + dialogDetial.question_answer[nowQuestionIndex].answer_data.length
              }}
            </span>
            <i class="el-icon-arrow-right"
              @click="overPage('answer', dialogDetial.question_answer[nowQuestionIndex].answer_data.length)"></i>
          </div>
        </div>
        <div class="answeringBox">
          <div class="answeringItem">
            <div class="innerAnswering"
              v-if="!dialogDetial.answer_type || dialogDetial.answer_type === 0 || !dialogDetial.question_answer[nowQuestionIndex].answer_data[nowAnswerIndex].answer_type">
            </div>
          </div>

          <div class="logBox">
            <!-- 判断问题类型 -->
            <template
              v-if="dialogDetial.question_answer[nowQuestionIndex].answer_data[nowAnswerIndex] && !dialogDetial.question_answer[nowQuestionIndex].answer_data[nowAnswerIndex].is_full">
              <span class="fontspan"> {{ getProcess }}</span>
            </template>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import cusImage from '../../components/photoPreview/index.vue'
import Clickoutside from 'element-ui/src/utils/clickoutside'
import request from '@/api/systemapi'
import { lazyAMapApiLoaderInstance } from 'vue-amap'
export default {
  name: 'dialogComponent',
  components: { cusImage },
  directives: { Clickoutside },
  props: {
    dialogDetial: { require },
    changTyping: { default: true } // 判断是否可以提问
  },
  data () {
    return {
      myQuestion: '',
      aiAnswer: '',
      nowQuestionIndex: 0, // 默认展示答案数组的下标
      nowAnswerIndex: 0, // 当前问题下的第几个答案
      showImage: false,
      showImage2: false,
      showEditInput: false, // 展示编辑文本框
      editingValue: '', // 编辑问题
      editingFile: '', // 编辑文件
      chatGroupId: '', // 主对话id
      urlList: [],
      urlList2: [],
      visible: false,
      fullscreenstatus: false,
      switchImgType: 1, // 强制刷新图片上传 key
      clockDetailList: [],
      abnomalDetailList: [], // 考勤异常数组
      activeNames: '1'
    }
  },
  watch: {
    // 问题索引值改变立刻重置0
    nowQuestionIndex (val, old) {
      if (val) {
        this.nowAnswerIndex = 0
      }
    }
  },
  computed: {
    ...mapGetters('menuList', ['theme', 'shortcutList', 'groupInfo']),
    ...mapState('menuList', [
      'activeIndex',
      'groupInfo',
      'hearderMenuList',
      'activeMenu',
      'activeHearderMenu',
      'allMenuList',
      'isShowMsg',
      'isBigScreen',
      'isBigScreenShowModule',
      'leftMenuList',
      'isMenuRoute',
      'isWorkSpace',
      'activeTabList'
    ]),
    getProcess () {
      const data = this.dialogDetial.question_answer[this.nowQuestionIndex].answer_data[this.nowAnswerIndex]
      let process = ''
      if (data) {
        process = '问题内容开始解析'
        if (data.answer_type) {
          process = '问题内容解析完成'
          if (data.answer_type === 1 || data.answer_type === 3) {
            process = '文本内容已生成'
          } else if (data.answer_type === 2 || data.answer_type === 4 || data.answer_type === 5) {
            process = '图片内容已生成'
          } else if (data.answer_type === 501) {
            process = '已停止生成答案'
          }
        }
        if (data.is_full) {
          process = '问题内容生成结束'
        }
      }

      return process
    }
  },
  methods: {
    ...mapMutations('menuList', [
      'setShowTab',
      'setCurrentBtn',
      'addActiveTabList',
      'setLeftMenuList',
      'setActiveHearderMenu',
      'setActiveMenu',
      'setMenuList',
      'addTab',
      'setDevAndHelpList',
      'setThree3DUrl',
      'setIsBigScreen',
      'setIsMenuRoute',
      'setActiveIndex',
      'setIsWorkSpace',
      'closeTab',
      'closeAllTab'
    ]),
    // 编辑当前问题
    editNowQuestion (item, index) {
      console.log('changTypingchangTyping', this.changTyping)
      this.editingValue = item.question_name
      this.editQuestionAttachment = item.question_attachment
      this.showEditInput = true
      this.$nextTick(() => {
        const catchId = item.question_id
        const editingBox = document.getElementById(catchId)
        editingBox.focus()
      })
      console.log('编辑问题', item, index)
    },
    blurInput () {
      this.showEditInput = false
    },
    checkEdit (newQuestion, originQuestion, newFile) {
      // 将信息传递请求答案
      // 将主对话id传入
      // console.log(' this.chatGroupId', this.chatGroupId)
      if (this.changTyping) {
        originQuestion.mainRoomId = this.chatGroupId
        if (originQuestion.reGetAnswer) {
          originQuestion.reGetAnswer = false
        }
        console.log('重新提问', newQuestion, originQuestion)
        const newQuestionObj = {}
        newQuestionObj.question = newQuestion
        newQuestionObj.questionFile = newFile || originQuestion.question_attachment
        // 重新提问
        this.$emit('handelNewQuestion', newQuestionObj, originQuestion, originQuestion.question_type)
        this.showEditInput = false
      } else {
        this.$message.warning('请等待觉悟回答完成!')
      }
    },
    // 重新生成答案
    reBuildQuestion (item, index, questionItem) {
      if (this.changTyping) {
        questionItem.mainRoomId = this.chatGroupId
        questionItem.reGetAnswer = true
        console.log('重新生成答案', item, index, questionItem)
        const questionObj = {}
        questionObj.question = questionItem.question_name
        questionObj.questionFile = questionItem.question_attachment
        this.$emit('handelNewAnswer', questionObj, questionItem, questionItem.question_type)
      } else {
        this.$message.warning('请等待觉悟回答完成!')
      }
    },
    onpenImage (url) {
      this.urlList = []
      this.urlList = [url]
      this.showImage = true
    },
    onpenQuestionImage (url) {
      this.urlList2 = []
      this.urlList2 = [url]
      this.showImage2 = true
    },
    // 复制
    copyClick (value) {
      const input = document.createElement('input') // 创建input对象
      input.value = value// 设置复制内容入口
      document.body.appendChild(input) // 添加临时实例
      input.select() // 选择实例内容
      document.execCommand('Copy') // 执行复制
      if (document.execCommand('Copy', 'false', null)) {
        // 如果复制成功
        // console.log('点击内容已复制');
        this.$message.success('复制成功')
      }
      // 复制成功之后删除增加的这个input元素
      document.body.removeChild(input)
    },
    // 下载答案
    downAnswer (value) {
      //   var name = new Date().getTime()
      //   var a = document.createElement('a')
      //   a.style.display = 'none'
      //   a.download = 'photo.png'
      //   a.href = value
      //   document.body.appendChild(a)
      //   a.click()
      //   document.body.removeChild(a)
      //   this.$message.success('下载成功')
      //  1
      fetch(value)
        .then(response => response.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = 'photo.png'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        })
        .catch(error => console.error('Error:', error))
    },
    // 向上翻
    backPage (type) {
      if (type === 'answer') {
        if (this.nowAnswerIndex + 1 <= 1) return
        this.nowAnswerIndex--
      }
      if (type === 'question') {
        if (this.nowQuestionIndex + 1 <= 1) return
        this.nowAnswerIndex = 0
        this.nowQuestionIndex--
      }
    },
    // 向后翻
    overPage (type, total) {
      if (type === 'answer') {
        if (this.nowAnswerIndex + 1 >= total) return
        this.nowAnswerIndex++
      }
      if (type === 'question') {
        if (this.nowQuestionIndex + 1 >= total) return
        this.nowAnswerIndex = 0
        this.nowQuestionIndex++
      }
    },
    // 翻到最后一页  type:1-全都翻到最后  2-只将当前答案翻到最后
    goTotal (type) {
      const answerTotal = this.dialogDetial.question_answer[this.nowQuestionIndex].answer_data.length
      const questionTotal = this.dialogDetial.question_answer.length
      //   先到最后一个问题在到最后一个答案
      if (type === 1) {
        this.nowQuestionIndex = questionTotal - 1
      }
      this.nowAnswerIndex = answerTotal - 1
    },
    // 全屏
    fullScreen () {
      // 全屏展示
      this.requestFullScreen(document.getElementById(this.dialogDetial.question_answer[this.nowQuestionIndex].question_id))
    },
    // 全屏
    requestFullScreen (element) {
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
      } else if (element.oRequestFullscreen) {
        element.oRequestFullscreen()
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullScreen()
      } else {
        var docHtml = document.documentElement
        var docBody = document.body
        var videobox = document.getElementById('monitors')
        var cssText = 'width:100%;height:100%;overflow:hidden;'
        docHtml.style.cssText = cssText
        docBody.style.cssText = cssText
        videobox.style.cssText = cssText + ';' + 'margin:0px;padding:0px;'
        document.IsFullScreen = true
      }
    },
    // 退出全屏
    exitFullscreen () {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.oRequestFullscreen) {
        document.oCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else {
        var docHtml = document.documentElement
        var docBody = document.body
        var videobox = document.getElementById('monitors')
        docHtml.style.cssText = ''
        docBody.style.cssText = ''
        videobox.style.cssText = ''
        document.IsFullScreen = false
      }
    },
    checkFull () {
      // 判断浏览器是否处于全屏状态 （需要考虑兼容问题）
      var isFull =
        document.mozFullScreen ||
        document.fullScreen ||
        document.webkitIsFullScreen ||
        document.webkitRequestFullScreen ||
        document.mozRequestFullScreen ||
        document.msFullscreenEnabled
      if (isFull === undefined) {
        isFull = false
      }
      return isFull
    },
    // 上传图片转base64
    uploadPhoto (item, questionItem) {
      console.log('item', item)
      console.log('questionItem', questionItem)
      var that = this
      that.imgLoading = true
      const indexpoint = item.file.name.lastIndexOf('.')
      const imageName = item.file.name.substr(indexpoint)
      const src = window.URL.createObjectURL(item.file)
      var reader = new FileReader()
      reader.onload = async (e) => {
        // e.target.result  就是从本地读取的图片的base64格式,将它上传给服务器即可
        // 使用axios的post方法上传即可
        var base64image = e.target.result
        that.fnFaceRecognition(item.file.name, base64image)
      }
      reader.readAsDataURL(item.file)
    },
    // 获取图片地址
    async fnFaceRecognition (name, baseImg) {
      const res = await request.updataIcon({ name: name, strbase64: [baseImg] })
      if (res) {
        console.log('上传图片res', res)
        // 强制改变key值来刷新img显示
        this.switchImgType === 1 ? this.switchImgType = 2 : this.switchImgType = 1
        this.editQuestionAttachment = JSON.stringify({
          question_file: res.data[0].FilePath,
          question_file_name: res.data[0].FileName
        })
      }
    },
    beforePhotpUpload (file, questionitem) {
      let isPNG = ''
      const isLt5M = file.size / 1024 < 1024 * 5

      if (questionitem.question_type === 3) {
        isPNG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png'
        if (!isPNG) {
          this.$message.error('上传图片只能是 jpg、jpeg、png 格式!')
          return false
        }
      } else if (questionitem.question_type === 4 || questionitem.question_type === 5) {
        isPNG = file.type === 'image/png'
        if (!isPNG) {
          this.$message.error('上传图片只能是 PNG 格式!')
          return false
        }
      }

      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!')
        return false
      }
      return isPNG && isLt5M
    },
    // 计算停车间隔时长
    getDiffTime (newDate, oldDate) {
      var subtime = (new Date(newDate) - new Date(oldDate)) / 1000 // 计算时间差,并将毫秒转化为秒
      var days = parseInt(subtime / 86400) // 天  24*60*60*1000
      var hours = parseInt(subtime / 3600) - 24 * days // 小时  60*60  总小时数-过去小时数=现在小时数
      var mins = parseInt(subtime % 3600 / 60) // 分钟 - (day*24)  以60秒为一整份  取余 剩下秒数 秒数/60就是分钟数
      var secs = parseInt(subtime % 60) // 以60秒为一整份  取余  剩下秒数
      //   const start = this.$moment(newDate).format('YYYY-MM-DD HH:mm:ss')
      //   const end = this.$moment(oldDate).format('YYYY-MM-DD HH:mm:ss')
      //   const days = this.$moment(start).diff(end, 'days')
      //   const hours = this.$moment(start).diff(end, 'hours')
      //   const mins = this.$moment(start).diff(end, 'minutes')
      return days + ' 天 ' + hours + ' 小时 ' + mins + ' 分钟'
    },
    // 创建地图
    initMap () {
      const that = this
      lazyAMapApiLoaderInstance.load().then(() => {
        var map = new AMap.Map(that.dialogDetial._id, {
          zoom: 18,
          features: ['bg', 'road', 'building', 'point'] // , "point" 地标太多了显得乱
        })
        var polygonList = []
        const lineList = JSON.parse(that.dialogDetial.answer_content).textName.data
        lineList.forEach((element, index) => {
          polygonList.push(
            [element.longitude, element.latitude]
          )
          var markerContent = '' +
            '<div class="custom-content-marker">' + (index + 1) +
            '</div>'
          var position = new AMap.LngLat(element.longitude, element.latitude)
          var marker = new AMap.Marker({
            position: position,
            // 将 html 传给 content
            content: markerContent,
            // 以 icon 的 [center bottom] 为原点
            offset: new AMap.Pixel(-13, -30)
          })
          map.add(marker)
        })
        // 创建折线实例
        var polyline = new AMap.Polyline({
          path: polygonList,
          borderWeight: 2, // 线条宽度，默认为 1
          strokeColor: '#0049FF', // 线条颜色
          lineJoin: 'round' // 折线拐点连接处样式
        })
        // 将折线添加至地图实例
        // map.add(polyline)
        polyline.setMap(map)
        // 缩放地图到合适的视野级别
        map.setFitView([polyline])
      })
    },
    // 判断是否迟到
    determineAbnormal (data) {
      let status = 1
      if (data.isholiday) {
        status = 1
      } else {
        data.clockdetail.forEach(item => {
          if (item.is_no_clocking) {
            status = 0
          } else if (item.go_clock && (item.islate || item.islate_spring)) {
            status = 0
          } else if (item.off_clock && (item.isearly || item.isearly_spring)) {
            status = 0
          }
        })
      }
      return status
    },
    // 获取总异常数
    getAbnormalTimes (obj) {
      let totalAbnormal = 0
      totalAbnormal = totalAbnormal + obj.not_clocked_in
      if (obj.late_minute_count > 0) {
        totalAbnormal = totalAbnormal + (obj.late_times - obj.late_minute_count)
      } else {
        totalAbnormal = totalAbnormal + obj.late_times
      }
      if (obj.early_minute_count > 0) {
        totalAbnormal = totalAbnormal + (obj.early_leave_times - obj.early_minute_count)
      } else {
        totalAbnormal = totalAbnormal + obj.early_leave_times
      }
      return totalAbnormal
    },
    // 获取详细异常考勤
    getAbnormalDetail () {
      this.clockDetailList.forEach(item => {
        if (!item.isholiday) {
          item.clockdetail.forEach(element => {
            if (element.is_no_clocking) {
              this.abnomalDetailList.push({
                date: item.date,
                workTime: element.work_time,
                describe: '未打卡'
              })
            } else if (element.go_clock && (element.islate || element.islate_spring)) {
              this.abnomalDetailList.push({
                date: item.date,
                workTime: element.work_time,
                describe: '迟到'
              })
            } else if (element.off_clock && (element.isearly || element.isearly_spring)) {
              this.abnomalDetailList.push({
                date: item.date,
                workTime: element.work_time,
                describe: '早退'
              })
            }
          })
        }
      })
      console.log('abnomalDetailList', this.abnomalDetailList)
    },
    // 跳转查看新增详情
    seeAddDetial (type) {
      if (type === 9) {
        this.setMenuList('路外停车场')
      } else if (type === 10) {
        this.setMenuList('临停计费方案')
      }
    }
  },
  created () {
  },
  mounted () {
    console.log('dialogDetial', this.dialogDetial)
    this.chatGroupId = this.dialogDetial._id
    if (this.dialogDetial.answer_type === 7) {
      setTimeout(() => {
        this.initMap()
      }, 300)
    }
    if (this.dialogDetial.answer_type === 8) {
      console.log('JSON.parse(this.dialogDetial.answer_content).textName', JSON.parse(this.dialogDetial.answer_content).textName)
      this.clockDetailList = JSON.parse(this.dialogDetial.answer_content).textName.resultData
      const statisticsDetail = JSON.parse(this.dialogDetial.answer_content).textName.statistics_detail
      // 判断是否是在之前入职，是则不显示未打卡信息
      const time = this.$moment(JSON.parse(this.dialogDetial.answer_content).textName.induction_date).subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')
      if (this.clockDetailList.length > 0) {
        this.clockDetailList.forEach(item => {
          if (this.$moment(item.date).isBefore(time)) {
            item.clockdetail.forEach(element => {
              element.is_no_clocking = false
            })
          }
        })
      }
      this.getAbnormalDetail()
    }
  }
}
</script>

<style scoped lang="less">
.dialogContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  // margin-bottom: 50px;
  .logoBox {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px 10px 10px 10px;
  }

  .myQuestionBox {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    // height: 100%;
    background: #fff;

    .myLogo {
      width: 40px;
      height: 40px;
      background-color: #FF9900;

      span {
        font-size: 22px;
        line-height: 40px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
      }
    }

    .mainQuestionBox {
      min-height: 50px;
      width: 95%;
      // display: flex;
      // justify-content: flex-start;
      // align-items: center;
      // flex-wrap: wrap;
      // overflow: hidden;
      position: relative;

      // background-color: pink;
      .innerMianQuesion {
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        overflow: hidden;

        .showQuestion {
          width: 100%;
          display: flex;
          justify-content: space-between;
          position: relative;

          .quesItem {
            padding: 10px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .imgAnswer {
              // width: 250px !important;
              // height: 250px;
              max-width: 250px;
              max-height: 250px;

              // border: 1px solid  #ddd;
              /deep/ .image-error {
                max-width: 250px;
                max-height: 250px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: #ddd;

                span {
                  margin-top: 5px;
                }
              }
            }

            .videoQuestion {
              // width: 300px;
              height: 250px;
            }
          }

          // .innerMainQuestion {
          //     width: 100%;
          //     padding-left: 10px;
          //     box-sizing: border-box;
          //     text-align: left;
          //     display: flex;
          //     flex-direction: column;

          //     .questionImgBox {
          //         margin-top: 10px;
          //         width: 230px;
          //         height: 30px;
          //         background: #FFFFFF;
          //         border-radius: 5px 5px 5px 5px;
          //         opacity: 1;
          //         border: 1px solid #E6E6E6;
          //     }
          // }

          &:hover {
            .editQuestion {
              // transform: translateX(5px);
              visibility: visible;
            }
          }

          .editQuestion {
            position: absolute;
            right: 0;
            top: 0;
            background-color: #fff;
            box-shadow: -10px 10px 10px 10px rgba(255, 255, 255, .8);
            visibility: hidden;

            /deep/ .lb-button {
              margin: unset;
            }
          }

          .editing {
            width: 100%;
            position: relative;
            padding-bottom: 10px;
            box-sizing: border-box;
            border-radius: 5px;
            border: 1px solid #b2b2b2;

            .editInput {
              width: 100%;
              height: 80%;

              /deep/.el-textarea__inner {
                border: 0 !important;
              }
            }

            .editQuestionFile {
              float: left;
              width: 85px;
              height: 85px;
              margin-left: 15px;
              border-radius: 5px 5px 5px 5px;
              border: 1px solid #E6E6E6;
              position: relative;

              img {
                width: 85px;
                height: 85px;
                border-radius: 5px 5px 5px 5px;
                display: inline-block;
              }

              &:hover {
                .imgMask {
                  display: block;
                }
              }

              .imgMask {
                position: absolute;
                top: 0;
                left: 0;
                display: none;
                width: 85px;
                height: 85px;
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 5px 5px 5px 5px;

                .iconfont {
                  font-size: 18px;
                  text-align: center;
                  line-height: 85px;
                  color: #fff;
                }
              }
            }

            .handelBox {
              float: right;
              margin-right: 10px;

              i {
                cursor: pointer;
                width: 30px;
                height: 30px;
                margin-right: 15px;
                font-size: 24px;
                color: orange;

                &:last-child {
                  color: red;
                }
              }

              // position: absolute;
              // bottom: 10px;
            }
          }
        }

      }

      .handelQuestion {
        // position: absolute;
        // right: -70px;
        // top: 0;
        text-align: left;
        .pageNum {
          margin: 0 5px;
          cursor: default;
        }

        i {
          cursor: pointer;
        }
      }
    }
  }

  .aiAnswerBox {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;

    .aiLogo {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #0049FF;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
      border-radius: 10px 10px 10px 10px;

      .iconfont {
        font-size: 24px;
        color: #fff;
      }

      .loadingLogo {
        width: 40px;
        height: 40px;
        background-position: center;
        background-size: 80%;
        background-repeat: no-repeat;
        background-image: url('~@/assets/img/juewu/answeringLogo.gif');
      }
    }

    .innerFlex {
      width: 95%;
      display: flex;
      flex-direction: column;
      min-width: 0;
    }

    .mainAnswerBox {
      width: 100%;
      min-height: 50px;
      position: relative;

      .innerMainAnswer {
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        overflow: hidden;

        .showAnswer {
          width: 100%;

          &:hover {
            .editAnswer {
              visibility: visible;
            }
          }

          .answeritem {
            padding: 10px;
            box-sizing: border-box;
            background-color: #F2F2F2;
            border-radius: 10px 10px 10px 10px;

            .imgAnswer {
              // width: 250px !important;
              // height: 250px;
              max-width: 250px;
              max-height: 250px;

              /deep/ .image-error {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: #ddd;

                span {
                  margin-top: 5px;
                }
              }
            }

            .seaseAnswer {
              margin-top: 15px;
              font-size: 16px;
              font-family: Source Han Sans CN, Source Han Sans CN;
              font-weight: 400;
              color: #999999;
            }

            .trayTitle {
              margin-bottom: 5px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 16px;
              font-family: Source Han Sans CN, Source Han Sans CN;
              font-weight: 400;
              color: #01A6FB;

              span {
                &::before {
                  content: '';
                  margin-right: 5px;
                  display: inline-block;
                  width: 8px;
                  height: 8px;
                  background-color: #01A6FB;
                  border-radius: 50%;
                }
              }
            }

            .topTray {
              margin-bottom: 5px;
              font-size: 16px;
              font-family: Source Han Sans CN, Source Han Sans CN;
              font-weight: 400;
              color: #4D4D4D;
            }

            .midTray {
              background-color: #fff;
              padding: 10px;
              border-radius: 10px 10px 10px 10px;
              box-sizing: border-box;
              width: 100%;
              height: 400px;

              .map-container {
                width: 100%;
                height: 90%;

                /deep/ .custom-content-marker {
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  text-align: center;
                  line-height: 30px;
                  color: #fff;
                  background-color: #0049FF;
                }
              }
            }

            .footTray {
              margin-top: 5px;
              background-color: #fff;
              padding: 10px;
              border-radius: 10px 10px 10px 10px;
              box-sizing: border-box;

              .footTrayDetial {
                margin-bottom: 5px;
                font-size: 14px;
                font-family: Source Han Sans CN, Source Han Sans CN;
                font-weight: 400;
                color: #4D4D4D;
                white-space: nowrap;
                span {
                  display: inline-block;
                  text-align: left;
                  margin-right: 100px;
                }
              }
            }
            .attendanceTop{
              margin-bottom: 5px;
              font-size: 16px;
              font-family: Source Han Sans CN, Source Han Sans CN;
              font-weight: 400;
              color: #4D4D4D;
            }

            .attendanceMid {
              margin-top: 5px;
              background-color: #fff;
              padding: 10px;
              border-radius: 10px 10px 10px 10px;
              box-sizing: border-box;
              overflow-x: auto;
              .el-calendar.calendar_box {
                height: 330px;
                width: 330px;

                /deep/ .el-calendar__header {
                  display: none;
                }

                /deep/.el-calendar__body {
                  padding: unset;
                }

                /deep/ .el-calendar-table {
                  th {
                    // border: 1px solid #ebeef5;
                    background: #F2F2F2;
                    // border-radius: 5px 5px 5px 5px;
                    text-align: center;
                  }

                  .el-calendar-day:hover {
                    background-color: transparent;
                  }

                  td.is-selected {
                    background-color: transparent;
                  }

                  td.next {
                    pointer-events: none;
                  }

                  td.prev {
                    pointer-events: none;
                  }

                }

                /deep/ .current,
                /deep/ .next,
                /deep/ .prev {
                  width: 80px;
                  height: 30px;
                  background-color: none;
                  padding: 5px;
                  box-sizing: border-box;
                  border: 0;

                  .el-calendar-day {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    font-weight: 700;
                    font-weight: 400;
                    color: #4D4D4D;
                    height: 35px;
                    border-radius: 5px 5px 5px 5px;
                    border: 1px solid #CCCCCC;
                  }
                }

                /deep/ .next,
                /deep/ .prev {
                  .el-calendar-day {
                    color: #CCCCCC;
                  }
                }

                /deep/ .current {
                  position: relative;

                  .date_detail_cell {
                    font-size: 12px;
                    color: #000;
                    position: absolute;
                    top: 8px;
                    left: 8px;
                    right: 8px;
                    bottom: 8px;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    padding:2px;
                    box-sizing: border-box;

                    .attanceDeial {
                      width: 40%;
                      height: 2px;
                      background-color: #1ACD90;
                    }

                    .abnomalStatus {
                      background-color: #FF4258;
                    }
                  }
                }
              }
            }

            .attendanceFoot {
              margin-top: 5px;
              background-color: #fff;
              padding: 10px;
              border-radius: 10px 10px 10px 10px;
              box-sizing: border-box;

              .attendanceFootDetial {
                margin: 8px 0;
                font-size: 14px;
                font-family: Source Han Sans CN, Source Han Sans CN;
                font-weight: 400;
                color: #4D4D4D;
                white-space: nowrap;
                span {
                  display: inline-block;
                  text-align: left;
                  margin-right: 100px;
                  min-width: 180px;
                }
              }

            }

            .el-collapse {
              border: 0;

              /deep/.el-collapse-item__header {
                font-size: 16px;
                font-family: Source Han Sans CN, Source Han Sans CN;
                font-weight: 400;
                color: #01A6FB;
                border: 0;

                &::before {
                  content: '';
                  margin-right: 5px;
                  display: inline-block;
                  width: 8px;
                  height: 8px;
                  background-color: #01A6FB;
                  border-radius: 50%;
                }
              }
              /deep/ .el-collapse-item__wrap {
                border: 0;
                // overflow-x: scroll;
                overflow-x: auto;
              }
            }
            .newAddTop{
              margin-bottom: 15px;
              font-size: 16px;
              font-family: Source Han Sans CN, Source Han Sans CN;
              font-weight: 400;
              color: #4D4D4D;
            }
            .newAddBox{
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 10px;
              box-sizing: border-box;
              width: 300px;
              height: 50px;
              background: #FFFFFF;
              border-radius: 10px 10px 10px 10px;
              font-size: 14px;
              font-family: Source Han Sans CN, Source Han Sans CN;
              font-weight: 400;
              color: #4D4D4D;
              .routerBox{
                width: 30px;
                height: 30px;
                background: #F2F2F2;
                border-radius: 5px 5px 5px 5px;
                text-align: center;
                font-size: 16px;
                line-height: 30px;
                font-weight: 800;
                cursor: pointer;
              }
            }
          }

          .editAnswer {
            width: 100%;
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            visibility: hidden;

            .rebuildButton {
              height: 25px;
              width: 100px;
              text-align: center;
              background-color: RGBA(204, 237, 254, 0.3);
              border-radius: 5px 5px 5px 5px;
              font-size: 12px;
              line-height: 25px;
              font-family: Source Han Sans CN, Source Han Sans CN;
              color: #01A6FB;
              cursor: pointer;
            }

            /deep/ .lb-button {
              margin: unset;
            }
          }

        }

      }

      .handelAnswer {
        position: absolute;
        // right: -70px;
        bottom: 0;
        .pageNum {
          margin: 0 5px;
          cursor: default;
        }

        i {
          cursor: pointer;
        }
      }
    }

    .answeringBox {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .answeringItem {
        width: 100%;
        padding-left: 10px;
        box-sizing: border-box;
        background-color: #F2F2F2;
        border-radius: 10px 10px 10px 10px;

        .innerAnswering {
          width: 100px;
          height: 40px;
          background-image: url('~@/assets/img/juewu/answeringContext.gif');
          background-size: 100%;
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      .logBox {
        width: 100%;
        text-align: left;
        margin-top: 10px;
        // padding: 10px;
        box-sizing: border-box;
        border-radius: 10px 10px 10px 10px;
        background-color: #f7fcff;

        .fontspan {
          color: #1db0fb;
          font-weight: 800;
          margin: 10px;
        }
      }
    }
  }
}

.el-popover {
  // background-color: #1db0fb!important;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px 10px 10px 10px;
  z-index: 10000;
  .innerlog {
    width: 100%;
    text-align: left;
    margin-top: 10px;
    // padding: 10px;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    // background-color: #f7fcff;

    // .fontspan {
    //     color: #1db0fb;
    //     font-weight: 800;
    //     margin: 10px;
    // }
  }
}</style>
