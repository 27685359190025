import Vue from '../src/utils/prototypeConfig'
import App from './App.vue'
import router from './router'
// 防多次点击，重复提交
import preventReClick from './utils/preventReClick'
// 引入 全局样式
import './assets/css/global.less'
// 引入第三方字体图标
import './assets/fonts/iconfont.css'
import './assets/fontFamily/font.css'
// 引入 element-ui 组件库
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 引入自己的组件
import UI from './components/index'
// 引入百度地图
// import BaiduMap from 'vue-baidu-map'
// 高德地图
import VueAMap from 'vue-amap'
// 引入封装的npm组件库
// 导入moment
import './utils/moment'
import './utils/message'
import store from './store'
import './utils/flexible'
import './assets/js/map/city/hu2_nan2_chang2_sha1.js' // 引入长沙地图
// import 'echarts/map/js/china.js' // 引入中国地图
import lottie from 'vue-lottie' // 引入动态图片
import Avue from '@smallwei/avue' // 引入封装表格插件
import '@smallwei/avue/lib/index.css'

Vue.use(Avue)
Vue.component('lottie', lottie)
Vue.use(ElementUI)
Vue.use(VueAMap)
Vue.use(UI)
VueAMap.initAMapApiLoader({
  // key: 'ab1284a712dd78d31271899cf3cc7ef3',
  key: '3c012699a6f96daa6e36c7c98d33f9aa',
  plugin: ['AMap.Autocomplete', ' AMap.LabelMarker', 'AMap.Weather', 'AMap.DistrictLayer', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation', 'AMap.MarkerClusterer', 'AMap.DistrictSearch', 'AMap.Geocoder', 'AMap.Polygon', 'AMap.MouseTool'],
  // 默认高德 sdk 版本为 1.4.4
  uiVersion: '1.0.11', // ui库版本，不配置不加载,
  v: '1.4.4'
})
// window.addEventListener('popstate', function() {
//   history.pushState(null, null, document.URL)
// })
// Vue.use(BaiduMap, {
//   // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
//   ak: 'b8UoEa90TCREjmEd0asp4xPbgiw00LgY'
// })
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
