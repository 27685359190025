<!-- 输入框组件 -->
<template>
  <div class='inputContent'>
    <div class="modelSelect">
      <el-select v-model="questionType" placeholder="请选择" @change="modelSelected" popper-class="miniModelSelect">
        <template #prefix>
          <span style="padding-left: 5px;">
            <i :class="['icon', 'iconfont', modelOptions[questionType - 1].icon]" />
          </span>
        </template>
        <el-option v-for="item in modelOptions" :key="item.value" :label="item.label" :value="item.value">
          <span style="float: left;">
            <i :class="['icon', 'iconfont', item.icon]"></i> <!-- 如果动态显示图标，这里添加判断 -->
          </span>
          <span style="padding-left: 6px;">{{ item.label }}</span>
        </el-option>
      </el-select>
      <div class="stopAnsweringBox" v-if="showStopBtn">
        <lbButton icon="tingzhihuida" class="stopButton" fill type="white" @click="ceaseBtn">停止作答</lbButton>
      </div>
      <div class="ghostbox"></div>
    </div>
    <div class="roundBox">
      <div class="topInput">
        <template v-if="questionType === 1 || questionType === 2">
          <el-input class="chat_question" v-model="chatContent" resize="none" placeholder="请输入内容" type="textarea"
            :autosize="{ minRows: 4, maxRows: 4 }" size="medium" @keydown.native="inputChange" @keyup.native="inputClick">
            <!-- <i slot="suffix" class="iconfont lebo-send"></i> -->
          </el-input>
        </template>
        <template v-if="questionType === 3 || questionType === 4 || questionType === 5">
          <div class="uploadPhoto">
            <div class="upLoadImg" v-if="imgLoading">
              <el-image fit="contain" :src="attachment">
                <div slot="error" class="image-slot">
                  <i class="el-icon-loading"></i>
                </div>
              </el-image>
              <i class="el-icon-error delUpload" @click="delattAchment" v-if="attachment"></i>
            </div>
            <el-input class="photo_question" v-model="chatContent" resize="none" placeholder="请输入内容" type="textarea"
              :autosize="{ minRows: 4, maxRows: 4 }" size="medium" @keyup.native="inputClick"
              @keydown.native="inputChange">
              <!-- <i slot="suffix" class="iconfont lebo-send"></i> -->
            </el-input>
          </div>
        </template>
        <template v-if="questionType === 6">
          <div class="uploadVideoBox">
            <el-input class="photo_question" v-model="chatContent" resize="none" placeholder="请输入内容" type="textarea"
              :autosize="{ minRows: 4, maxRows: 4 }" size="medium" @keyup.native="inputClick"
              @keydown.native="inputChange">
              <!-- <i slot="suffix" class="iconfont lebo-send"></i> -->
            </el-input>
          </div>
        </template>
      </div>
      <div class="bottomButton">
        <div class="leftbottomFile">
          <template v-if="questionType === 6">
            <div class="uplaogingVideoBox" v-if="imgLoading">
              <div class="innerItem" v-if="formVideo">
                <i class="icon iconfont lebo-videofile lefticon"></i>
                <span class="fileName">{{ uploadFileName }}</span>
                <i class="el-icon-error delUpload" @click="delattAchment"></i>
                <!-- <video v-if="attachment" :src="attachment" class="avatar" ref="uploadVideo" controls="controls"
                  style="width: 0;"></video> -->
              </div>
              <div class="innerLoadingItem" v-else>
                <i class="el-icon-loading "></i>
              </div>
            </div>
          </template>
        </div>
        <div class="rightBottomButton">
          <div class="iconBox">
            <template v-if="!isCatchVoice">
              <i class="icon iconfont lebo-yuyingduihua" @click="startSpeak"></i>
            </template>
            <template v-else>
              <div class="activeVoice" @click="endSpeak">
                <span class="voiceGif"></span>
                <i class="icon iconfont lebo-stopvoice redVoice"></i>
              </div>
            </template>
            <template v-if="questionType === 3">
              <el-upload class="avatar-uploader" accept="image/jpeg,image/jpg,image/png" action=""
                :http-request="uploadPhoto" :show-file-list="false" :before-upload="beforePhotpUploadAll">
                <i class="icon iconfont lebo-uploadimg"></i>
              </el-upload>
            </template>
            <template v-if="questionType === 4 || questionType === 5">
              <el-upload class="avatar-uploader" accept="image/png" action="" :http-request="uploadPhoto"
                :show-file-list="false" :before-upload="beforePhotpUpload">
                <i class="icon iconfont lebo-uploadimg"></i>
              </el-upload>
            </template>
            <template v-if="questionType === 6">
              <el-upload class="avatar-uploader" accept="video/*" action="上传视频的后台地址" :show-file-list="false"
                :http-request="handleVideoSuccess" :before-upload="beforeUploadVideo" >
                <i class="icon iconfont lebo-uploadvideo"></i>
              </el-upload>
            </template>
            <!-- <i class="icon iconfont lebo-shangchuanwenjian"></i> -->
          </div>
          <lbButton icon="" class="getWisdom" @click="sendRuoter()" type="orign">发送</lbButton>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { addNewQuestion, addNewChatRoom, uploadVideo } from '@/api/juewuApi.js'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import HZRecorder from '@/utils/HZRecorder.js'
import request from '@/api/systemapi'
export default {
  name: 'inputBox',
  components: {},
  props: {
    isAddNewDialog: { default: false }, // 是添加新对话还是 继续对话
    changTyping: { default: true },
    defaultContent: { type: String }, // 默认的输入文字
    showStopBtn: { default: false } // 停止作答按钮
  },
  data () {
    return {
      // 输入内容
      chatContent: '',
      questionType: 1, // 1=文生文，2=文生图，3=图生文,4=图生图 5=图绘图 当前选择的输入框类型
      newAdd: '',
      isCatchVoice: false,
      recorder: null,
      attachment: '', // 上传的文件
      jsonAttachment: '',
      attachmentGuuid: '', // 上传视频id
      attachmentName: '', // 上传文件名
      modelOptions: [{
        value: 1,
        label: '文生文',
        icon: 'lebo-wenben'
      }, {
        value: 2,
        label: '文生图',
        icon: 'lebo-wordtopic'
      }, {
        value: 3,
        label: '图生文',
        icon: 'lebo-pictoword'
      }, {
        value: 4,
        label: '图生图',
        icon: 'lebo-tupianicon'
      }, {
        value: 5,
        label: '图绘图',
        icon: 'lebo-xiutu'
      }, {
        value: 6,
        label: '视频分析',
        icon: 'lebo-videoanalysis'
      }],
      value: 1,
      imgLoading: false,
      isEnter: 0,
      isShift: 0,
      formVideo: '', // form格式video文件
      uploadFileName: '',
      noMic: false, // 麦克风
      noPromission: false // 麦克风权限

    }
  },
  watch: {
    nowInputQuestionType (val, old) {
      this.questionType = val
    },
    defaultContent (val, old) {
      console.log('val', val)
      if (val) {
        this.chatContent = val
      }
    }
  },
  computed: {
    ...mapState('juewuStore', ['allHistoryChatRoom', 'activeChatNum', 'nowInputQuestionType']),
    ...mapGetters(['getUserInfo', 'isAdmin', 'getOptions', 'getWayName'])
  },
  methods: {
    ...mapMutations('juewuStore', ['getAllHistoryRoom', 'changeActiveNum', 'changeQuestionType', 'changeNowPanlActiveName']),
    // 选中模型
    modelSelected (type) {
      this.delattAchment()
      this.questionType = type
      this.changeQuestionType(type)
      this.$emit('changeType', type)
    },
    // 回车发送
    onSubmitChat () {

    },
    inputClick (event) {
      // 获取按下的键的键值
      const keyCode = event.keyCode || event.which
      if (keyCode === 13) {
        this.isEnter = 0
      }
      if (keyCode === 16) {
        this.isShift = 0
      }
    },
    inputChange (event) {
      // 获取按下的键的键值
      const keyCode = event.keyCode || event.which
      if (keyCode === 13) {
        this.isEnter = 1
      }
      if (keyCode === 16) {
        this.isShift = 1
      }
      if (this.isShift === 1 && this.isEnter === 1) {
        // 换行
      } else if (this.isEnter === 1) {
        // console.log(this.isShift, this.isEnter, '走enter')
        this.sendRuoter() // 调用发送方法
        event.preventDefault()// 因为使用了 el-input 自适应文本高度，
        // 默认按enter会有换行效果，所以这里使用  event.preventDefault();阻止默认事件，可根据自己的项目需求进行修改
      }
    },

    // 点击发送
    sendRuoter () {
      if (!this.changTyping) return
      const obj = {}
      obj.question = this.chatContent.trim()
      obj.questionFile = this.jsonAttachment
      obj.questionFileGuuid = this.attachmentGuuid
      if (this.questionType === 1 || this.questionType === 2) {
        if (this.chatContent.trim()) {
          if (this.isAddNewDialog) {
            // 发送新增总对话请求
            this.fnAddHistoryDialog(this.chatContent.trim(), this.jsonAttachment)
          } else {
            // 继续对话传递输入内容
            this.$emit('continueChat', obj, this.questionType)
          }
          this.chatContent = ''
          this.attachment = ''
          this.jsonAttachment = ''
          this.attachmentGuuid = ''
          this.imgLoading = false
          this.attachmentName = ''
        } else {
          this.$message.error('请输入您的问题!')
        }
      } else if (this.questionType === 3 || this.questionType === 4 || this.questionType === 5) {
        if (!this.jsonAttachment) {
          this.$message.error('请上传图片')
        } else {
          if (this.chatContent.trim()) {
            if (this.isAddNewDialog) {
              // 发送新增总对话请求
              this.fnAddHistoryDialog(this.chatContent.trim(), this.jsonAttachment)
            } else {
              // 继续对话传递输入内容
              this.$emit('continueChat', obj, this.questionType)
            }
            this.chatContent = ''
            this.attachment = ''
            this.jsonAttachment = ''
            this.attachmentGuuid = ''
            this.imgLoading = false
            this.attachmentName = ''
          } else {
            this.$message.error('请输入您的问题!')
          }
        }
      } else if (this.questionType === 6) {
        if (!this.formVideo) {
          this.$message.error('请上传视频')
        } else {
          if (this.chatContent.trim()) {
            this.fnuploadVideo(this.formVideo, this.getUserInfo.user_name, this.chatContent.trim())
            // if (this.isAddNewDialog) {
            //   console.log(1)
            //   this.fnuploadVideo(this.formVideo, this.getUserInfo.user_name, this.chatContent.trim())
            //   this.$nextTick(() => {
            //     // 发送新增总对话请求
            //     this.fnAddHistoryDialog(this.chatContent.trim(), this.jsonAttachment)
            //   })
            // } else {
            //   console.log(2)
            //   this.fnuploadVideo(this.formVideo, this.getUserInfo.user_name, this.chatContent.trim())
            //   this.$nextTick(() => {
            //     const videoObj = {}
            //     videoObj.question = this.chatContent.trim()
            //     videoObj.questionFile = this.jsonAttachment
            //     videoObj.questionFileGuuid = this.attachmentGuuid
            //     // 继续对话传递输入内容
            //     this.$emit('continueChat', videoObj, this.questionType)
            //   })
            // }
            // this.chatContent = ''
            // this.attachment = ''
            // this.jsonAttachment = ''
            // this.imgLoading = false
            // this.attachmentName = ''
          } else {
            this.$message.error('请输入您的问题!')
          }
        }
      }
    },
    // 新增历史对话房间
    async fnAddHistoryDialog (msg, file) {
      const res = await addNewChatRoom({ dialogu_name: msg })
      console.log('新增历史对话', res)
      if (res && res.Code === 200) {
        this.$store.dispatch('juewuStore/fnGetHistoryChatList', { dialogu_name: msg })
        this.changeActiveNum(0)
        this.fnAddNewQuestion(res.Data, file)
      }
    },
    // 新增空回答 问题组
    async fnAddNewQuestion (data, flie) {
      const obj = {}
      obj.dialogu_id = data._id
      obj.question_type = this.questionType ? this.questionType : 1
      obj.question_name = data.dialogu_name ? data.dialogu_name : ''
      obj.question_attachment = flie || '' // 判断有无文件
      obj.answer_content = '' // 还未接收到答案先传空接收到答案后再调编辑接口补上
      obj.answer_time = '1970 01-01 00:00:00'
      obj.answer_type = 0
      obj.question_answer = [{
        question_type: this.questionType ? this.questionType : 1,
        question_name: data.dialogu_name ? data.dialogu_name : '',
        question_id: data._id + 'firstnew',
        question_attachment: '',
        answer_data: [{
          answer_content: '',
          answer_time: '1970 01-01 00:00:00',
          answer_type: 0
        }]
      }]
      console.log('新增空回答obj', obj)
      const res = await addNewQuestion(obj)
      console.log('第一次新增问题', res)
      this.newAdd = res.Data
      this.attachmentGuuid ? this.newAdd.question_file_guuid = this.attachmentGuuid : this.newAdd.question_file_guuid = ''
      this.$emit('addNew', this.newAdd)
    },
    // 开启语音说话
    startSpeak () {
      this.isCatchVoice = true
      if (!this.recorder) {
        if (this.noMic) {
          this.$message.error('未检测到麦克风')
        } else if (this.noPromission) {
          this.$message.error('请打开浏览器麦克风权限')
        }
        return
      }
      this.recorder.start()
    },
    // 结束语音 开始转文字
    endSpeak () {
      this.isCatchVoice = false
      if (!this.recorder) return
      const resVideo = this.recorder.getBlob()
      this.fileToText(resVideo)
      this.recorder.stop()
    },
    // 初始化录音
    initHZRecorder () {
      try {
        var that = this
        // <!-- 检查是否能够调用麦克风 -->
        window.AudioContext = window.AudioContext || window.webkitAudioContext
        navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia
        window.URL = window.URL || window.webkitURL
        var audioContext = new AudioContext()
        navigator.getUserMedia({ audio: true }, function (stream) {
          that.recorder = new HZRecorder(stream, {
            sampleBits: 16,
            sampleRate: 16000,
            isAuto: that.autoCatchVoice
          }, (blob) => {
            that.fileToText(blob)
          })
          console.log('麦克初始化完成')
        }, function (e) {
          console.log('No live audio input: ' + e)
          that.noMic = true
        })
      } catch (e) {
        console.log('您的浏览器不支持语音对话')
        this.noPromission = true
      }
    },
    // 语音转文字
    fileToText (blob) {
      var that = this
      const form = new FormData()
      form.append('file', blob)
      var xhr = new XMLHttpRequest()
      xhr.open('POST', '/api/MaintenanceApiException/MaintenanceMain/UploadFileToText', true)
      // eslint-disable-next-line no-unused-vars
      xhr.onload = function (e) {
        if (xhr.readyState === 4 && xhr.status === 200) {
          var obj = JSON.parse(JSON.parse(xhr.responseText))
          if (obj.err_msg === 'success.') {
            var txt = obj.result[0]
            if (txt !== '' && txt.match(/[\u4e00-\u9fa5]/g).length >= 2) {
              console.log('问题： ', txt)
              that.chatContent = txt
            }
          }
        }
      }
      xhr.send(form)
    },
    beforePhotpUpload (file) {
      const isPNG = file.type === 'image/png'
      console.log('this.question', this.questionType)
      const isLt5M = file.size / 1024 < 1024 * 5
      if (!isPNG) {
        this.$message.error('上传图片只能是 PNG 格式!')
        return false
      }
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!')
        return false
      }
      return isPNG && isLt5M
    },
    beforePhotpUploadAll (file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png'
      const isLt1M = file.size / 1024 < 1024 * 5
      if (!isJPG) {
        this.$msg.error('图片只能是 jpg、jpeg、png 格式!')
        return false
      }
      if (!isLt1M) {
        this.$msg.error('图片大小不能超过 5MB!')
        return false
      }
      return true
    },
    // 上传图片转base64
    uploadPhoto (item) {
      var that = this
      that.imgLoading = true
      const indexpoint = item.file.name.lastIndexOf('.')
      const imageName = item.file.name.substr(indexpoint)
      const src = window.URL.createObjectURL(item.file)
      var reader = new FileReader()
      reader.onload = async (e) => {
        // e.target.result  就是从本地读取的图片的base64格式,将它上传给服务器即可
        // 使用axios的post方法上传即可
        var base64image = e.target.result
        that.fnFaceRecognition(item.file.name, base64image)
      }
      reader.readAsDataURL(item.file)
    },
    // 获取图片地址
    async fnFaceRecognition (name, baseImg) {
      const res = await request.updataIcon({ name: name, strbase64: [baseImg] })
      if (res) {
        const imgFile = {
          question_file: res.data[0].FilePath,
          question_file_name: res.data[0].FileName
        }
        this.jsonAttachment = JSON.stringify(imgFile)
        this.attachment = res.data[0].FilePath
      }
    },
    // 删除图片文件
    delattAchment () {
      this.attachment = ''
      this.jsonAttachment = ''
      this.attachmentGuuid = ''
      this.attachmentName = ''
      this.formVideo = ''
      this.imgLoading = false
    },
    // 验证视频格式
    beforeUploadVideo (file) {
      if (
        [
          'video/mp4',
          'video/ogg',
          'video/flv',
          'video/avi',
          'video/wmv',
          'video/rmvb'
        ].indexOf(file.type) === -1
      ) {
        this.$message.error('请上传正确的视频格式')
        return false
      }
    },
    // 获取上传视频
    handleVideoSuccess (file) {
      this.imgLoading = true
      // console.log('file', file.file.name)
      const URL = window.URL || window.webkitURL
      console.log(URL.createObjectURL(file.file)) // 地址
      // console.log('file.file', file.file) // 文件file
      // console.log('getUserInfo', this.getUserInfo)
      var formData = new FormData()
      formData.append('file', file.file)
      this.formVideo = formData
      this.uploadFileName = file.file.name
      // this.fnuploadVideo(formData, this.getUserInfo.user_name, Date.now())
    },
    // 获取视频
    async fnuploadVideo (file, name, uid) {
      const res = await uploadVideo(file, name, uid)
      if (res) {
        const imgFile = {
          question_file: res.data[0].file_path,
          question_file_name: res.data[0].file_name,
          question_file_guuid: res.data[0].guuid
        }
        this.jsonAttachment = JSON.stringify(imgFile)
        this.attachment = res.data[0].file_path
        this.attachmentGuuid = res.data[0].guuid
        this.attachmentName = res.data[0].file_name
        if (this.isAddNewDialog) {
          // 发送新增总对话请求
          this.fnAddHistoryDialog(this.chatContent.trim(), this.jsonAttachment)
        } else {
          const videoObj = {}
          videoObj.question = this.chatContent.trim()
          videoObj.questionFile = this.jsonAttachment
          videoObj.questionFileGuuid = this.attachmentGuuid
          // 继续对话传递输入内容
          this.$emit('continueChat', videoObj, this.questionType)
        }
        this.chatContent = ''
        this.attachment = ''
        this.jsonAttachment = ''
        this.imgLoading = false
        this.attachmentName = ''
        this.formVideo = ''
      }
    },
    // 停止作答
    ceaseBtn () {
      this.$emit('stopAnsweing')
    }
    // // 全屏
    // fullScreen () {
    //   const element = this.$refs.uploadVideo
    //   if (element) {
    //     this.requestFullScreen(element)
    //   }
    // },
    // // 全屏
    // requestFullScreen (element) {
    //   console.log('ele', element)
    //   if (element.requestFullscreen) {
    //     element.requestFullscreen()
    //   } else if (element.mozRequestFullScreen) {
    //     element.mozRequestFullScreen()
    //   } else if (element.msRequestFullscreen) {
    //     element.msRequestFullscreen()
    //   } else if (element.oRequestFullscreen) {
    //     element.oRequestFullscreen()
    //   } else if (element.webkitRequestFullscreen) {
    //     element.webkitRequestFullScreen()
    //   } else {
    //     var docHtml = document.documentElement
    //     var docBody = document.body
    //     var videobox = document.getElementById('monitors')
    //     var cssText = 'width:100%;height:100%;overflow:hidden;'
    //     docHtml.style.cssText = cssText
    //     docBody.style.cssText = cssText
    //     videobox.style.cssText = cssText + ';' + 'margin:0px;padding:0px;'
    //     document.IsFullScreen = true
    //   }
    // }
  },
  created () { },
  mounted () {
    this.questionType = this.nowInputQuestionType
    this.initHZRecorder()
    // console.log('this.defaultContent', this.defaultContent)
    if (this.defaultContent) {
      this.chatContent = this.defaultContent
    }
  }
}
</script>

<style scoped lang="less">
.inputContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: #FFFFFF;
  padding: 10px;
  box-sizing: border-box;
  position: relative;

  .modelSelect {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content:space-between;
    .stopAnsweringBox {
      // height:100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .stopButton {
        width: 120px;
        height: 30px;
        background: #FFFFFF;
        border-radius: 5px 5px 5px 5px;
        border: 1px solid #FF4258;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FF4258;
      }
    }

    /deep/.el-input__prefix {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #606266;
    }
    .el-select {
      width: 150px;
    }
    .ghostbox{
      width: 150px;
    }
    /deep/.el-input__inner {
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #E6E6E6;
    }

    // 图标加动画删除
    /deep/.el-select__caret::before {
      /* 右侧图标 */
      content: '';
      transition: unset;
      transform: none;
    }

    /deep/.el-select__caret {
      transform: none;
      background-image: url('~@/assets/img/juewu/changeModel.png');
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }

    /deep/.el-select .el-input .el-select__caret.is-reverse {
      transform: none;
    }
  }

  .roundBox {
    width: 100%;
    height: 80%;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    border: 1px solid #fff;

    .topInput {
      width: 100%;

      // height: 70%;
      .chat_question {
        // width: 100%;
        height: 100%;

        /deep/.el-textarea__inner {
          // 去除文本框边界
          border: 0;
        }

        input {
          height: 100%;
          border: none;
        }
      }

      .uploadPhoto {
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding: 10px;
        box-sizing: border-box;

        .upLoadImg {
          border: 1px solid #d9d9d9;
          border-radius: 8px;
          margin: 5px;
          width: 85px;
          height: 85px;
          position: relative;

          .el-image {
            width: 100%;
            height: 100%;
          }

          /deep/ .image-slot {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
              font-size: 24px;
              color: #0049ff;
            }
          }

          .delUpload {
            position: absolute;
            cursor: pointer;
            right: -5px;
            top: -5px;
          }
        }

        .photo_question {
          // width: 80%;
          height: 100%;

          /deep/.el-textarea__inner {
            // 去除文本框边界
            border: 0;
          }

          input {
            height: 100%;
            border: none;
          }
        }
      }

      .uploadVideoBox {
        width: 100%;
        height: 100%;
        .photo_question {
          // width: 80%;
          height: 100%;

          /deep/.el-textarea__inner {
            // 去除文本框边界
            border: 0;
          }

          input {
            height: 100%;
            border: none;
          }
        }
      }
    }

    .bottomButton {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      box-sizing: border-box;

      .leftbottomFile {
        width: 30%;

        .uplaogingVideoBox {
          width: 70%;
          height: 30px;
          background: #FFFFFF;
          border-radius: 5px 5px 5px 5px;
          border: 1px solid #E6E6E6;

          .innerItem {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            box-sizing: border-box;
            padding: 0 10px;

            .lefticon {
              font-size: 24px;
              color: #01A6FB;
            }

            .fileName {
              width: 150px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            }
          }

          .innerLoadingItem {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

        }
      }

      .rightBottomButton {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 70%;

        .iconBox {
          display: flex;
          align-items: center;
          justify-content: center;

          .iconfont {
            margin-right: 15px;
            font-size: 18px;
            line-height: 18px;
          }

          .activeVoice {
            height: 30px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: 15px;
            border: #FF4258 1px solid;
            box-sizing: border-box;
            border-radius: 5px 5px 5px 5px;

            // width: 100%;
            .voiceGif {
              height: 30px;
              width: 100px;
              background-image: url('~@/assets/img/juewu/Voice02.gif');
              background-size: 80%;
              background-position: center center;
              background-repeat: no-repeat;
            }

            .redVoice {
              height: 30px;
              width: 30px;
              line-height: 30px;
              background-color: #FF4258;
              border-radius: 5px 5px 5px 5px;
              margin-right: 0;
            }
          }

          /deep/ .avatar-uploader {
            .el-upload {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .getWisdom {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100px;
          height: 30px;
        }
      }

    }
  }

}</style>
