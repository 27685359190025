import axios from './http'

// 新增集团
const addGroup = (data) => axios.post('/ParkingLotApi/Grop/Add', data)
// 修改集团
const updataGroup = (data) => axios.post('/ParkingLotApi/Grop/Update', data)
// 获取集团 列表
const getGroupList = (params) => axios.get('/ParkingLotApi/Grop/GetPageList', { params: params })
// 获取APP板块 列表
const getAppPlateList = (params) => axios.get('/ParkingLotApi/SysAppPlateOwner/GetAppPlateList')
// 获取集团详情
const getGroupDetail = (params) => axios.get('/ParkingLotApi/Grop/GetDetail', { params: params })
// 判断集团名称是否重复
const verifyGroupName = (params) => axios.get('/ParkingLotApi/Grop/VerifyGroupNameIsReuse', { params: params })
// 删除集团
const deleteGroup = (data) => axios.post('/ParkingLotApi/Grop/Delete', data)
// 根据集团标识获取路内停车场信息
const getlnParkingListByGropId = (params) => axios.get('/ParkingLotApi/Grop/GetlnParkingListByGropId', { params: params })
// 通过集团id获取该集团下监控场景
const getMonitorSenceListByGropId = (params) => axios.get('/ParkingLotApi/Grop/GetMonitorSenceListByGropId', { params: params })
// 通过集团id获取该集团下组织
const getOrganizationListByGropId = (params) => axios.get('/ParkingLotApi/Grop/GetOrganizationListByGropId', { params: params })

// 获取用户列表
const getUserList = (params) => axios.get('/ParkingLotApi/User/GetPageList', { params: params })
// 新增用户
const addNewUser = (data) => axios.post('/ParkingLotApi/User/Add', data)
// 新增用户 获取角色列表
const getroleList = (params) => axios.get('/ParkingLotApi/Role/getRoleByUserId', { params: params })
// 编辑用户
const updateUser = (data) => axios.post('/ParkingLotApi/User/Update', data)
// 新增用户 用户名重名判断
const userNameIsReuse = (params) => axios.get('/ParkingLotApi/User/VerifyUserNameIsReuse', { params: params })
// 删除用户
const userDelete = (data) => axios.post('/ParkingLotApi/User/Delete', data)
// 重置密码
const resetPwd = (data) => axios.post('/ParkingLotApi/User/UpdatePwdByUserId', data)
// 用户管理 项目分配
// const getParkingPermitList = (params) => {
//     return axios({
//         method: 'get',
//         url: '/ParkingLotApi/User/GetParkingListByUserId',
//         params
//     })
// }
// 获取集团 选项列表信息
const getGropList = (params) => axios.get('/ParkingLotApi/Grop/GetGropList', { params: params })
// 角色管理 列表
const roleManagerList = (params) => axios.get('/ParkingLotApi/Role/GetPageList', { params: params })
// 角色管理 删除角色
const deleteRole = (data) => axios.post('/ParkingLotApi/Role/Delete', data)
// 编辑角色时获取权限分配（废弃）
const rolePermisionList = () => axios.get('/ParkingLotApi/SysMenus/GetSysMenusList')
// 新增角色时获取权限分配（废弃）
const roleDetailList = (params) => axios.get('/ParkingLotApi/Role/Detail', { params: params })
// 判断角色名称是否重复
const verifyRoleNameisReuse = (params) => axios.get('/ParkingLotApi/Role/VerifyRoleNameIsReuse', { params: params })
// 角色管理 新增角色
const addRole = (data) => axios.post('/ParkingLotApi/Role/Add', data)
// 角色管理 新增角色
const addRoleNew = (data) => axios.post('/ParkingLotApi/Role/AddNew', data)
// 角色管理 编辑角色
const updateRole = (data) => axios.post('/ParkingLotApi/Role/Update', data)
// 角色管理 编辑角色
const updateRoleNew = (data) => axios.post('/ParkingLotApi/Role/UpdateNew', data)
// 获取操作日志列表接口
const getLogList = (params) => axios.get('/ParkingLotApi/OperationLog/GetPageList', { params: params })
// 根据集团标识获取用户信息
const getUserListByGropId = (params) => axios.get('/ParkingLotApi/Grop/GetUserListByGropId', { params: params })
// 上传图标
const updataIcon = (data) => axios.post('/FileUploadServiceApi/picture/UploadPicByBase64', data)
// 百度人脸识别
const faceRecognition = (data) => axios.post('/XZApiException/VisitorH5Api/FaceRecognition', data)
// 支付配置列表接口
const getPaymentConfigList = (params) => axios.get('/ParkingLotApi/SysPayConfiguration/GetPageList', { params: params })
// 获取支付配置详情
const getPayDetail = (params) => axios.get('/ParkingLotApi/SysPayConfiguration/PayDetail', { params: params })
// 获取新增支付配置详情
const getNewPayDetail = () => axios.get('/ParkingLotApi/SysPayConfiguration/GetPayDetail')
// 新增支付配置
const addPayConfig = (data) => axios.post('/ParkingLotApi/SysPayConfiguration/Add', data)

// 修改支付配置接口
const updatePayConfig = (data) => axios.post('/ParkingLotApi/SysPayConfiguration/Update', data)
// 删除支付配置
const delPayConfig = (data) => axios.post('/ParkingLotApi/SysPayConfiguration/Delete', data)

// 获取未配置支付配置的集团下拉列接口
const getPayGroupList = (params) => axios.get('/ParkingLotApi/SysPayConfiguration/groupList', { params: params })
// 查询平台配置
const queryPlatformConfig = () => axios.get('/ParkingLotApi/Grop/SelDefault')
// 保存平台配置
const updatPlatformConfig = (data) => axios.post('/ParkingLotApi/Grop/AddDefault', data)
// 集团管理员新增普通用户时获取停车场权限
const getParkingListByUserId = (params) => axios.get('/ParkingLotApi/User/GetParkingListByUserId', { params: params })
// 集团管理员新增、编辑用户时获取资产权限
const getAssetProjectListByUserId = (params) => axios.get('/ParkingLotApi/User/GetAssetProjectListByUserId', { params: params })
/// 集团管理员新增、编辑用户时获取监控场景权限
const getMonitorSceneListByUserId = (params) => axios.get('/ParkingLotApi/User/GetMonitorSceneListByUserId', { params: params })
// 集团管理员新增、编辑用户时获取组织权限
const getOrganizationListByUserId = (params) => axios.get('/ParkingLotApi/User/GetOrganizationListByUserId', { params: params })
// 集团管理员新增、编辑用户时获取大屏权限
const getGroupScreenListByUserId = (params) => axios.get('/ParkingLotApi/User/GetSysGroupScreenByUserId', { params: params })
// 账号管理 获取个人账号列表
const getPersonalAccountList = (params) => axios.get('/ParkingLotApi/SysAppUserInfo/GetPageList', { params: params })
// 账号管理 删除个人账号
const deletePersonalAccount = (data) => axios.post('/ParkingLotApi/SysAppUserInfo/Delete', data)
// 获取自定义菜单分布列表
const getSysResourceMenusPageList = (params) => axios.get('/ParkingLotApi/SysResourceMenus/GetPageList', { params: params })
// 获取图片库
const getIconLibraryList = (params) => axios.get('/ParkingLotApi/SysResourceMenus/GetIconLibraryList', { params: params })
// admin获取菜单列表
const getAdminMenusList = (params) => axios.get('/ParkingLotApi/SysMenus/AdminGetMenusList', { params: params })
// 获取菜单详情
const getSysResourceMenusDetail = (params) => axios.get('/ParkingLotApi/SysResourceMenus/Detail', { params: params })
// 新增自定义菜单
const addSysResourceMenus = (data) => axios.post('/ParkingLotApi/SysResourceMenus/Add', data)
// 编辑自定义菜单
const updateSysResourceMenus = (data) => axios.post('/ParkingLotApi/SysResourceMenus/Update', data)
// 删除自定义菜单
const delSysResourceMenus = (data) => axios.post('/ParkingLotApi/SysResourceMenus/Delete', data)
// 获取大屏自定义列表
const getBigScreenCustomizationPageList = (params) => axios.get('/ParkingLotApi/BigScreenCustomization/GetPageList', { params: params })
// 获取大屏自定义详情
const getBigScreenCustomizationDetail = (params) => axios.get('/ParkingLotApi/BigScreenCustomization/GetDetail', { params: params })
// 新增自定义大屏
const addBigScreenCustomization = (data) => axios.post('/ParkingLotApi/BigScreenCustomization/Add', data)
// 编辑自定义大屏
const updateBigScreenCustomization = (data) => axios.post('/ParkingLotApi/BigScreenCustomization/Update', data)
// 删除自定义大屏
const delBigScreenCustomization = (data) => axios.post('/ParkingLotApi/BigScreenCustomization/Delete', data)
// 获取标准件列表
const getBigScreenStandardList = (params) => axios.get('/ParkingLotApi/BigScreenStandard/GetPageStandardList', { params: params })
// 获取app自定义列表
const getAppPlatepagelist = (params) => axios.get('/ParkingLotApi/SysResourcePlate/GetPageList', { params: params })
// 获取app板块
const getResourceAppPlatelist = (params) => axios.get('/ParkingLotApi/SysResourcePlate/GetAppPlateList', { params: params })
// 新增app自定义
const addSysResourceAppPlate = (data) => axios.post('/ParkingLotApi/SysResourcePlate/Add', data)
// 修改app自定义
const uptSysResourceAppPlate = (data) => axios.post('/ParkingLotApi/SysResourcePlate/Update', data)
// 获取app自定义详情
const getAppPlatedetail = (params) => axios.get('/ParkingLotApi/SysResourcePlate/Detail', { params: params })
// 删除app自定义
const delAppPlate = (data) => axios.post('/ParkingLotApi/SysResourcePlate/Delete', data)
// 获取菜单自定义模板导入接口
const getMenusTemplateList = (params) => axios.get('/ParkingLotApi/Grop/GetMenusTemplate', { params: params })
// 获取大屏自定义模板导入接口
const getBigScreenTemplate = (params) => axios.get('/ParkingLotApi/Grop/GetBigScreenTemplate', { params: params })
// 获取APP板块自定义模板导入接口
const getAppTemplate = (params) => axios.get('/ParkingLotApi/Grop/GetAppTemplate', { params: params })
// 获取自定义域名后缀
const getCustomDomainSuffix = (params) => axios.get('/ParkingLotApi/Grop/GetCustomDomainSuffix', { params: params })
// 获取场景列表
const getSceneList = () => axios.get('/ParkingLotApi/Scene/GetAllScene')
// 删除场景列表
const deleteSceneList = (data) => axios.post('/ParkingLotApi/Scene/Delete', data)
// 新增场景
const addSceneList = (data) => axios.post('/ParkingLotApi/Scene/Add', data)
// 编辑场景
const updateSceneList = (data) => axios.post('/ParkingLotApi/Scene/Update', data)
// 获取场景自定义详情
const getSceneDetail = (params) => axios.get('/ParkingLotApi/Scene/GetDetail', { params: params })
// 发布自定义大屏
const publishBigScreenById = (data) => axios.post('/ParkingLotApi/BigScreenCustomization/UptPublishById', data)
// 获取标准件筛选列表
const getStandardDataList = (params) => axios.get('/ParkingLotApi/BigScreenStandard/GetStandardDataList', { params: params })
export default {
  addGroup,
  updataGroup,
  getGroupList,
  getAppPlateList,
  getGroupDetail,
  verifyGroupName,
  deleteGroup,
  getlnParkingListByGropId,
  getMonitorSenceListByGropId,
  getOrganizationListByGropId,
  getUserList,
  addNewUser,
  getroleList,
  updateUser,
  userNameIsReuse,
  userDelete,
  resetPwd,
  getGropList,
  roleManagerList,
  deleteRole,
  rolePermisionList,
  roleDetailList,
  verifyRoleNameisReuse,
  addRole,
  addRoleNew,
  updateRole,
  updateRoleNew,
  getLogList,
  getUserListByGropId,
  updataIcon,
  faceRecognition,
  getPaymentConfigList,
  getPayDetail,
  getNewPayDetail,
  addPayConfig,
  updatePayConfig,
  delPayConfig,
  getPayGroupList,
  queryPlatformConfig,
  updatPlatformConfig,
  getParkingListByUserId,
  getAssetProjectListByUserId,
  getMonitorSceneListByUserId,
  getOrganizationListByUserId,
  getPersonalAccountList,
  deletePersonalAccount,
  getSysResourceMenusPageList,
  getIconLibraryList,
  getAdminMenusList,
  getSysResourceMenusDetail,
  addSysResourceMenus,
  updateSysResourceMenus,
  delSysResourceMenus,
  getBigScreenCustomizationPageList,
  getBigScreenCustomizationDetail,
  addBigScreenCustomization,
  updateBigScreenCustomization,
  delBigScreenCustomization,
  getBigScreenStandardList,
  getAppPlatepagelist,
  getResourceAppPlatelist,
  addSysResourceAppPlate,
  uptSysResourceAppPlate,
  getAppPlatedetail,
  delAppPlate,
  getMenusTemplateList,
  getBigScreenTemplate,
  getAppTemplate,
  getCustomDomainSuffix,
  getSceneList,
  deleteSceneList,
  addSceneList,
  updateSceneList,
  getSceneDetail,
  publishBigScreenById,
  getStandardDataList,
  getGroupScreenListByUserId
}
