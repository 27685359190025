/**
 * 路外停车场相关请求
 */
import axios from './http'

// 停车场列表查询
export const getParkingList = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/outParking/GetPageList',
    params
  })
}

// 删除停车场
export const delParkingInfo = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/outParking/Delete',
    data
  })
}

// 查看停车场详情
export const getOutParkingDetails = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/lnParking/GetDetails',
    params
  })
}

// 查看停车场已绑定计费方案
export const getParkingCostConfigList = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/outParking/GetParkingCostDetailPageList',
    params
  })
}

// 查看停车场已配置的设备列表
export const getOutParkingDeviceConfigList = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/outParking/GetEquipmentConfigTree',
    params
  })
}

// 查看通行策略详情
export const getParkingTrafficRuleDetail = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/outParking/GetParkingTrafficRuleDetail',
    params
  })
}

// 获取停车场树形结构列表
export const getOutParkingTreeList = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/outParking/GetTreeList',
    params
  })
}

// 路外停车场计费规则配置
export const setOutParkingCost = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/outParking/SetlnParkingCost',
    data
  })
}

// 停车记录
export const getParkingRecordList = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/Record/GetPageList',
    params
  })
}

// 添加停车场子节点
export const addChildParking = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/outParking/AddChildParking',
    data
  })
}

// 新增停车场
export const addParking = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/outParking/Add',
    data
  })
}

// 编辑停车场
export const editParking = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/outParking/Update',
    data
  })
}
// 编辑子停车场或出入品
export const updateParkChild = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/outParking/UpdateChild',
    data
  })
}

// GetID
export const getID = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/outParking/GetID',
    params
  })
}

// 添加通行策略
export const addParkingTrafficRules = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/outParking/AddParkingTrafficRules',
    data
  })
}

// 查看停车场详情
export const getParkDetails = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/outParking/GetDetails',
    params
  })
}

// 编辑通行策略
export const uptParkingTrafficRules = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/outParking/UptParkingTrafficRules',
    data
  })
}

// 修改路外停车场状态
export const setOutParkingStatus = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/outParking/SetlnParkingStatus',
    data
  })
}

// 路外停车场 --- 设备配置
export const setOutParkingDeviceLayout = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/outParking/AddorUptEquipmentConfig',
    data
  })
}

// 路外停车场 --- 节点设备配置数据
export const getEquipmentConfigDetail = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/outParking/GetEquipmentConfigDetail',
    params
  })
}
// 路外停车场 --- 停车场设备配置数据数据
export const getAllEquipmentConfigList = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/outParking/GetAllEquipmentConfigTree',
    params
  })
}

// 路外停车场 --- 抓拍记录
export const getCaptureRecordList = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/Record/GetPageListbyCap',
    params
  })
}

// 获取车牌识别相机下拉框
export const getIdentifyCameralsSelectList = () => axios.get('/ParkingLotApi/outParking/GetIdentifyCamerals')

// 获取显示屏设备下拉框
export const getDisplayDevicelsSelectList = () => axios.get('/ParkingLotApi/outParking/GetDisplayDevicels')

// 获取一体机设备下拉框
export const getIntegratedMachinelsSelectList = () => axios.get('/ParkingLotApi/outParking/GetIntegratedMachinels')

// 获取视频监控设备下拉框
export const getMonitorscenelsSelectList = () => axios.get('/ParkingLotApi/outParking/Getmonitorscenels')
