/**
 * 路内停车场
 */
const curbPark = [
  // 路内停车场 —— 停车场信息 Parking Information
  {
    path: '/parking-information',
    name: 'ParkingInformation',
    // component: () => import('../view/curbPark/ParkingInformation.vue')
    component: () => import('../view/parkingManagement/views/curbPark/yardManagement/ParkingInformation/index.vue')

  },
  // // 路内停车场 —— 停车场信息 Parking Information 查看停车场
  // {
  //   path: '/look-parking',
  //   name: 'lookParking',
  //   component: () => import('../view/curbPark/lookParking.vue')
  // },
  // // 路内停车场 —— 停车场信息 Parking Information 设备配置
  // {
  //   path: '/device-layout',
  //   name: 'deviceLayout',
  //   component: () => import('../view/curbPark/deviceLayout.vue')
  // },
  // // 路内停车场 —— 停车场信息 Billing configuration 计费配置
  // {
  //   path: '/billing-configuration',
  //   name: 'billingConfiguration',
  //   component: () => import('../view/curbPark/billingConfiguration.vue'),
  //   props: true
  // },
  // 路内停车场 —— 车位信息 truckSpace information
  {
    path: '/truckspace-information',
    name: 'truckspaceInformation',
    component: () => import('../view/parkingManagement/views/curbPark/yardManagement/truckSpaceInformation/index.vue')
  },
  // 出入记录 -- 停车记录
  {
    path: '/accessre-cordpage',
    name: 'accessrecordpage',
    component: () => import('../view/parkingManagement/views/curbPark/trafficRecords/accessrecordpage/index.vue')
  },
  // 出入记录 -- 抓拍记录
  {
    path: '/picture-capture-recorded',
    name: 'incaptureRecord',
    component: () => import('../view/parkingManagement/views/curbPark/trafficRecords/incaptureRecord/index.vue')
  },
  // // 出入记录 -  查看记录
  // {
  //   path: '/search-recordpage/:id',
  //   name: 'searchrecordpage',
  //   component: () => import('../view/curbPark/accessRecords/searchrecordpage.vue'),
  //   props: true
  // },
  // 卡片管理 -  计时卡 /time-card
  {
    path: '/time-card',
    name: 'timeCard',
    component: () => import('../view/parkingManagement/views/cardManagement/timeCard/index.vue')
  },
  // // 卡片管理 -  新增计时卡 /add-time-card
  // {
  //   path: '/add-time-card/:id',
  //   name: 'addTimeCard',
  //   component: () => import('../view/curbPark/cardmanage/addTimeCard.vue'),
  //   props: true
  // },
  // // 卡片管理 -  查看卡片信息 /look-card-detail
  // {
  //   path: '/look-card-detail/:cardType/:id',
  //   name: 'lookCardDetail',
  //   component: () => import('../view/curbPark/cardmanage/lookCardDetail.vue'),
  //   props: true
  // },
  // 卡片管理 -  月租卡 /month-card
  {
    path: '/month-card',
    name: 'monthCard',
    component: () => import('../view/parkingManagement/views/cardManagement/monthCards/index.vue')
  },
  // // 卡片管理 -  新增月租卡 /add-month-card
  // {
  //   path: '/add-month-card/:id',
  //   name: 'addMonthCard',
  //   component: () => import('../view/curbPark/cardmanage/addMonthCard.vue'),
  //   props: true
  // },
  // // 卡片管理 -  月租卡续费 /month-card-renewals
  // {
  //   path: '/month-card-renewals/:id',
  //   name: 'monthCardRenewals',
  //   component: () => import('../view/curbPark/cardmanage/monthCardRenewals.vue'),
  //   props: true
  // },
  // 卡片管理 -  储值卡 /stored-card
  {
    path: '/stored-card',
    name: 'storedCard',
    component: () => import('../view/parkingManagement/views/cardManagement/storedCard/index.vue')
  },
  // // 卡片管理 -  新增储值卡 /add-stored-card
  // {
  //   path: '/add-stored-card/:id',
  //   name: 'addStoredCard',
  //   component: () => import('../view/curbPark/cardmanage/addStoredCard.vue'),
  //   props: true
  // },
  // // 卡片管理 -  储值卡充值 /stored-card-pay
  // {
  //   path: '/stored-card-pay/:id',
  //   name: 'storedCardPay',
  //   component: () => import('../view/curbPark/cardmanage/storedCardPay.vue'),
  //   props: true
  // },
  // 卡片管理 -  贵宾卡 /vip-card
  {
    path: '/vip-card',
    name: 'vipCard',
    component: () => import('../view/parkingManagement/views/cardManagement/vipCard/index.vue')
  },
  // // 卡片管理 -  新增贵宾卡 /add-vip-card
  // {
  //   path: '/add-vip-card/:id',
  //   name: 'addVipCard',
  //   component: () => import('../view/curbPark/cardmanage/addVipCard.vue'),
  //   props: true
  // },
  // 订单管理 -  异常订单
  {
    path: '/abnormal-order',
    name: 'abnormalorder',
    component: () => import('../view/parkingManagement/views/orderManagement/abnormalorder/index.vue')
  },
  // // 订单管理 -  异常订单/配置规则
  // {
  //   path: '/abnormal-order-config',
  //   name: 'abnormalorderconfig',
  //   component: () => import('../view/curbPark/ordermanage/abnormalorderconfig.vue')
  // },
  // 订单管理 -  订单申诉
  {
    path: '/order-appeal',
    name: 'orderappeal',
    component: () => import('../view/parkingManagement/views/orderManagement/orderappeal/index.vue')
  },
  // 订单管理 -  退款记录
  {
    path: '/refund-list',
    name: 'refundList',
    component: () => import('../view/parkingManagement/views/orderManagement/refundList/index.vue')
  },
  // // 订单管理 -  退款详情
  // {
  //   path: '/refund-detail/:id',
  //   name: 'refundDetail',
  //   component: () => import('../view/curbPark/ordermanage/refundDetail.vue'),
  //   props: true
  // },
  // 订单管理 -  订单信息
  {
    path: '/order-info',
    name: 'orderinfo',
    component: () => import('../view/parkingManagement/views/orderManagement/orderinfo/index.vue')
  },
  // 订单管理 -  订单信息/查看
  // {
  //   path: '/order-info-look/:id',
  //   name: 'orderinfolook',
  //   component: () => import('../view/curbPark/ordermanage/orderinfolook.vue'),
  //   props: true
  // },
  // 设备管理 ———— 高位车牌识别相机 high_camera
  {
    path: '/high-camera',
    name: 'highCamera',
    component: () => import('../view/parkingManagement/views/curbPark/equipmentManagement/highCamera/index.vue')
  },
  // // 设备管理 ———— 查看高位车牌识别相机 /look-high-camera
  // {
  //   path: '/look-high-camera',
  //   name: 'lookHighCamera',
  //   component: () => import('../view/deviceManagement/lookHighCamera.vue')
  // },
  // 设备管理 ———— 车牌识别视频桩 /video-stake
  {
    path: '/video-stake',
    name: 'videoStake',
    component: () => import('../view/parkingManagement/views/curbPark/equipmentManagement/videoStake/index.vue')
  },
  // // 设备管理 ———— 查看车牌识别视频桩  /look-video-stake
  // {
  //   path: '/look-video-stake',
  //   name: 'lookVideoStake',
  //   component: () => import('../view/deviceManagement/lookVideoStake.vue')
  // },
  // 设备管理 ———— 车牌识别路牙机 /device-machine
  {
    path: '/device-machine',
    name: 'deviceMachine',
    component: () => import('../view/parkingManagement/views/curbPark/equipmentManagement/deviceMachine/index.vue')
  },
  // // 设备管理 ———— 查看车牌识别路牙机  /look-device-machine
  // {
  //   path: '/look-device-machine',
  //   name: 'lookDeviceMachine',
  //   component: () => import('../view/deviceManagement/lookDeviceMachine.vue')
  // },
  // 设备管理 ———— 嵌入式地磁 /geomagnetic
  {
    path: '/geomagnetic',
    name: 'geomagnetic',
    component: () => import('../view/parkingManagement/views/curbPark/equipmentManagement/geomagnetic/index.vue')
  },
  // // 设备管理 ———— 查看嵌入式地磁  /look-geomagnetic
  // {
  //   path: '/look-geomagnetic',
  //   name: 'lookGeomagnetic',
  //   component: () => import('../view/deviceManagement/lookGeomagnetic.vue')
  // },
  // 计费方案 -- 计时卡计费方案 timekeeping-project
  {
    path: '/timekeeping-project',
    name: 'timekeepingProject',
    component: () => import('../view/parkingManagement/views/billingScheme/timekeepingProject/index.vue')
  },
  // // 计费方案 -- 新增编辑计时卡计费方案 add-timekeeping-project
  // {
  //   path: '/add-timekeeping-project',
  //   name: 'addTimekeepingProject',
  //   component: () => import('../view/billingProject/addTimekeepingProject.vue')
  // },
  // 计费方案 -- 月租卡计费方案 month-project
  {
    path: '/month-project',
    name: 'monthProject',
    component: () => import('../view/parkingManagement/views/billingScheme/monthProject/index.vue')
  },
  // // 计费方案 -- 新增编辑月租卡计费方案 add-month-project
  // {
  //   path: '/add-month-project',
  //   name: 'addMonthProject',
  //   component: () => import('../view/billingProject/addMonthProject.vue')
  // },
  // 计费方案 -- 时段计费方案 interval-project
  {
    path: '/interval-project',
    name: 'intervalProject',
    component: () => import('../view/parkingManagement/views/billingScheme/intervalProject/index.vue')
  },
  // // 计费方案 -- 新增修改时段计费方案 add-interval-project
  // {
  //   path: '/add-interval-project',
  //   name: 'addIntervalProject',
  //   component: () => import('../view/billingProject/addIntervalProject.vue')
  // },
  // 计费方案 -- 车型计费方案 model-project
  {
    path: '/model-project',
    name: 'modelProject',
    component: () => import('../view/parkingManagement/views/billingScheme/modelProject/index.vue')
  }
  // // 计费方案 -- 新增修改车型计费方案 add-model-project
  // {
  //   path: '/add-model-project',
  //   name: 'AddModelProject',
  //   component: () => import('../view/billingProject/addModelProject.vue')
  // }
]
export default curbPark
