// 引入组件
import Vue from 'vue'
import { Message } from 'element-ui'

// 注意：这里使用的$message,所以在使用时候也是this.$message
Vue.prototype.$msg = function (msg) {
  return Message({
    message: msg,
    duration: 1000
  })
}
// 分别对success、warning和error等样式进行设置
Vue.prototype.$msg.success = function (msg) {
  return Message.success({
    message: msg,
    duration: 1000
  })
}
Vue.prototype.$msg.warning = function (msg) {
  return Message.warning({
    message: msg,
    duration: 1000
  })
}
Vue.prototype.$msg.error = function (msg) {
  return Message.error({
    message: msg,
    duration: 1000
  })
}
Vue.prototype.$msg.info = function (msg) {
  return Message.info({
    message: msg,
    duration: 1000
  })
}
