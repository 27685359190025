/**
 * 计费方案相关请求
 */
import axios from './http';
// 计时卡计费方案列表
export const getTimeCardList = (params) => axios.get('/ParkingLotApi/TimeCard/GetPageList', { params: params })
// 新增计时/计次卡方案接口
export const addTimeOrCount = (data) => axios.post('/ParkingLotApi/TimeCard/AddTimeOrCount', data)
// 删除计时/计次/车型/时段方案
export const delTimeOrCount = (data) => axios.post('/ParkingLotApi/TimeCard/Delete', data)
// 编辑计时卡/车型/时段信息界面
export const getDetailsList = (params) => axios.get('/ParkingLotApi/TimeCard/GetDetails', { params: params })
// 编辑计时卡/计次卡方案接口
export const updateTimeOrCount = (data) => axios.post('/ParkingLotApi/TimeCard/UpdateTimeOrCount', data)
//  月租卡计费方案列表
export const getMonthlyCardList = (params) => axios.get('/ParkingLotApi/TimeCard/MonthlyCardList', { params: params })
// 编辑月租卡信息界面
export const getMonthCardView = (params) => axios.get('/ParkingLotApi/TimeCard/MonthCardView', { params: params })
// 编辑月租卡计费方案
export const editMonthCard = (data) => axios.post('/ParkingLotApi/TimeCard/EditMonthCard', data)
// 新增月租卡计费方案接口
export const addMonthCard = (data) => axios.post('/ParkingLotApi/TimeCard/AddMonthCard', data)
// 删除月租卡计费方案
export const delMonthCard = (data) => axios.post('/ParkingLotApi/TimeCard/DelMonthCard', data)
// 查询时段计费方案列表
export const getIntervalCardList = (params) => axios.get('/ParkingLotApi/TimeCard/IntervalCardList', { params: params })
// 新增时段计费方案
export const addInterval = (data) => axios.post('/ParkingLotApi/TimeCard/AddInterval', data)
// 编辑时段计费方案接口
export const editInterval = (data) => axios.post('/ParkingLotApi/TimeCard/EditInterval', data)
// 查询车型计费方案列表
export const getMondelCardList = (params) => axios.get('/ParkingLotApi/TimeCard/MondelCardList', { params: params })
// 新增车型计费方案接口
export const addModel = (data) => axios.post('/ParkingLotApi/TimeCard/AddModel', data)
// 编辑车型计费方案
export const editModel = (data) => axios.post('/ParkingLotApi/TimeCard/EditModel', data)
// 计时计次方案下拉列
export const getTimeCountSelectList = (params) => axios.get('/ParkingLotApi/TimeCard/TimeCountSelect', { params: params })
// 月租卡计费方案下拉列
export const getMonthSelectList = (params) => axios.get('/ParkingLotApi/TimeCard/MonthSelect', { params: params })
// 导出月租卡、储值卡缴费记录
export const getExportCardPaymenteList = (params) => axios.get('/ParkingLotApi/Financial/ExportCardPaymenteList', { params: params })
