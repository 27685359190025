/**
 * 数据分析模块
 */

const dataAnalysis = [
  // 停车场运营情况
  {
    path: '/dataAnalysis/packingOperationSituation',
    name: 'packingOperationSituation',
    component: () => import('../view/dataAnalysis/packingOperationSituation/index.vue')
  },
  // 停车数据流
  {
    path: '/dataAnalysis/packingDataFlow',
    name: 'packingDataFlow',
    component: () => import('../view/dataAnalysis/packingDataFlow/index.vue')
  },
  // 交通热力
  {
    path: '/dataAnalysis/parkingTrafficHeat',
    name: 'parkingTrafficHeat',
    component: () => import('../view/dataAnalysis/parkingTrafficHeat/index.vue')
  }
]
export default dataAnalysis
