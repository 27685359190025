import utils from '../utils/utils'

const testAccount = {
  namespaced: true,
  state: {
    test: 1,
    dataSummary: null, // 左一--停车场统计;
    parkingList: [], // 左二--停车场；
    paymentMethod: []
  },
  getters: {
    getTest (state) {
      return state.test
    } // -> getters['account/isAdmin']
  },
  actions: {
    testActions ({ commit }) {
      commit('changeTest')
    } // -> dispatch('account/login')
  },
  mutations: {
    // 设置支付方式
    setPaymentMethod (state, data) {
      state.paymentMethod = data
      // console.log("-=-==-=data=", JSON.stringify(data));
    }
  }
}

export default testAccount
