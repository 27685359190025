<template>
  <el-tooltip
    popper-class="lb-tooltip"
    class="item"
    effect="light"
    :enterable="false"
    :content="hint"
    placement="top"
    v-if="hint"
  >
    <button
      class="lb-button hint"
      :class="[
        `lb-${type}-hint-button`,
        {
          'is-disabled': disabled,
        },
      ]"
      @click="handleClick"
    >
      <i :class="'iconfont lebo-' + icon"></i>
    </button>
  </el-tooltip>
  <button
    class="lb-button"
    :class="[
      fill ? `lb-${type}-button` : `lb-${type}-hint-button`,
      {
        'is-disabled': disabled,
      },
    ]"
    @click="handleClick"
    v-else
  >
    <i :class="'iconfont lebo-' + icon" v-if="icon"></i>
    <slot></slot>
  </button>
</template>
<script>
export default {
  name: 'lbButton',
  props: {
    type: {
      // default succes info  warning setting err primary orign goBack white
      type: String,
      default () {
        return 'default'
      }
    },
    fill: {
      // 是否填充实色
      type: Boolean,
      default () {
        return false
      }
    },
    icon: {
      // 图标
      type: String,
      default () {
        return ''
      }
    },
    disabled: {
      // 是否禁用
      type: Boolean,
      default () {
        return false
      }
    },
    hint: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {}
  },
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {
    handleClick (e) {
      if (this.disabled) return
      e.preventDefault()
      this.$emit('click', e)
    }
  }
}
</script>
<style scoped lang="less">
@import url("../../assets/css/color.less");
.lb-button {
  padding: 7px 15px;
  border-radius: 5px;
  border: 1px solid @unselectTitleColor;
  background-color: @writeColor;
  color: @writeColor;
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }
  .iconfont {
    margin-right: 5px;
  }
}
.lb-button.hint {
  padding: 7px;
  border-radius: 5px;
  display: inline-block;
  margin-right: 10px;
  &:hover {
    cursor: pointer;
    color: @writeColor;
  }
  .iconfont {
    margin-right: 0;
  }
}

// @currentColor: #01a6fb;
.lb-default-button {
  border: 1px solid @currentColor;
  background-color: @currentColor;
  color: @writeColor;
  box-shadow: 0px 5px 10px 0px rgba(@currentColor, 0.2);
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.lb-default-hint-button {
  border: 1px solid @currentColor;
  background-color: rgba(@currentColor, 0.1);
  color: @currentColor;
  border: 1px solid rgba(@currentColor, 0.1);
  // opacity: 0.1;
  &:hover {
    background-color: rgba(@currentColor, 1);
    border: 1px solid rgba(@currentColor, 1);
    color: @writeColor;
  }
}
// @oneColor: #1acd90;
.lb-succes-button {
  border: 1px solid @oneColor;
  background-color: @oneColor;
  box-shadow: 0px 5px 10px 0px rgba(@oneColor, 0.2);
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.lb-succes-hint-button {
  border: 1px solid @oneColor;
  background-color: rgba(@oneColor, 0.1);
  color: @oneColor;
  border: 1px solid rgba(@oneColor, 0.1);
  // opacity: 0.1;
  &:hover {
    color:#fff;
    background-color: rgba(@oneColor, 1);
    border: 1px solid rgba(@oneColor, 1);
  }
}
// @twoColor: #33c0d5;
.lb-info-button {
  border: 1px solid @twoColor;
  background-color: @twoColor;
  box-shadow: 0px 5px 10px 0px rgba(@twoColor, 0.2);
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.lb-info-hint-button {
  border: 1px solid @twoColor;
  background-color: rgba(@twoColor, 0.1);
  color: @twoColor;
  border: 1px solid rgba(@twoColor, 0.1);
  // opacity: 0.1;
  &:hover {
    background-color: rgba(@twoColor, 1);
    border: 1px solid rgba(@twoColor, 1);
    color: @writeColor;
  }
}
// @threeColor: #e39f28;
.lb-warning-button {
  border: 1px solid @threeColor;
  background-color: @threeColor;
  box-shadow: 0px 5px 10px 0px rgba(@threeColor, 0.2);
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.lb-warning-hint-button {
  border: 1px solid @threeColor;
  background-color: rgba(@threeColor, 0.1);
  color: @threeColor;
  border: 1px solid rgba(@threeColor, 0.1);
  // opacity: 0.1;
  &:hover {
    background-color: rgba(@threeColor, 1);
    border: 1px solid rgba(@threeColor, 1);
    color: @writeColor;
  }
}
// @fourColor: #5d77e8;
.lb-setting-button {
  border: 1px solid @fourColor;
  background-color: @fourColor;
  box-shadow: 0px 5px 10px 0px rgba(@fourColor, 0.2);
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.lb-setting-hint-button {
  border: 1px solid @fourColor;
  background-color: rgba(@fourColor, 0.1);
  color: @fourColor;
  border: 1px solid rgba(@fourColor, 0.1);
  // opacity: 0.1;
  &:hover {
    background-color: rgba(@fourColor, 1);
    border: 1px solid rgba(@fourColor, 1);
    color: @writeColor;
  }
}
// @fiveColor: #f55265;
.lb-err-button {
  border: 1px solid @fiveColor;
  background-color: @fiveColor;
  box-shadow: 0px 5px 10px 0px rgba(@fiveColor, 0.2);
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.lb-err-hint-button {
  border: 1px solid @fiveColor;
  background-color: rgba(@fiveColor, 0.1);
  color: @fiveColor;
  border: 1px solid rgba(@fiveColor, 0.1);
  // opacity: 0.1;
  &:hover {
    background-color: rgba(@fiveColor, 1);
    border: 1px solid rgba(@fiveColor, 1);
    color: @writeColor;
  }
}

.is-disabled {
  cursor: not-allowed !important;
  opacity: 0.8 !important;
  &:hover {
    cursor: not-allowed !important;
    opacity: 0.8 !important;
  }
}

.lb-primary-button {
  border: 1px solid @oneColor;
  background-color: #fff;
  box-shadow: 0px 5px 10px 0px rgba(@oneColor, 0.2);
  &:hover {
    cursor: pointer;
    // opacity: 0.8;
  }
}

// @sixColor:#01A6FB;
.lb-primary-hint-button {
  border: 1px solid @sixColor;
  background-color:#fff;
  color: @sixColor;
  box-shadow: 0px 5px 10px 0px rgba(@sixColor, 0.2);
  &:hover {
    background-color: rgba(@sixColor, 1);
    border: 1px solid rgba(@sixColor, 1);
    color: @writeColor;
  }
}

.lb-orign-button {
  border: 1px solid @oneColor;
  background-color: @sixColor;
  box-shadow: 0px 5px 10px 0px rgba(@oneColor, 0.2);
  &:hover {
    cursor: pointer;
    // opacity: 0.8;
  }
}

// @sixColor:#01A6FB;
.lb-orign-hint-button {
  border: 1px solid @sixColor;
  background-color:@sixColor;
  color:#fff;
  // box-shadow: 0px 5px 10px 0px rgba(@sixColor, 0.2);
  &:hover {
    background-color: rgba(@sixColor, 1);
    border: 1px solid rgba(@sixColor, 1);
    color: @writeColor;
    box-shadow: 0px 5px 10px 0px rgba(@sixColor, 0.5);
  }
}
.lb-goBack-button {
  border: 1px solid @currentColor;
  background-color: @currentColor;
  color: @writeColor;
  box-shadow: 0px 5px 10px 0px rgba(@currentColor, 0.2);
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
// @sixColor:#01A6FB;
.lb-goBack-hint-button {
  border: 1px solid @currentColor;
  background-color: rgba(@currentColor, 0.1);
  color: @currentColor;
  border: 1px solid rgba(@currentColor, 0.1);
  // opacity: 0.1;
  &:hover {
    // background-color: rgba(@currentColor, 1);
    // border: 1px solid rgba(@currentColor, 1);
    // color: @writeColor;
    box-shadow: 0px 5px 10px 0px rgba(@currentColor, 0.2);
  }
}

.lb-white-button {
  border: 1px solid white;
  background-color: transparent;
  color: white;
  // box-shadow: 0px 5px 10px 0px rgba(@currentColor, 0.2);
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
// @sixColor:#01A6FB;
.lb-white-hint-button {
  border: 1px solid white;
  background-color:transparent;
  color: white;
  // opacity: 0.1;
  &:hover {
    background-color: rgba(white, .3);
    // border: 1px solid rgba(@currentColor, 1);
    // color: @writeColor;
    box-shadow: 0px 5px 10px 0px rgba(@currentColor, 0.2);
  }
}
</style>
