<template>
  <div class="command-center-count-box" :style="`height: ${height}%;`">
    <div class="command-center-count-title">
      <span class="topHead">
        <!-- <i :class="`iconfont ${icon}`"></i> -->
        <div class="topHeadIcon"></div>
        <span>{{title}}</span>
      </span>
      <ul class="right_button_box">
        <li v-for="(item, index) in rightList" :key="index" :class="['right_button',
        order_status == index + 1 ? (small ? 'select-small' : 'select'): (small ? 'unselect-small' : 'unselect')
        ]" @click="clickHandle($event, item)">{{item.title}}</li>
      </ul>
      <!-- 右侧自定义按钮插槽 -->
      <slot name="rightClick"></slot>
    </div>
    <ul class="command-center-count">
      <slot></slot>
    </ul>

  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'bigScreenModel',
  props: {
    icon: { type: String },
    title: { type: String },
    height: { type: String, default: '33' },
    rightList: { type: Array },
    order_status: { type: Number },
    small: { type: Boolean, default: false }
  },
  data () {
    return {}
  },
  created () { },
  mounted () { },
  computed: {},
  watch: {},
  methods: {
    clickHandle (e, item) {
      this.$emit('rightClick', item.status)
    }
  }
}
</script>
<style scoped lang="less">
.topHead{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .topHeadIcon{
  background-image: url('../../assets/img/mclz/topHeadIcon.png');
  width: 24px;
  height: 24px;
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  margin-right: 10px;
}
}

</style>
