/**
 * 用户相关的请求
 */
import axios from './http'
import qs from 'qs'

// 登录请求
export const login = (data) => {
  data = qs.stringify(data)
  return axios({
    method: 'post',
    url: '/identity/connect/token',
    data
  })
}

// 根据域名获取集团登录页信息
export const getLoginPageInfoByDomainAddr = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotManageEndApiException/Exception/GetLoginPageInfoByDomainAddr',
    params
  })
}

// 获取个人停车场权限
export const getOwnerParkingList = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/lnParking/GetParkingList',
    params
  })
}

// 获取用户信息
export const userInfo = () => {
  return axios({
    method: 'get',
    url: '/identity/connect/userinfo'
  })
}

// 修改用户首次登陆状态
export const updateUserFirstLogin = (data) => {
  return axios({
    method: 'post',
    url: '/ParkingLotApi/User/UpdateUserFirstLogin',
    data
  })
}
// 获取当前登录人菜单
export const getMenusList = (params) => {
  return axios({
    method: 'get',
    url: 'ParkingLotApi/SysMenus/GetCurrentMenusListNew',
    params
    // url: '/ParkingLotApi/SysMenus/GetCurrentMenusList',
  })
}

// 获取用户详情
export const getDetailUser = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/User/DetailUser',
    params
  })
}

// 获取异常告警个数
export const GetAlarmCount = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/BigScreen/GetAlarmCount',
    params
  })
}

// 获取当前用户的平台名称以及Logo以及经纬度接口
export const getGroupBigScreen = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotApi/BigScreen/GetCurruser',
    params
  })
}

export const getSelDefault = (params) => {
  return axios({
    method: 'get',
    url: '/ParkingLotManageEndApiException/Exception/SelDefault',
    params
  })
}

// 获取手机端链接地址
export const getPhoneClientLinkAddress = (params) => axios.get('/ParkingLotApi/Grop/GetPhoneClientLinkAddress', { params: params })
