const systemModule = [
  // 系统 -- 集团管理
  {
    path: '/group-manage',
    name: 'groupManage',
    component: () => import('../view/systemManagement/views/groupManage/index.vue')
  },
  // // // 系统 -- 集团管理 -- 配置
  // {
  //   path: '/add-group-manage',
  //   name: 'groupManageConfig',
  //   component: () => import('../view/systemModule/groupManage/add.vue'),
  //   props: true
  // },
  // 系统 -- 用户管理
  {
    path: '/user-manage',
    name: 'userManage',
    component: () => import('../view/systemManagement/views/userManage/index.vue')
  },
  // // 系统 -- 用户管理 用户新增编辑
  // {
  //   path: '/user-edit',
  //   name: 'userEdit',
  //   component: () => import('../view/systemModule/userEdit.vue')
  // },
  // 系统 -- 角色管理
  {
    path: '/role-manage',
    name: 'roleManage',
    component: () => import('../view/systemManagement/views/roleManage/index.vue')
  },
  // // 系统 -- 角色管理 角色新增 编辑
  // {
  //   path: '/role-edit',
  //   name: 'roleEdit',
  //   component: () => import('../view/systemModule/roleEdit.vue')
  // },
  // 系统 -- 日志管理
  {
    path: '/log-manage',
    name: 'logManage',
    component: () => import('../view/systemManagement/views/logManage/index.vue')
  },
  // 系统 -- 高级管理 --- 支付配置
  {
    path: '/payment-config',
    name: 'paymentConfig',
    component: () => import('../view/systemManagement/views/seniorManagement/paymentConfig/index.vue')
  },
  // 系统 -- 高级管理 --- 平台默认配置
  {
    path: '/platform-config',
    name: 'platformconfig',
    component: () => import('../view/systemManagement/views/seniorManagement/platformconfig/index.vue')
  },
  // // 系统 -- 高级管理 --- 新增支付配置
  // {
  //   path: '/add-payment-config',
  //   name: 'addPaymentConfig',
  //   component: () => import('../view/systemModule/addPaymentConfig.vue')
  // },
  // 系统 -- 高级管理 -- 大屏自定义 /screen-custom
  {
    path: '/screen-custom',
    name: 'addScreenCustom',
    component: () => import('../view/systemManagement/views/seniorManagement/screenCustom/index.vue')
  },
  // // 系统 -- 高级管理 -- 新增大屏自定义 /add-screen-custom
  // {
  //   path: '/add-screen-custom',
  //   name: 'screenCustom',
  //   component: () => import('../view/systemModule/screenCustom/add.vue')
  // },
  // 系统 -- 高级管理 -- 菜单自定义 /menu-custom
  {
    path: '/menu-custom',
    name: 'menuCustom',
    component: () => import('../view/systemManagement/views/seniorManagement/menuCustom/index.vue')
  },
  // // 系统 -- 高级管理 -- 新增菜单自定义 /add-menu-custom
  // {
  //   path: '/add-menu-custom',
  //   name: 'addMenuCustom',
  //   component: () => import('../view/systemModule/menuCustom/add.vue')
  // },
  // 系统 -- 高级管理 -- APP自定义 /app-custom
  {
    path: '/app-custom',
    name: 'appCustom',
    component: () => import('../view/systemManagement/views/seniorManagement/appCustom/index.vue')
  }
  // // 系统 -- 高级管理 -- APP自定义 /app-custom
  // {
  //   path: '/add-app-custom',
  //   name: 'addAppCustom',
  //   component: () => import('../view/systemModule/appCustom/add.vue')
  // }
]

export default systemModule
