// 统一导出组件
import leButton from './le-button/index'
import leCard from './le-card/index'
import leCount from './le-count/index'
import bigScreenModel from './big-screen-model/index'
import leDialog from './le-dialog/index'
import leboQuery from './le-query/index'
import leboSwitch from './le-switch/index'
import leboForm from './le-form/index'
const components = [
  leButton,
  leCard,
  leCount,
  bigScreenModel,
  leDialog,
  leboQuery,
  leboSwitch,
  leboForm
]
// 定义install方法
const install = function (Vue, options) {
  // 注册所有的组件
  components.forEach(component => {
    Vue.component(component.name, component)
  })
}
// 判断是否直接引入文件，如果是，就不用调用Vue.use()
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}
// 导出install方法
export default {
  install,
  ...components
}
