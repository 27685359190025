<template>
  <div id="app">
    <title>{{platformInfo.platform_name}}</title>
    <!-- <router-view /> -->
    <!-- 添加点击事件 -->
    <!-- <div id="app" style="height: 100%" @click="isTimeOut">
      <router-view />
    </div> -->
    <div class="hoverJWBox" v-if="shortcutList.indexOf(4) !==-1 && hoverBall" >
      <div class="hoverJWChatIcon" @click="showChatBox" v-if="isShowIconBox">
        <i class="icon iconfont lebo-juewulogo"></i>
      </div>
    </div>
    <div class="hoverJWChatBox" v-if="isShowChatBox">
        <div class="hoverJWTop">
          <div class="leftIcon">
            <span class="JWlogo"></span>
            <span class="JWtitle">觉悟大模型</span>
          </div>
          <div class="rightHandle">
            <i class="iconfont icon lebo-max" @click="toJW"></i>
            <i class="icon iconfont lebo-guanbiicon" @click="closeJWChat"></i>
          </div>
        </div>
       <miniCommonChat class="minChatBox"></miniCommonChat>
      </div>
    <router-view />
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { login, getSelDefault, getLoginPageInfoByDomainAddr } from './api/userapi'
import utils from './utils/utils'
import miniCommonChat from './view/homeNavigation/views/miracleChat/modelComponents/miniCommonChat/index.vue'
export default {
  name: 'app',
  data () {
    return {
      lastTime: null, // 最后一次点击的时间
      currentTime: null, // 当前点击的时间
      timeOut: 30 * 60 * 1000, // 设置超时时间:30分钟
      isShowChatBox: false,
      isShowIconBox: true
    }
  },
  components: { miniCommonChat },
  computed: {
    ...mapGetters(['theme']),
    ...mapState(['platformInfo']),
    ...mapGetters('menuList', ['theme', 'shortcutList', 'groupInfo']),
    ...mapState('juewuStore', ['hoverBall'])

  },
  watch: {
    // theme(newValue, oldValue) {
    //   setTimeout(() => { this.setTheme(); }, 200);
    // },
    $route (to, form) {
      // console.log("=-=-=-=to=", to.path);
      this.setRoutStatus(to.path)
      this.refreshToken()
    }
  },
  created () {
    this.$store.dispatch('fngetLoginPageInfoByDomainAddr')
    this.lastTime = new Date().getTime()
    // if (utils.getQueryString("access_toke")) {
    //   this.$router.push('/home')
    // }
    // setTimeout(() => { this.setTheme(); }, 200);
    // var style = localStorage.getItem("style");
    // console.log('style-----', style);
    // if (style) {
    //   document.getElementById("style").setAttribute("href", style);
    // } else {
    //   document.getElementById("style").setAttribute("href", "");
    // }
  },
  mounted () {
  },
  methods: {
    ...mapMutations(['setplatformInfo']),
    ...mapMutations('menuList', [
      'setRoutStatus',
      'setLeftMenuList',
      'setActiveHearderMenu',
      'setActiveMenu',
      'setMenuList',
      'addTab',
      'setDevAndHelpList',
      'setThree3DUrl',
      'setIsBigScreen',
      'setIsMenuRoute',
      'setActiveIndex',
      'closeTab',
      'closeAllTab'
    ]),
    // 根据域名获取集团登录页信息
    // async fngetLoginPageInfoByDomainAddr() {
    //   var domain_address = window.location.hostname === 'localhost' ? 'tcity.lebopark.com' : window.location.hostname;
    //   const res = await getLoginPageInfoByDomainAddr({
    //     // domain_address: 'tcity.leboparkzz.api.com'
    //     domain_address: domain_address,
    //   })
    //   console.log(res);
    //   if (res && res.Code === 200 && res.Data) {
    //     // var data = res.Data
    //     console.log(res.Data);
    //     this.setplatformInfo(res.Data);
    //     // this.login_page = data.login_page || 1
    //     // this.loginPlatformInfo.login_logo = data.logo_url || this.loginPlatformInfo.login_logo;
    //     // this.loginPlatformInfo.login_title = data.platform_name || this.loginPlatformInfo.login_title;
    //     // this.setplatformInfo({
    //     //   login_logo: this.loginPlatformInfo.login_logo,
    //     //   login_title: this.loginPlatformInfo.login_title,
    //     //   logo_url: this.loginPlatformInfo.login_logo,
    //     //   platform_name: this.loginPlatformInfo.login_title
    //     // });
    //   } else {
    //     this.setplatformInfo({
    //       login_logo: require("./assets/img/login-log.png"),
    //       login_title: "城市停车平台后台管理系统",
    //       logo_url: require("./assets/img/home_logo.png"),
    //       theme: 1,
    //       platform_name: "城市停车平台后台管理系统",
    //       login_page: 1,
    //     })
    //   }
    // },
    // async fngetSelDefault() {
    //   const res = await getSelDefault()
    //   console.log(res);
    //   if (res && res.Code === 200 && res.Data) {
    //     this.setplatformInfo(res.Data)
    //   }
    // },
    // setTheme() {
    // console.log(this.theme);
    //   let style = "";
    //   switch (this.theme) {
    //     case 1:
    //       style = "./css/one.css";
    //       break;
    //     case 2:
    //       style = "./css/two.css";
    //       break;
    //     case 3:
    //       style = "./css/three.css";
    //       break;
    //     case 4:
    //       style = "./css/four.css";
    //       break;
    //     case 5:
    //       style = "./css/five.css";
    //       break;
    //     default:
    //       style = "./css/two.css";
    //       break;
    //   }
    //   // localStorage.setItem("style", style);
    //   document.getElementById("style").setAttribute("href", style);
    // },
    isTimeOut () {
      this.currentTime = new Date().getTime() // 记录这次点击的时间
      if (this.currentTime - this.lastTime > this.timeOut) { // 判断上次最后一次点击的时间和这次点击的时间间隔是否大于30分钟
        if (sessionStorage.getItem('token')) { // 如果是登录状态
          this.$alert('您的登录已过期，请重新登录', '', {
            confirmButtonText: '确定',
            callback: action => {
              // console.log(action);
              sessionStorage.removeItem('token')
              this.$router.push('/login')
              // this.$message({

              //   type: 'info',
              //   message: `action: ${action}`
              // });
            }
          })
        } else {
          this.lastTime = new Date().getTime()
        }
      } else {
        this.lastTime = new Date().getTime() // 如果在30分钟内点击，则把这次点击的时间记录覆盖掉之前存的最后一次点击的时间
      }
    },
    async refreshToken () {
      const overTime = await sessionStorage.getItem('overexpirestime')
      const refreshToken = await sessionStorage.getItem('refreshtoken')
      const newTime = new Date().getTime()
      // console.log("(overTime - 0)= ", ((overTime - 0) > newTime));
      // console.log("(newTime > (overTime)= ", (newTime > (overTime - 60 * 59 * 1000)));
      // console.log("(newTime)= ", (newTime));
      // console.log("(overexpirestime)= ", (new Date().getTime() + 3600 * 1000));
      // console.log("(overTime)= ", (overTime));
      // console.log("(newTime > (overTime)= ", (overTime - 60 * 59 * 1000));
      // 在快过期的最后10分钟，如果使用过那么就刷新token
      if ((overTime - 0) > newTime && newTime > (overTime - 60 * 10 * 1000)) {
        try {
          const res = await login({
            client_id: 'parkinglot',
            client_secret: 'secret',
            grant_type: 'refresh_token',
            refresh_token: refreshToken
          })
          // console.log(res);
          if (res) {
            sessionStorage.setItem('token', res.access_token)
            sessionStorage.setItem('refreshtoken', res.refresh_token)
            sessionStorage.setItem('overexpirestime', `${new Date().getTime() + res.expires_in * 1000}`)
          }
        } catch (error) {

        }
      }
    },
    showChatBox () {
      this.isShowChatBox = true
      this.isShowIconBox = false
    },
    // 跳转聊天对话
    toJW () {
      this.setActiveIndex('')
      this.setLeftMenuList([])
      this.setActiveMenu('/miracle-chat')
    },
    closeJWChat () {
      this.isShowChatBox = false
      this.isShowIconBox = true
    }
  }
}
</script>

<style scoped lang="less">
#app {
  font-family: SourceHanSansCN;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #666;
  font-size: 14px;
  position: relative;
}

.BMap_cpyCtrl {
  display: none;
}
.anchorBL {
  display: none;
}

.hoverJWBox{
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 9999;
  width: 20px;
  height: 100px;
  // background: black;
  // border-radius: 92px 92px 92px 92px;
  // border: 5px solid rgba(0,73,255,0.2);
  &:hover{
    // bottom:10px;
    // right:10px;
    .hoverJWChatIcon{
      transform: translateX(-60px);
      transition: all 0.5s;
    }
  }
  .hoverJWChatIcon{
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
    background: #0049FF;
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
    border-radius: 92px 92px 92px 92px;
    cursor: pointer;
    border: 5px solid rgba(0,73,255,0.2);
    transform: translateX(-10px);
    transition: all 0.5s 1.5s;
    .iconfont{
      font-size: 26px;
      color: #fff;
      line-height: 60px;
    }
    // transition-delay:2s;
  }

}
.hoverJWChatBox{
  z-index: 9999;
    position: fixed;
    bottom: 10px;
    right: 0px;
    background-color:#fff;
    width: 450px;
    height: 860px;
    margin-bottom: 10px;
    margin-right: 10px;
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
    border-radius: 10px 10px 10px 10px;
    box-sizing: border-box;
    .hoverJWTop{
      width: 100%;
      height: 5%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background:#F2FAFF;
      border-radius: 10px 10px 0px 0px;
      // line-height:  50px;
      .leftIcon{
       display: flex;
       align-items: center;
        .JWlogo{
          margin:0 10px;
          display: inline-block;
          width: 25px;
          height: 25px;
          background-image: url('./assets/img/juewu/addNewLogo.png');
          background-size: 100%;
          background-position: center center;
          background-repeat: no-repeat;
        }
        .JWtitle{
          font-size: 16px;
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 500;
          color: #01A6FB;
        }
      }
      .rightHandle{
        .iconfont{
          font-size: 16px;
          margin: 0 10px;
        }
      }
    }
    .minChatBox{
      width: 100%;
      height: 95%;
      border-radius: 0 0 10px 10px;
    }
  }
</style>
