/**
 * 行政相关请求
 */
import axios from './http'
// 组织管理----------------------start----------------------
// 获取组织列表
export const getOrganizationPageList = (params) => axios.get('/XZApi/XZOrganization/GetPageList', { params: params })
// 删除组织
export const deleteOrganization = (data) => axios.post('/XZApi/XZOrganization/Delete', data)
// 新增组织
export const newAddOrg = (data) => axios.post('/XZApi/XZOrganization/Add', data)
// 修改组织
export const updateOrg = (data) => axios.post('/XZApi/XZOrganization/Update', data)
// 新增子组织
export const addChildrenOrg = (data) => axios.post('/XZApi/XZOrganization/AddChildren', data)
// 获取组织详情
export const getOrgDetail = (params) => axios.get('/XZApi/XZOrganization/GetDetail', { params: params })
// 导入模板
export const importArchitect = (data) => axios.post('/XZApi/XZOrganization/FileImportArchitect', data)
// 上传组织架构模板
export const uploadOrganizationFile = (data) => {
  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: '/XZApi/XZOrganization/UploadFile',
    data
  })
}
// 获取组织树结构数据列表(当前用户下所有的)
export const getOrganizatioTreeList = (params) => axios.get('/XZApi/XZOrganization/GetTreeList', { params: params })
// 获取通行组下拉列
export const getPermissionGroupSelectList = (params) => axios.get('/XZApi/XZPersonnelInfo/GetGroupList', { params: params })
// 获取通行组详情
export const getPermissionGroupDetail = (params) => axios.get('/XZApi/XZPersonnelInfo/GetGroupDetail', { params: params })
// 获取省市区列表
export const getProvinceList = (params) => axios.get('/XZApi/XZOrganization/GetProvinceList', { params: params })
// 组织管理----------------------end----------------------
// 人员管理----------------------start----------------------
// 获取人员列表
export const getPersonnelInfoPageList = (params) => axios.get('/XZApi/XZPersonnelInfo/GetPageList', { params: params })
// 新增人员
export const addNewPerson = (data) => axios.post('/XZApi/XZPersonnelInfo/Add', data)
// 查看人员信息
export const getPersonDetail = (params) => axios.get('/XZApi/XZPersonnelInfo/GetDetail', { params: params })
// 修改人员
export const updatePerson = (data) => axios.post('/XZApi/XZPersonnelInfo/Update', data)
// 删除人员
export const deletePerson = (data) => axios.post('/XZApi/XZPersonnelInfo/Delete', data)
// 加入黑名单
export const addByBlack = (data) => axios.post('/XZApi/XZPersonnelInfo/AddByBlack', data)
// 获取黑名单列表
export const getPageListByBlack = (params) => axios.get('/XZApi/XZPersonnelInfo/GetPageListByBlack', { params: params })
// 删除黑名单
export const deleteByBlack = (data) => axios.post('/XZApi/XZPersonnelInfo/DeleteByBlack', data)
// 获取人员审核列表
export const getPersonnelReviewsList = (params) => axios.get('/XZApi/XZPersonnelInfo/GetPersonnelReviews', { params: params })
// 人员审核
export const auditPersonnelReview = (data) => axios.post('/XZApi/XZPersonnelInfo/PersonnelReview', data)

// 人员管理----------------------end----------------------
// 分页获取来访人员列表
export const getXZVisitorInfoPageList = (params) => axios.get('/XZApi/XZVisitorInfo/GetPageList', { params: params })
// 获取来访人员详情
export const getXZVisitorInfoDetail = (params) => axios.get('/XZApi/XZVisitorInfo/Detail', { params: params })
// 新增访客
export const addXZVisitorInfo = (data) => axios.post('/XZApi/XZVisitorInfo/Add', data)
// 编辑访客
export const updateXZVisitorInfo = (data) => axios.post('/XZApi/XZVisitorInfo/Update', data)
// 删除访客
export const delXZVisitorInfo = (data) => axios.post('/XZApi/XZVisitorInfo/Delete', data)
// 分页获取访客审核列表
export const getXZVisitorInfoAuditPageList = (params) => axios.get('/XZApi/XZVisitorInfo/GetAuditPageList', { params: params })
// 获取访客审核详情
export const getVisitorAuditDetail = (params) => axios.get('/XZApi/XZVisitorInfo/VisitorAuditDetail', { params: params })
// 分页获取访客登记列表
export const getXZVisitorInfoRegisterPageList = (params) => axios.get('/XZApi/XZVisitorInfo/GetRegisterPageList', { params: params })
// 获取组织人员下拉列表
export const getXZVisitorInfoPersonsList = (params) => axios.get('/XZApiException/VisitorH5Api/GetPersonsList', { params: params })
// 获取组织二维码地址
export const getQRCodeUrl = (params) => axios.get('/XZApi/XZOrganization/GetQRCodeUrl', { params: params })
// 修改组织访客信息
export const updateOrganizationVisitor = (data) => axios.post('/XZApi/XZOrganization/UpdateVisitor', data)
// 新增考勤规则接口
export const addAttendanceRules = (data) => axios.post('/XZApi/XZAttendanceRules/Add', data)
// 编辑考勤规则接口
export const updateAttendanceRules = (data) => axios.post('/XZApi/XZAttendanceRules/Update', data)
// 获取考勤规则详情
export const getAttendanceRulesDetail = (params) => axios.get('/XZApi/XZAttendanceRules/Detail', { params: params })
// 获取考勤规则列表接口
export const getAttendanceRulesList = (params) => axios.get('/XZApi/XZAttendanceRules/GetPageList', { params: params })
// 删除考勤规则接口
export const delAttendanceRules = (data) => axios.post('/XZApi/XZAttendanceRules/Delete', data)
// 获取打卡区域列表接口
export const getRegionalRulesList = (params) => axios.get('/XZApi/XZRegionalRules/GetPageList', { params: params })
// 删除考勤区域规则接口
export const delRegionalRules = (data) => axios.post('/XZApi/XZRegionalRules/Delete', data)
// 添加打卡区域
export const addRegionalRules = (data) => axios.post('/XZApi/XZRegionalRules/Add', data)
// 编辑打卡区域接口
export const updateRegionalRules = (data) => axios.post('/XZApi/XZRegionalRules/Update', data)
// 获取打卡区域详情接口
export const getRegionalRulesDetail = (params) => axios.get('/XZApi/XZRegionalRules/Detail', { params: params })
// 新增考勤组接口
export const addAttendanceGroup = (data) => axios.post('/XZApi/XZAttendanceGroup/Add', data)
// 编辑考勤组接口
export const updateAttendanceGroup = (data) => axios.post('/XZApi/XZAttendanceGroup/Update', data)
// 考勤组列表接口
export const getAttendanceGroupList = (params) => axios.get('/XZApi/XZAttendanceGroup/GetPageList', { params: params })
// 删除考勤规则接口
export const delAttendanceGroup = (data) => axios.post('/XZApi/XZAttendanceGroup/Delete', data)
// 获取考勤统计列表接口
export const getAttendanceStatisticsList = (params) => axios.get('/XZApi/XZAttendanceStatistics/GetPageList', { params: params })
// 新增围栏策略接口
export const addRingFence = (data) => axios.post('/XZApi/XZRingFence/Add', data)
// 编辑围栏策略接口
export const updateRingFence = (data) => axios.post('/XZApi/XZRingFence/Update', data)
// 区域策略列表接口
export const getRingFenceList = (params) => axios.get('/XZApi/XZRingFence/GetPageList', { params: params })
// 删除围栏策略接口
export const delRingFence = (data) => axios.post('/XZApi/XZRingFence/Delete', data)
// 获取未配置围栏策略下拉列接口
// export const getRingFenceInspectorList = (data) => axios.post('/XZApi/XZRingFence/GetInspectorList', data)
export const getRingFenceInspectorList = (data) => axios.post('/XZApi/XZRingFence/GetInspectorList', data)
// 围栏策略详情接口
export const getFenceDetail = (params) => axios.get('/XZApi/XZRingFence/Detail', { params: params })
// 获取绑定围栏策略的人员
export const getFenceInspectorList = (params) => axios.get('/XZApi/XZRingFence/getInspectorList', { params: params })
// 查询未配置考勤组的巡检人员接口
export const getAttendanceGroupInspectorList = (data) => axios.post('/XZApi/XZAttendanceGroup/FindXZInspector', data)
// 获取考勤组下面的巡检人员接口
export const getAttendanceStatisticsInspectorList = (params) => axios.get('/XZApi/XZAttendanceStatistics/getInspectorList', { params: params })
// 获取考勤组下拉组织成员+收费员列表
export const getAttendanceInspectorList = (data) => axios.post('/XZApi/XZAttendanceGroup/FindXZInspector', data)
// 获取当前组织下未配置考勤规则的成员+收费员列表
export const getMembersWithoutConfiguredRules = (params) => axios.get('/XZApi/XZAttendanceGroup/FindXZAttendanceGroupOrganization', { params: params })
// 获取当前组织下未配置考勤规则的成员+收费员列表
export const getFenceWithoutConfiguredRules = (params) => axios.get('/XZApi/XZRingFence/FindXZRingFenceOrganization', { params: params })
// 获取考勤统计---考勤组下拉列
export const getGroupSelectList = (params) => axios.get('/XZApi/XZAttendanceGroup/GetGroupList', { params: params })
// 导出考勤统计接口
export const exportAttendanceStatisticsList = (data) => {
  return axios({
    method: 'post',
    url: '/XZApi/XZAttendanceStatistics/exportList',
    responseType: 'blob',
    data
  })
}
// 查看考勤打卡详情
export const getClockDetail = (params) => axios.get('/XZApi/XZPunchClock/GetClockDetail', { params: params })
// 打卡记录列表接口
export const getPunchClockList = (params) => axios.get('/XZApi/XZPunchClock/GetPageList', { params: params })
// 打卡记录详情接口
export const getPunchClockDetail = (params) => axios.get('/XZApi/XZPunchClock/Detail', { params: params })
// 行为轨迹(人员分布)接口
export const getDistributionList = (params) => axios.get('/XZApi/XZBehaviorTrajectory/distributionList', { params: params })
// 行为轨迹获取全部巡检人员
// export const getAllInspector = () => axios.get('/XZApi/XZRingFence/GetPersonList')
export const getAllInspector = () => axios.get('/XZApi/XZRingFence/GetPersonList')
// 获取围栏策略下拉列表
export const getRailStrategySelectList = () => axios.get('/XZApi/XZRingFence/GetSelectList')
// // 新增巡检人员
// export const addInspector = (data) => axios.post('/XZApi/XZInspector/Add', data)
// //  修改巡检人员
// export const updateInspector = (data) => axios.post('/XZApi/XZInspector/Update', data)
// 新增巡检人员
export const addInspector = (data) => axios.post('/ParkingLotApi/LnInspector/Add', data)
//  修改巡检人员
export const updateInspector = (data) => axios.post('/ParkingLotApi/LnInspector/Update', data)
// 获取分配巡检人员停车场的权限
export const getOwnerParkingList = (params) => axios.get('/XZApi/XZInspector/GetlnInspectorParkingList', { params: params })
// 根据停车场获取车位信息
export const getParkingSpacesList = (params) => axios.get('/XZApi/XZInspector/GetParkingSpacesList', { params: params })
// 删除巡检人员
export const delInspector = (data) => axios.post('/ParkingLotApi/LnInspector/Delete', data)
// 获取巡检人员详情
// export const getInspectorDetails = (params) => axios.get('/XZApi/XZInspector/GetInspectorDetails', { params: params })
export const getInspectorDetails = (params) => axios.get('/ParkingLotApi/LnInspector/GetInspectorDetails', { params: params })
// 获取巡检人员信息列表
// export const getInspectorList = (params) => axios.get('/XZApi/XZInspector/GetPageList', { params: params })
export const getInspectorList = (params) => axios.get('/ParkingLotApi/LnInspector/GetPageList', { params: params })
// 获取巡检人员打卡信息列表
export const getInspectorCheckList = (params) => axios.get('/XZApi/XZPunchClock/GetPageList', { params: params })
// 获取巡检人员打卡详情
export const getCheckDetails = (params) => axios.get('/XZApi/XZPunchClock/Detail', { params: params })
// 异常处理列表接口
export const getAbnormalHandleList = (params) => axios.get('/ParkingLotApi/LnInspector/AbnormalHandleList', { params: params })
// 异常处理详情接口
export const getAbnormalHandleDetail = (params) => axios.get('/ParkingLotApi/LnInspector/AbnormalHandleDetail', { params: params })
// 出入记录 车主管理 告警 的接口
// 出入记录 查询列表
export const accessRecords = (params) => axios.get('/ParkingLotApi/Record/GetPageList', { params: params })
// 出入记录 详情接口
export const recordsDetail = (params) => axios.get('/ParkingLotApi/Record/GetDetails', { params: params })
// 出入记录 停车场列表
export const parkingList = () => axios.get('/ParkingLotApi/lnParking/GetParkingList')
// 出入记录 车牌矫正
export const plateCorrect = (data) => axios.post('/ParkingLotApi/Record/PlateCorrect', data)
// 出入记录 --- 抓拍记录
export const getCaptureRecordList = (params) => axios.get('/ParkingLotApi/Record/GetPageListbyCap_In', { params: params })
// 车主管理 列表
export const ownerList = (params) => axios.get('/ParkingLotApi/CoCarInfo/GetPageList', { params: params })
// 车主管理 详情
export const ownerDetail = (params) => axios.get('/ParkingLotApi/CoCarInfo/GetDetail', { params: params })
// 车主管理 删除
export const ownerDelete = (data) => axios.post('/ParkingLotApi/CoCarInfo/Delet', data)
// 设备告警 信息列表
export const warnigList = (params) => axios.get('/ParkingLotApi/DeviceAlarm/GetPageList', { params: params })
// 设备告警 处理
export const handleInfo = (data) => axios.post('/ParkingLotApi/DeviceAlarm/Update', data)
// 所有选择框的下拉类型列表
// 出现跨域问题在路由地址后面加了个 /
export const findList = () => axios.get('/ParkingLotApi/ParkingEnum/FindList')
// 获取设备告警详情
export const getDeviceAlarmDetail = (params) => axios.get('/ParkingLotApi/DeviceAlarm/Detail', { params: params })
// 获取违规告警信息
export const getCarAlarmList = (params) => axios.get('/ParkingLotApi/CarAlarm/GetPageList', { params: params })
// 处理违规停车结果
export const setHandleAlarm = (data) => axios.post('/ParkingLotApi/CarAlarm/HandleAlarm', data)
// 轨迹异常列表接口
export const getTrackAlarmList = (params) => axios.get('/XZApi/XZTrackAlarm/GetPageList', { params: params })
// 轨迹异常详情接口
export const getTrackAlarmDetail = (params) => axios.get('XZApi/XZTrackAlarm/Detail', { params: params })
// 获取组织下拉列
export const getOrganizList = (params) => axios.get('XZApi/XZAttendanceGroup/GetSeleteList', { params: params })
