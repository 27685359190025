// 觉悟大模型api
import axios from './http'
//  判断问题类型
export const getQuestionType = (params) => axios.get('../AIApi/Ai/GetQuestionType', { params: params })
// 获取图片答案
export const getPicAnswer = (params) => axios.get('../AIApi/Ai/GetTxtToImg', { params: params })
// 通过gtp获取答案
export const getGTPStringAnswer = (params) => axios.get('../AIApi/Ai/GetTxtToTxt', { params: params })
// 获取历史对话分页列表
export const getHistoryChatList = (params) => axios.get('/MaintenanceApi/JWAccountQuestion/GetPageList', { params: params })
// 新建历史对话室
export const addNewChatRoom = (data) => axios.post('/MaintenanceApi/JWAccountQuestion/AddDialogu', data)
// 编辑历史对话名称
export const editeDialogTitle = (data) => axios.post('/MaintenanceApi/JWAccountQuestion/UpdateDialogu', data)
// 删除整个对话
export const delHistoryDialog = (data) => axios.post('/MaintenanceApi/JWAccountQuestion/Delete', data)
// 获取整个具体对话内容
export const getDialogDetial = (params) => axios.get('/MaintenanceApi/JWAccountQuestion/Detail', { params: params })
// 新增新的一条问题
export const addNewQuestion = (data) => axios.post('/MaintenanceApi/JWAccountQuestion/Add', data)
// 编辑一条问题
export const editOldQuestion = (data) => axios.post('/MaintenanceApi/JWAccountQuestion/Update', data)
// 图片接口
// 获取绘图类型
export const getPhotoType = (params) => axios.get('../AIApi/Ai/GetDrawType', { params: params })
// 图片绘制图片 png <4m 图绘图
export const photoGetPhoto = (data) => axios.post('../AIApi/Ai/CreateImageVariationForUrl', data)
// 图片描述绘制图片 图生图
export const photoDescribephoto = (data) => axios.post('../AIApi/Ai/CreateImageEditForUrl', data)
// 英文翻译
export const translationTozh = (data, msg) => axios.post('/MaintenanceApiException/MaintenanceMain/EnToZh?txt=' + msg, data)
// 智囊团操作接口
// 新增属性标签
export const addNewBrainTag = (data) => axios.post('/MaintenanceApi/JWBrainTag/Add', data)
// 编辑属性标签
export const editBrainTag = (data) => axios.post('/MaintenanceApi/JWBrainTag/Update', data)
// 删除属性标签
export const delBrainTag = (data) => axios.post('/MaintenanceApi/JWBrainTag/Delete', data)
// 获取属性标签列表
export const getBrainTagList = (params) => axios.get('/MaintenanceApi/JWBrainTag/GetPageList', { params: params })
// 获取数据源列表
export const getBrainOriginList = (params) => axios.get('/MaintenanceApi/JWBrainTrust/GetDataOriginsList', { params: params })
// 获取智囊团详情
export const getBrainHelpDetail = (params) => axios.get('/MaintenanceApi/JWBrainTrust/GetDetail', { params: params })
// 新增智囊团类型
export const addNewBrainHelp = (data) => axios.post('/MaintenanceApi/JWBrainTrust/Add', data)
// 编辑智囊团类型
export const editBrainHelp = (data) => axios.post('/MaintenanceApi/JWBrainTrust/Update', data)
// 编辑智囊团状态
export const editBrainHelpStatus = (data) => axios.post('/MaintenanceApi/JWBrainTrust/UpdateActive', data)
// 批量删除智囊团
export const delBrainHelp = (data) => axios.post('/MaintenanceApi/JWBrainTrust/Delete', data)
// 获取智囊团分页列表
export const getBrainList = (params) => axios.get('/MaintenanceApi/JWBrainTrust/GetPageList', { params: params })
// 根据标签获取智囊团列表
export const getBrainListByTag = (params) => axios.get('/MaintenanceApi/JWBrainTrust/GetPageListByTag', { params: params })
// 获取参谋会话列表
export const getBrainRoomList = (params) => axios.get('/MaintenanceApi/JWBrainTrust/GetDialoguPageList', { params: params })
// 上传视屏获取guuid
export const uploadVideo = (data, userName, questoin) => axios.post('../AIApi/VideoAnalysis/UploadFile?user_name=' + userName + '&question_desc=' + questoin, data)
// 根据guuid获取gpt答案
export const getVideoAnswer = (params) => axios.get('../AIApi/VideoAnalysis/GetGptAnswerDetail', { params: params })
// 获取问答库
export const getQuestionList = (params) => axios.get('/MaintenanceApiException/MaintenanceMain/GetAiQuestionsAnswersPageList', { params: params })
// 获取考勤详情
export const getAttenceDetials = (params) => axios.get('/XZApi/XZPunchClock/GetJWClockDetail', { params: params })
// 获取车辆出入轨迹
export const getCarInOutRecord = (params) => axios.get('/ParkingLotApi/Record/GetRecordList', { params: params })
