/**
 * 优惠劵
 */

const discountCouponList = [
  // 账号管理 —— 商家号  /shops-account
  {
    path: '/shops-account',
    name: 'shopsAccount',
    component: () => import('../view/businessManagement/views/accountManagement/shopsAccount/index.vue')
  },
  // // 账号管理 —— 新增商家号  /add-shops-account
  // {
  //   path: '/add-shops-account',
  //   name: 'addShopsAccount',
  //   component: () => import('../view/discountCoupon/addShopsAccount.vue')
  // },
  // 账号管理 —— 账号审核  /shops-audit
  {
    path: '/shops-audit',
    name: 'shopsAudit',
    component: () => import('../view/businessManagement/views/accountManagement/shopsAudit/index.vue')
  },
  // // 账号管理 —— 账号审核  /look-shops-audit
  // {
  //   path: '/look-shops-audit/:id',
  //   name: 'lookShopsAudit',
  //   component: () => import('../view/discountCoupon/lookShopsAudit.vue'),
  //   props: true
  // },
  // 优惠劵管理  —— 优惠劵配置 /discount-coupon
  {
    path: '/discount-coupon',
    name: 'discountCoupon',
    component: () => import('../view/businessManagement/views/couponManagement/discountCoupon/index.vue')
  },
  // 优惠劵管理  —— 发劵记录 /coupon-record
  {
    path: '/coupon-record',
    name: 'couponRecord',
    component: () => import('../view/businessManagement/views/couponManagement/couponRecord/index.vue')
  },
  // // 优惠劵管理  —— 查看发劵记录详情 /look-coupon-record
  // {
  //   path: '/look-coupon-record/:id',
  //   name: 'lookCouponRecord',
  //   component: () => import('../view/discountCoupon/lookCouponRecord.vue'),
  //   props: true
  // },
  // 套餐包 —— 账号续费套餐  /account-combo
  {
    path: '/account-combo',
    name: 'accountCombo',
    component: () => import('../view/businessManagement/views/packageManagement/accountCombo/index.vue')
  },
  // // 套餐包 —— 新增、编辑账号续费套餐  /add-account-combo
  // {
  //   path: '/add-account-combo',
  //   name: 'addAccountCombo',
  //   component: () => import('../view/discountCoupon/addAccountCombo.vue')
  // },
  // 套餐包 —— 优惠劵套餐 /coupon-combo
  {
    path: '/coupon-combo',
    name: 'couponCombo',
    component: () => import('../view/businessManagement/views/packageManagement/couponCombo/index.vue')
  },
  // // 套餐包 —— 新增、编辑优惠劵套餐 /add-coupon-combo
  // {
  //   path: '/add-coupon-combo',
  //   name: 'addCouponCombo',
  //   component: () => import('../view/discountCoupon/addCouponCombo.vue')
  // },
  // 订单管理 —— 账号续费订单 /account-order
  {
    path: '/account-order',
    name: 'accountOrder',
    component: () => import('../view/businessManagement/views/orderManagement/accountOrder/index.vue')
  },
  // // 订单管理 —— 查看账号续费订单 /look-account-order
  // {
  //   path: '/look-account-order/:id',
  //   name: 'lookAccountOrder',
  //   component: () => import('../view/discountCoupon/lookAccountOrder.vue'),
  //   props: true
  // },
  // 订单管理 —— 优惠劵订单 /coupon-order
  {
    path: '/coupon-order',
    name: 'couponOrder',
    component: () => import('../view/businessManagement/views/orderManagement/couponOrder/index.vue')
  }
  // // 订单管理 —— 查看优惠劵订单 /look-coupon-order
  // {
  //   path: '/look-coupon-order/:id',
  //   name: 'lookCouponOrder',
  //   component: () => import('../view/discountCoupon/lookCouponOrder.vue'),
  //   props: true
  // }
]
export default discountCouponList
