const deepClone = function (obj) {
  const objClone = Array.isArray(obj) ? [] : {}
  if (obj && typeof obj === 'object') {
    for (const key in obj) {
      if (obj[key] && typeof obj[key] === 'object') {
        objClone[key] = deepClone(obj[key])
      } else {
        objClone[key] = obj[key]
      }
    }
  }
  return objClone
}

const numRandom = function (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}
const fnGetBtnList = function (data, tag, list) {
  // console.log('data, tag, list', data, tag, list)
  data.forEach((item) => {
    if (item.menu_url === tag) {
      if (item.children && item.children.length > 0) {
        if (item.children[0].is_btn) {
          console.log('fnGetBtnList+---------', tag)
          list.push(...item.children)
        } else {
          fnGetBtnList(item.children, tag, list)
        }
      }
    } else {
      if (item.children && item.children.length > 0) {
        fnGetBtnList(item.children, tag, list)
      }
    }
  })
  return list
}

const filterTreeData = function (data) {
  var statusList = []
  for (var i = 0; i < data.length; i++) {
    statusList.push({
      status: false,
      id: ''
    })
    setStatusData(statusList[i], data[i])
  }
  // console.log(statusList);
  var index = statusList.findIndex(item => item.status)
  // console.log(index);
  return index >= 0 ? findTreeData(data, statusList[index].id) : null
}

const setStatusData = function (status, item) {
  if (item.content) {
    if (!status.id) {
      status.status = true
      status.id = item.id
    }
  } else {
    if (item.children && item.children.length > 0) {
      item.children.forEach(data => {
        setStatusData(status, data)
      })
    }
  }
}
const findTreeData = function (data, id) {
  var str
  data.forEach(item => {
    if (item.id === id) {
      return str = item
    } else {
      if (item.children && item.children.length) {
        if (!str) {
          return str = findTreeData(item.children, id)
        }
      }
    }
  })
  return str
}

const getQueryString = function (name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var r = window.location.search.substr(1).match(reg)
  if (r != null) {
    return unescape(r[2])
  }
  return null
}
// 枚举告警类型 total
const selectType = function (array) {
  const nameList = []
  const dataList = []
  array.forEach(item => {
    if (item.alarm_type.toString() === '2') {
      nameList.push('没戴口罩')
      dataList.push(item.total)
    } else if (item.alarm_type.toString() === '4') {
      nameList.push('没穿厨师服')
      dataList.push(item.total)
    } else if (item.alarm_type.toString() === '1') {
      nameList.push('厨师帽检测')
      dataList.push(item.total)
    } else if (item.alarm_type.toString() === '8') {
      nameList.push('打电话')
      dataList.push(item.total)
    } else if (item.alarm_type.toString() === '16') {
      nameList.push('吸烟')
      dataList.push(item.total)
    } else if (item.alarm_type.toString() === '32') {
      nameList.push('赤膊检测')
      dataList.push(item.total)
    } else if (item.alarm_type.toString() === '256') {
      nameList.push('猫')
      dataList.push(item.total)
    } else if (item.alarm_type.toString() === '512') {
      nameList.push('狗')
      dataList.push(item.total)
    } else if (item.alarm_type.toString() === '1024') {
      nameList.push('老鼠')
      dataList.push(item.total)
    } else if (item.alarm_type.toString() === '65536') {
      nameList.push('未盖垃圾桶')
      dataList.push(item.total)
    } else if (item.alarm_type.toString() === '3') {
      nameList.push('厨师帽检测，戴口罩检测')
      dataList.push(item.total)
    } else if (item.alarm_type.toString() === '5') {
      nameList.push('厨师帽检测，工服穿戴检测')
      dataList.push(item.total)
    } else if (item.alarm_type.toString() === '6') {
      nameList.push('戴口罩检测，工服穿戴检测')
      dataList.push(item.total)
    } else if (item.alarm_type.toString() === '7') {
      nameList.push('厨师帽检测，戴口罩检测，工服穿戴检测')
      dataList.push(item.total)
    } else if (item.alarm_type.toString() === '9') {
      nameList.push('厨师帽检测，玩手机检测')
      dataList.push(item.total)
    } else if (item.alarm_type.toString() === '12') {
      nameList.push('厨师帽检测，玩手机检测，工服穿戴检测')
      dataList.push(item.total)
    } else if (item.alarm_type.toString() === '13') {
      nameList.push('厨师帽检测，垃圾桶未盖检测')
      dataList.push(item.total)
    } else if (item.alarm_type.toString() === '0') {
      nameList.push('其他')
      dataList.push(item.total)
    } else {
      nameList.push(item.alarm_type.toString())
      dataList.push(item.total)
    }
  })
  return { nameList, dataList }
}
// 枚举告警名称数据
const selectAlarmName = function (num) {
  const val = num.toString()
  if (val === '2') {
    return '没戴口罩'
  } else if (val === '4') {
    return '没穿厨师服'
  } else if (val === '1') {
    return '打电话'
  } else if (val === '8') {
    return '吸烟'
  } else if (val === '16') {
    return '赤膊检测'
  } else if (val === '32') {
    return '猫'
  } else if (val === '256') {
    return '厨师帽检测'
  } else if (val === '512') {
    return '狗'
  } else if (val === '1024') {
    return '老鼠'
  } else if (val === '65536') {
    return '未盖垃圾桶'
  } else if (val === '3') {
    return '厨师帽检测，戴口罩检测'
  } else if (val === '5') {
    return '厨师帽检测，工服穿戴检测'
  } else if (val === '6') {
    return '戴口罩检测，工服穿戴检测'
  } else if (val === '7') {
    return '厨师帽检测，戴口罩检测，工服穿戴检测'
  } else if (val === '9') {
    return '厨师帽检测，玩手机检测'
  } else if (val === '12') {
    return '厨师帽检测，玩手机检测，工服穿戴检测'
  } else if (val === '13') {
    return '厨师帽检测，垃圾桶未盖检测'
  } else if (val === '0') {
    return '其他'
  } else {
    return val
  }
}
// 枚举操作类型
const getOperationType = function (val) {
  if (val === 'AddPerson') {
    return '新增人员'
  } else if (val === 'EditPerson') {
    return '编辑人员'
  } else if (val === 'DelPerson') {
    return '删除人员'
  } else if (val === 'GetSceneSnap') {
    return '获取抓拍场景图'
  } else if (val === 'Upconfig') {
    return '获取系统参数'
  } else if (val === 'SetRTSPCfg') {
    return '设置RTSP参数'
  } else if (val === 'GetRTSPCfg') {
    return '获取RTSP参数'
  } else if (val === 'EditAD') {
    return '编辑广告'
  } else if (val === 'DelAD') {
    return '删除广告'
  } else if (val === 'Versions') {
    return '获取版本'
  } else if (val === 'Upgrade') {
    return '升级'
  } else if (val === 'Unlock') {
    return '开锁'
  } else if (val === 'DynamicPass') {
    return '动态密码'
  } else if (val === 'ApplyCard') {
    return '申请开卡'
  } else if (val === 'CardAuth') {
    return '卡片权限'
  } else if (val === 'UpTPTconfig') {
    return '设置温度'
  } else if (val === 'inspection') {
    return '雷达检测指令'
  } else if (val === 'SetFactoryDefault') {
    return '恢复出厂设置'
  } else if (val === 'GetDoorconfig') {
    return '获取开门条件及输出控制'
  } else if (val === 'RebootDevice') {
    return '重启设备'
  } else if (val === 'UpDoorconfig') {
    return '设置开门条件'
  } else if (val === ' SetCfgSysNet') {
    return ' 配置设备 IP'
  } else if (val === 'FrontalFaceSnap') {
    return '抓拍图片'
  } else if (val === 'UpFaceconfig') {
    return '设置人脸参数'
  } else if (val === 'GetSystemLog') {
    return '设备日志'
  } else if (val === 'SetStandByUI') {
    return '修改设备待机界面'
  } else if (val === 'PlayAudioFile') {
    return '播放音频'
  } else if (val === 'UpHTTPconfig') {
    return '设置http订阅'
  } else if (val === 'GetAudioFile') {
    return '获取音频文件'
  } else if (val === 'GetCfgSysNet') {
    return '获取ip地址'
  } else if (val === 'GetMQTTconfig') {
    return '获取mqtt配置'
  } else if (val === 'UpMQTTconfig') {
    return '修改mqtt配置'
  } else if (val === 'OpenFaceDetection') {
    return '人脸检测'
  } else if (val === 'SetBootScreenUI') {
    return '替换开机UI'
  } else if (val === 'UpSoundconfig') {
    return '提示声音及界面'
  } else if (val === 'GetHTTPconfig') {
    return '获取http订阅设置'
  } else if (val === 'GetFaceconfig') {
    return '获取人脸参数'
  } else if (val === 'GetSoundconfig') {
    return '获取提示声音及界面'
  } else if (val === 'SJWYEditAD') {
    return '打开新广告'
  } else if (val === 'SJWYDelAD') {
    return '删除广告'
  } else if (val === 'SJWYDelADS') {
    return '删除全部广告'
  } else if (val === 'SJWYEditADS') {
    return '批量修改广告'
  } else if (val === 'ManualPushRecords') {
    return '手动推送'
  } else if (val === 'DeletePersons') {
    return '批量删除人员'
  } else {
    return val
  }
}
export default {
  deepClone,
  numRandom,
  fnGetBtnList,
  filterTreeData,
  getQueryString,
  selectType,
  selectAlarmName,
  getOperationType
}
