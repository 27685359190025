import utils from '../utils/utils'
import { getHistoryChatList, editeDialogTitle, delHistoryDialog, addNewChatRoom, getBrainRoomList } from '@/api/juewuApi.js'

const juewuStore = {
  namespaced: true,
  state: {
    allHistoryChatRoom: [], // 历史对话房间列表
    activeChatNum: 0, // 当前历史对话激活项
    allBrainChatRoom: [], // 智囊团房间列表
    activeBrainNum: 0, // 当前参谋激活项
    nowInputQuestionType: 1, // 当前输入框问题类型
    nowPanlActiveName: 'common', // 历史对话'common' 参谋列表'brain'
    hoverBall: true // 悬浮球显示状态

  },
  getters: {
    nowPanlActiveName: state => state.nowPanlActiveName,
    hoverBallStatus: state => state.hoverBall
  },
  actions: {
    // 获取历史对话列表
    async fnGetHistoryChatList ({ commit, state }, params) {
      console.log('state', state)
      const res = await getHistoryChatList(params)
      if (res && res.Code === 200) {
        // state.allHistoryChatRoom = res.Data
        console.log('stroe', res)
        commit('getAllHistoryRoom', res.Data)
      }
    },
    // 获取参谋对话列表
    async fngetBrainRoomList ({ commit, state }, params) {
      const res = await getBrainRoomList(params)
      if (res && res.Code === 200) {
        // state.allHistoryChatRoom = res.Data
        console.log('stroeBrain', res)
        commit('getAllBrainList', res.Data)
      }
    }
  },
  mutations: {
    // 获取全部历史对话列表
    getAllHistoryRoom (state, data) {
      state.allHistoryChatRoom = data
    },
    // 获取当前所有参谋会话列表
    getAllBrainList (state, data) {
      state.allBrainChatRoom = data
    },
    // 修改当前历史对话激活项
    changeActiveNum (state, data) {
      state.activeChatNum = data
    },
    // 修改当前参谋列表激活项
    changeBrainActiveNum (state, data) {
      state.activeBrainNum = data
    },
    // 改变当前input问题类型
    changeQuestionType (state, data) {
      state.nowInputQuestionType = data
    },
    // 改变当前历史参谋列表
    changeNowPanlActiveName (state, data) {
      state.nowPanlActiveName = data
    },
    // 开启关闭悬浮球
    changeHoverBallVisble (state, data) {
      state.hoverBall = data
    }
  }
}

export default juewuStore
