/**
 * 资产
 */
const assetsManagement = [
  // 资产 --  资产项目  /asssets-project
  {
    path: '/asssets-project',
    name: 'assetsProject',
    component: () => import('../view/assetInspectionManagement/views/asssetsProject/index.vue')
  },
  // 资产 --  资产类别  /asssets-category
  {
    path: '/asssets-category',
    name: 'assetsCategory',
    component: () => import('../view/assetInspectionManagement/views/assetsCategory/index.vue')
  },
  // 资产 --  巡检路线  /inspection-route
  {
    path: '/inspection-route',
    name: 'inspectionRoute',
    component: () => import('../view/assetInspectionManagement/views/inspectionRoute/index.vue')
  },
  // // 资产 --  新增巡检路线  /add-inspection-route
  // {
  //   path: '/add-inspection-route/:id',
  //   name: 'addInspectionRoute',
  //   component: () => import('../view/assetsManagement/addInspectionRoute.vue'),
  //   props: true
  // },
  // 资产 --  巡检计划  /inspection-plan
  {
    path: '/inspection-plan',
    name: 'inspectionPlan',
    component: () => import('../view/assetInspectionManagement/views/inspectionPlan/index.vue')
  },
  // // 资产 --  新增巡检计划  /add-inspection-plan
  // {
  //   path: '/add-inspection-plan/:id',
  //   name: 'addInspectionPlan',
  //   component: () => import('../view/assetsManagement/addInspectionPlan.vue'),
  //   props: true
  // },
  // // 资产 --  查看巡检计划  /look-inspection-plan
  // {
  //   path: '/look-inspection-plan/:id',
  //   name: 'lookInspectionPlan',
  //   component: () => import('../view/assetsManagement/lookInspectionPlan.vue'),
  //   props: true
  // },
  // 资产 --  巡检任务  /inspection-task
  {
    path: '/inspection-task',
    name: 'inspectionTask',
    component: () => import('../view/assetInspectionManagement/views/inspectionTask/index.vue')
  },
  // // 资产 --  查看巡检任务  /look-inspection-task
  // {
  //   path: '/look-inspection-task/:id',
  //   name: 'lookInspectionTask',
  //   component: () => import('../view/assetsManagement/lookInspectionTask.vue'),
  //   props: true
  // },
  // 资产 --  巡检任务记录  /task-record
  {
    path: '/task-record',
    name: 'taskRecord',
    component: () => import('../view/assetInspectionManagement/views/abnormalRecords/taskRecord/index.vue')
  },
  // // 资产 --  查看巡检任务记录  /look-task-record
  // {
  //   path: '/look-task-record/:id',
  //   name: 'lookTaskRecord',
  //   component: () => import('../view/assetsManagement/lookTaskRecord.vue'),
  //   props: true
  // },
  // 资产 --  视频巡检记录  /video-record
  {
    path: '/video-record',
    name: 'videoRecord',
    component: () => import('../view/assetInspectionManagement/views/abnormalRecords/videoRecord/index.vue')
  },
  // 资产 --  事件上报记录  /event-record
  {
    path: '/event-record',
    name: 'eventRecord',
    component: () => import('../view/assetInspectionManagement/views/abnormalRecords/eventRecord/index.vue')
  },
  // // 资产 --  查看事件上报记录  /look-event-record
  // {
  //   path: '/look-event-record/:id',
  //   name: 'lookEventRecord',
  //   component: () => import('../view/assetsManagement/lookEventRecord.vue'),
  //   props: true
  // },
  // 资产 --  待办工单  /backlog-order
  {
    path: '/backlog-order',
    name: 'backlogOrder',
    component: () => import('../view/assetInspectionManagement/views/backlogOrder/index.vue')
  }
  // // 资产 --  查看待办工单  /look-backlog-order
  // {
  //   path: '/look-backlog-order/:id',
  //   name: 'lookBacklogOrder',
  //   component: () => import('../view/assetsManagement/lookBacklogOrder.vue'),
  //   props: true
  // }
]
export default assetsManagement
