import Vue from 'vue'
import moment from 'moment'
moment.locale('zh-cn')
Vue.prototype.validSpace = function (value) {
  // value = value.replace(/\s/g, "");
  value = value.replace(/[`~!@#$%^&*()\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, '')
  return value
}
// 除了符号 可以输入数字文字字母空格
Vue.prototype.divideSymbol = function (value) {
  // value = value.replace(/\s/g, "");
  value = value.replace(/[`~!@#$%^&*()\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '')
  return value
}
// /^([0-9a-zA-Z-]{1,}\.)+([a-zA-Z]{2,})$/
// 网络地址
Vue.prototype.netUrlSymbol = function (value) {
  // value = value.replace(/\s/g, "");
  value = value.replace(/^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/g, '')
  return value
}
// 除了符号 可以输入数字文字字母空格点_.
Vue.prototype.divideSymbolDot = function (value) {
  // value = value.replace(/\s/g, "");
  value = value.replace(/[`~!@#$%^&*()\-+=<>?:"{}|,/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '')
  return value
}
// 字母+数字
Vue.prototype.validNumberLetter = function (value) {
  // value = value.replace(/\s/g, "");
  value = value.replace(/^!([A-Za-z0-9])*$/g, '').replace(/\s/g, '')
  return value
}
// 浮点数
Vue.prototype.floatNumber = function (value) {
  // value = value.replace(/\s/g, "");
  value = value.replace(/[`a-zA-Z~!@#$%^&*()\-+=<>?:"{}|,/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、 ]/g, '')
  return value
}
// 正整数
Vue.prototype.validNumberInteger = function (value) {
  if (!/^[1-9]\d*$/.test(value)) value = value.replace(/[\s\S]/g, '')
  return value
}
Vue.prototype.validSpacing = function (value) {
  value = value.replace(/\s/g, '')
  // if (value && value.length > 0) {
  //     var start = value.substr(0, 1);
  //     var reg = new RegExp(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/)
  //     if (reg.test(start)) {
  //         value = value.substr(1);
  //     }
  // }
  return value
}
Vue.prototype.validFacility = function (value) {
  value = value.replace(/[\u4E00-\u9FA5]/g, '').replace(/\s/g, '')
  // if (value && value.length > 0) {
  //     var start = value.substr(0, 1);
  //     var reg = new RegExp(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/)
  //     if (reg.test(start)) {
  //         value = value.substr(1);
  //     }
  // }
  return value
}

// 除去中文符号
Vue.prototype.validSpacingSpecial = function (value) {
  value = value.replace(/\s/g, '')
  if (value && value.length > 0) {
    var start = value.substr(0, 1)
    var reg = new RegExp(/[`~!@#$%^&*()\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/)
    if (reg.test(start)) {
      value = value.substr(1)
    }
  }
  return value
}
Vue.prototype.validPlateHead = function (value) {
  if (!/^[\u4e00-\u9fa5]{1}([A-Z]{1})?$/.test(value)) value = value.replace(/[\s\S]$/, '')
  return value
}

Vue.prototype.validPlatetail = function (value) {
  if (!/^([A-Z_0-9]{1,6})?$/.test(value)) value = value.replace(/[\s\S]$/, '')
  return value
}
Vue.prototype.validPlatetoUpperCase = function (value) {
  value = value.replace(/[`~!@#$%^&*()_+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, '')
  return value.toUpperCase()
}
// 完整的为牌号正则表达式
Vue.prototype.validPlate = function (value) {
  if (value.length <= 2) {
    if (!/^[\u4e00-\u9fa5]{1}([A-Z]{1})?$/.test(value)) value = value.replace(/[\s\S]$/, '')
    return value
  }
  if (!/^[\u4e00-\u9fa5]{1}([A-Z]{1})([A-Z_0-9]{1,6})?$/.test(value)) value = value.replace(/[\s\S]$/, '')
  return value
}

Vue.prototype.validSpecialChar = function (value) {
  value = value.replace(/[`~!@@#$%^&*()\-+=<>?:"{}|./'\\[\]·~@@#￥%……&*（）——\-+={}|《》【】；。]/g, '').replace(/\s/g, '')
  return value
}

Vue.prototype.validNumber = function (value) {
  value = value.replace(/^\.+|[^\d.]/g, '')
  return value
}
Vue.prototype.defaultsZero = function (value) {
  value = value.replace(/^\.+|[^\d.]/g, '')
  if (value === '') {
    return '0'
  } else if (value.length >= 2 && value.startsWith('0')) {
    return value.substr(1)
  }
  return value
}
// Vue.prototype.card_type = function (cardtype) {
//     switch (cardtype) {
//         case 0:
//             return "临时卡";
//         case 1:
//             return "计时卡";
//         case 2:
//             return "月租卡";
//         case 3:
//             return "储值卡";
//         case 4:
//             return "贵宾卡";
//     }
// }
// // 支付方式
// Vue.prototype.pay_way = function (way) {
//     switch (way) {
//         case 0:
//             return "全部";
//         case 1:
//             return "微信";
//         case 2:
//             return "支付宝";
//         case 3:
//             return "现金";
//     }
// }
// // 订单来源
// Vue.prototype.order_source = function (source) {
//     switch (source) {
//         case 1:
//             return "APP";
//         case 2:
//             return "公众号(H5)";
//         case 3:
//             return "微信小程序";
//         case 4:
//             return "支付宝小程序(预留)";
//         case 5:
//             return "安卓 APP(预留)";
//         case 6:
//             return "IOS App(预留)";
//         case 7:
//             return "系统生成";
//     }
// }
// Vue.prototype.order_status = function (status) {
//     switch (status) {
//         case 1:
//             return "待支付";
//         case 2:
//             return "已支付";
//     }
// }
// // 异常订单 处理状态
// Vue.prototype.handlestatus = function (status) {
//     switch (status) {
//         case 1:
//             return "未处理";
//         case 2:
//             return "已处理";
//     }
// }
// // 设备类型
// Vue.prototype.devicesType = function (type) {
//     switch (type) {
//         case 1:
//             return "地磁";
//         case 2:
//             return "高位相机";
//         case 3:
//             return "路牙机";
//         case 4:
//             return "视频桩";
//         case 5:
//             return "人工录入";
//         default:
//             return '/';
//     }
// }
Vue.prototype.validDateTime = function rTime (date) {
  // var json_date = new Date(date).toJSON();
  // return new Date(new Date(json_date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
  var newDate
  if (moment(date).format('YYYY-MM-DD') === '0001-01-01' || date === null) {
    newDate = '/'
  } else {
    newDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
  }
  return newDate
}
Vue.prototype.validDate = function (date) {
  var newDate
  if (moment(date).format('YYYY-MM-DD') === '0001-01-01' || date === null) {
    newDate = '/'
  } else {
    newDate = moment(date).format('YYYY-MM-DD')
  }
  return newDate
}
Vue.prototype.validTime = function (date) {
  return moment(date).format('HH:mm:ss')
}
Vue.prototype.deepClone = function (obj) {
  const objClone = Array.isArray(obj) ? [] : {}
  if (obj && typeof obj === 'object') {
    for (const key in obj) {
      if (obj[key] && typeof obj[key] === 'object') {
        objClone[key] = this.deepClone(obj[key])
      } else {
        objClone[key] = obj[key]
      }
    }
  }
  return objClone
}
Vue.prototype.orderHandleWay = function (type) {
  switch (type) {
    case 1:
      return '修改停车费'
    case 2:
      return '全额退款'
    case 3:
      return '驳回申诉'
    default:
      return '/'
  }
}
Vue.prototype.comparaDataTime = function (startdate, enddate) {
  // console.log("==--=startdate=-===", startdate);
  // console.log("====-=-=-=enddate-=-=-=-=", enddate);
  if (startdate === null || startdate === '') {
    return true
  }
  if (enddate === null || enddate === '') {
    return true
  }
  var oDate1 = new Date(startdate)
  var oDate2 = new Date(enddate)
  if (oDate1.getTime() > oDate2.getTime()) {
    this.$msg.warning('结束时间不能小于开始时间')
    return false
  } else {
    return true
  }
}
Vue.prototype.isLongTime = function (date) {
  if (!date) {
    return false
  }
  // console.log('==--==--==--==', moment(date).format("YYYY-MM-DD HH:mm:ss"));
  if (moment(date).format('YYYY-MM-DD HH:mm:ss') === '3000-01-01 00:00:00') {
    return true
  } else {
    return false
  }
}

Vue.prototype.getTimeText = (checkTime, nowTime) => {
  var startTime = moment(checkTime).unix()
  var endTime = nowTime === '0001-01-01T00:00:00+08:05' ? parseInt(new Date().getTime() / 1000) : moment(nowTime).unix()
  var difftime = (endTime - startTime) // 计算时间差,并把毫秒转换成秒
  var days = parseInt(difftime / 86400) // 天24*60*60
  var hours = parseInt(difftime / 3600) - 24 * days // 小时 60*60 总小时数-过去的小时数=现在的小时数
  var minutes = parseInt(difftime / 60) - 24 * days * 60 - hours * 60
  if (days === 0 && hours === 0) {
    return minutes + '分钟'
  } else if (days === 0 && minutes === 0) {
    return hours + '小时'
  } else if (days === 0) {
    return `${hours}小时${minutes > 9 ? minutes : '0' + minutes}分钟`
  } else if (hours === 0 && minutes === 0) {
    return `${days}天`
  } else if (hours === 0) {
    return `${days}天${minutes > 9 ? minutes : '0' + minutes}分钟`
  } else if (minutes === 0) {
    return `${days}天${hours > 9 ? hours : '0' + hours}小时`
  } else {
    return `${days}天${hours > 9 ? hours : '0' + hours}小时${minutes > 9 ? minutes : '0' + minutes}分钟`
  }
  // if (days === 0) {
  //     return `${hours > 9 ? hours : '0' + hours}小时${minutes > 9 ? minutes : '0' + minutes}分钟`;
  // } else {
  //     return `${days > 9 ? days : '0' + days}天${hours > 9 ? hours : '0' + hours}小时`;
  // }
}

Vue.prototype.getTimeLength = (time) => {
  const minute = parseInt(time)
  var days = parseInt(minute / (24 * 60))
  var hours = parseInt(minute / 60) - days * 24
  var minutes = minute - hours * 60 - days * 24 * 60
  //  if (hours === 0) {
  //     return `${minutes > 9 ? minutes : '0' + minutes}分钟`;
  // } else {
  //     return `${hours > 9 ? hours : '0' + hours}小时${minutes > 9 ? minutes : '0' + minutes}分钟`;
  // }
  if (days === 0 && hours === 0) {
    return `${minutes}分钟`
  } else if (days === 0 && minutes === 0) {
    return `${hours}小时`
  } else if (days === 0) {
    return `${hours}小时${minutes}分钟`
  } else if (hours === 0 && minutes === 0) {
    return `${days}天`
  } else if (hours === 0) {
    return `${days}天${minutes}分钟`
  } else if (minutes === 0) {
    return `${days}天${hours}小时`
  } else {
    return `${days}天${hours}小时${minutes}分钟`
  }
}
// 将单张图片做成数组配合el-image使用
Vue.prototype.formatImage = (imagstr) => {
  if (imagstr) {
    var newArray = imagstr.split(',')
    // console.log("-=-=-=-=-=-newArray=", newArray);
    newArray = newArray.filter(item => {
      return item
    })
    return newArray
  }
  return []
}
Vue.prototype.getFirstImage = (imagstr) => {
  if (imagstr) {
    var newArray = imagstr.split(',')
    var newimageStr = ''
    if (newArray.length > 0) {
      newimageStr = newArray[0]
    }
    return newimageStr
  }
  return ''
}
Vue.prototype.getMapStyleNum = (mapStyel) => {
  switch (mapStyel) {
    case 'normal':
      return 0
    case 'light':
      return 1
    case 'dark':
      return 2
    case 'blue':
      return 3
    case 'grey':
      return 4
    case 'macaron':
      return 5
    case 'darkblue':
      return 6
    default:
      return 4
  }
}
Vue.prototype.numRandom = function (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}
Vue.prototype.numtoDateTime = function (number, subtractNum) {
  var newDate = moment(number).subtract(subtractNum, 'seconds').format('YYYY-MM-DD HH:mm:ss')
  return newDate
}
Vue.prototype.orderNum = function (date) {
  var num = moment(date).format('YYYYMMDDHHmmss') + this.numRandom(10000, 90000)
  return num
}
Vue.prototype.payWayRandom = function () {
  var payWay = [1, 2, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 3]
  var num = payWay[this.numRandom(0, payWay.length - 1)]
  return num
}
Vue.prototype.moneyRandom = function () {
  var money = [10, 10, 10, 10, 5, 10, 10, 10, 5, 15, 10, 10, 15, 10, 10, 10, 10, 10, 20, 10, 10, 10, 30, 10, 10, 10, 50]
  var num = money[this.numRandom(0, money.length - 1)]
  return num
}
// 巡检计划状态
Vue.prototype.planStatus = function (status) {
  // 1：待开启 2：执行中 3：已终止 4：已结束 5：全部
  switch (status) {
    case 1:
      return '待开启'
    case 2:
      return '执行中'
    case 3:
      return '已终止'
    case 4:
      return '已结束'
    case 5:
      return '全部'
  }
}
// 巡检计划状态颜色
Vue.prototype.planStatusColor = function (status) {
  // 1：待开启 2：执行中 3：已终止 4：已结束 5：全部
  switch (status) {
    case 1:
      return '#E6A23C'
    case 2:
      return '#67C23A'
    case 3:
      return '#aaa'
    case 4:
      return '#aaa'
  }
}

// 巡检任务状态颜色
Vue.prototype.taskStatusColor = function (status) {
  // 1：待开启 2：执行中 3：已终止 4：已结束 5：全部
  switch (status) {
    case 1:
      return '#E6A23C'
    case 2:
      return '#67C23A'
    case 3:
      return '#aaa'
    case 4:
      return '#aaa'
    case 5:
      return '#aaa'
  }
}

// 巡检项执行状态颜色
Vue.prototype.dealStatusColor = function (status) {
  // 1：待巡检 4:已终止 5：已过期 6：执行中 7：已完成
  switch (status) {
    case 1:
      return '#E6A23C'
    case 6:
      return '#67C23A'
    case 4:
      return '#aaa'
    case 5:
      return '#aaa'
    case 7:
      return '#aaa'
  }
}
// 工单类型
Vue.prototype.exceptionType = function (status) {
  //  1：巡检任务 2：视频巡检 3：事件上报
  switch (status) {
    case 1:
      return '巡检任务'
    case 2:
      return '视频巡检'
    case 3:
      return '事件上报'
  }
}
// 工单状态
Vue.prototype.orderDealStatus = function (status) {
  //  0-待处理     1-已处理
  switch (status) {
    case 0:
      return '待处理 '
    case 1:
      return '已完成'
  }
}

// 工单状态颜色
Vue.prototype.orderDealStatusColor = function (status) {
  //  0-待处理     1-已处理
  switch (status) {
    case 0:
      return '#E6A23C'
    case 1:
      return '#aaa'
  }
}

// 上传图片前触发
Vue.prototype.beforeAvatarUpload = function (file) {
  const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png'
  const isLt1M = file.size / 1024 < 1024 * 5
  if (!isJPG) {
    this.$msg.error('图片只能是 jpg、jpeg、png 格式!')
    return false
  }
  if (!isLt1M) {
    this.$msg.error('图片大小不能超过 5MB!')
    return false
  }
  return true
}

// 上传图片前触发
Vue.prototype.beforeAvatarUpload2 = function (file) {
  const isJPG = file.type === 'image/gif' || file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png'
  const isLt1M = file.size / 1024 <= 1024 * 1
  if (!isJPG) {
    this.$msg.error('图片只能是 jpg、jpeg、png 格式!')
    return false
  }
  if (!isLt1M) {
    this.$msg.error('图片大小不能超过 1MB!')
    return false
  }
  return true
}
// 手机号中间4位转*
Vue.prototype.filterPhoneNumber = function (phoneNumber) {
  if (phoneNumber) return phoneNumber.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')
}
// 递归数据，若子节点为空则返回undefined
Vue.prototype.filterChildren = function (data) {
  var nodeChild = function (data) {
    data.forEach(item => {
      if (item.children && item.children.length > 0) {
        nodeChild(item.children)
      } else {
        item.children = undefined
      }
    })
    return data
  }
  return nodeChild(data)
}
// 根据权限判断是否展示按钮
Vue.prototype.isShowBtn = function (code) {
  var index = this.$store.state.menuList.currentBtnList.findIndex(item => item.menu_id === code)
  return index >= 0
}
export default Vue
