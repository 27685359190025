<template>
  <el-dialog :visible.sync="dialogVisible" :width="width" :style="{height:setHeight()}"  :fullscreen="fullscreen" :top="top" :modal="modal"
    :modal-append-to-body="modalAppendToBody" :append-to-body="appendToBody" :lock-scroll="lockScroll"
    :close-on-click-modal="closeOnClickModal" :close-on-press-escape="closeOnPressEscape" :show-close="false"
    :destroy-on-close="destroyOnClose" @close="closeHandle" @open="openHandle" class="lebo_dialog">
    <template slot="title">
      <div>
        <img src="../../assets/img/title.png" alt />
        <span>{{ title }}</span>
      </div>
      <div>
        <i :class="`iconfont ${fullscreen ? 'lebo-min' : 'lebo-max'}`" @click="toggleMaxDialog" v-if="showFullScreen"></i>
        <i class="iconfont lebo-guanbiicon" @click="closeHandle"></i>
      </div>
    </template>
    <slot></slot>
    <template slot="footer" v-if="!footerSlot">
      <lbButton type="write" icon="lebo-close" :fill="true" @click="closeHandle">取消</lbButton>
      <lbButton type="default" icon="lebo-confirm" :fill="true" @click="closeHandle">确定</lbButton>
    </template>
    <template slot="footer" v-else>
      <slot name="footer"></slot>
    </template>
  </el-dialog>
</template>
<script>
export default {
  name: 'lebo-dialog',
  props: {
    title: { type: String, required: true }, // 弹框标题（必填）
    isShow: { type: Boolean, required: true }, // 是否显示dialog（必填）
    width: { type: String, default: '50%' }, // 宽度
    top: { type: String, default: '10vh' }, //  margin-top
    modal: { type: Boolean, default: true }, // 是否需要遮罩层
    modalAppendToBody: { type: Boolean, default: true }, // 遮罩层是否插入至 body 元素上，若为 false，则遮罩层会插入至 Dialog 的父元素上
    appendToBody: { type: Boolean, default: true }, // Dialog 自身是否插入至 body 元素上。嵌套的 Dialog 必须指定该属性并赋值为 true
    lockScroll: { type: Boolean, default: true }, // 是否在 Dialog 出现时将 body 滚动锁定
    closeOnClickModal: { type: Boolean, default: false }, // 是否可以通过点击 modal 关闭 Dialog
    closeOnPressEscape: { type: Boolean, default: false }, // 是否可以通过按下 ESC 关闭 Dialog
    destroyOnClose: { type: Boolean, default: true }, // 关闭时销毁 Dialog 中的元素boolean—false
    footerSlot: { type: Boolean, default: false }, // 弹窗的底部是否是slot 默认 false
    showFullScreen: { type: Boolean, default: false }, // 是否显示全屏按钮
    height: { type: String }
  },
  data () {
    return {
      dialogVisible: this.isShow,
      fullscreen: false // 是否为全屏
    }
  },
  created () { },
  mounted () { },
  computed: {},
  watch: {
    isShow (newVal, oldVal) {
      // console.log('isShow---', newVal);
      this.dialogVisible = newVal
    }
  },
  methods: {
    // 关闭弹框
    closeHandle () {
      this.dialogVisible = false
      this.$emit('close')
    },
    // 打开弹框
    openHandle () {
      this.dialogVisible = true
      this.$emit('open')
    },
    // 弹框最大化
    toggleMaxDialog () {
      console.log('---', this.fullscreen)
      this.fullscreen = !this.fullscreen
    },
    // 点击确定按钮
    onConfirm () {
      this.$emit('onConfirm')
    },
    setHeight () {
      return this.height
    }
  }
}
</script>
<style scoped lang="less">
.lebo_dialog {
  // max-height: 800px;
  /deep/.el-dialog{
    border-radius:10px;

  }
  /deep/ .el-dialog__body {
    padding: 20px;
  }

  /deep/ .el-dialog__header {
    height: 50px;
    background-color: rgba(#01a6fb, 0.05);
    // box-shadow: 0px 5px 5px 0px rgba(1, 166, 251, 0.2);
    border-radius: 10px 10px 0px 0px;
    padding: 10px 20px;
    box-sizing: border-box;
    color: #01a6fb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    // font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: 600;
    color: #01A5FA;

    img {
      width: 20px;
      height: auto;
      margin-right: 10px;
    }

    .iconfont {
      color: #cccccc;
      margin-left: 15px;
      cursor: pointer;
    }
  }
}
</style>
