/**
 * 告警
 */

const warningList = [
  // 告警
  {
    path: '/device-warning',
    name: 'devicewarning',
    component: () => import('../view/maintenanceManagement/views/abnormalAlarm/devicewarning/index.vue')
  },
  // 告警 --- 违规停车 /illegal-parking
  {
    path: '/illegal-parking',
    name: 'illegalParking',
    component: () => import('../view/maintenanceManagement/views/abnormalAlarm/illegalParking/index.vue')
  },
  // 告警 ---轨迹异常  /trace-warning
  {
    path: '/trace-warning',
    name: 'traceWarning',
    component: () => import('../view/maintenanceManagement/views/abnormalAlarm/traceWarning/index.vue')
  },
  // 告警 ---人员异常  /person-warning
  {
    path: '/person-warning',
    name: 'personWarning',
    component: () => import('../view/maintenanceManagement/views/abnormalAlarm/personWarning/index.vue')
  }
]
export default warningList
