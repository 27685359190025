<template>
    <div>
        <div class="queryBox">
            <!-- 查询表单 -->
            <!-- 一行展示全 -->
            <template>
                <slot name="query-online"> </slot>
            </template>
            <!-- 有下拉框展示 -->
            <template>
                <div :class="['lebo-query', isMultiRow ? 'shadow_box' : '']">
                    <slot name="query-lines">
                    </slot>
                    <i :class="['open_box iconfont ', isOpen ? 'lebo-caret-top' : 'lebo-caret-bottom']" v-if="isMultiRow"
                        @click="toggleChange"></i>
                </div>
            </template>
        </div>
        <div class="mainBox">
            <!-- 主要表格部分 -->
            <div class="lebo-card">
                <div class="card_heard_box">
                    <div>
                        <span class="title">{{ title }}</span>
                        <slot name="heard-left"></slot>
                    </div>
                    <!-- 增删等按钮 -->
                    <slot name="heard-right"></slot>
                </div>
                <!-- 表格部分 -->
                <slot name="main-form">
                </slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'leForm',
  props: {
    isMultiRow: { type: Boolean, default: false }, // 点击展开收起图标
    title: { type: String, default: '报表' } // 表格标题
  },
  data () {
    return {
      isOpen: true
    }
  },
  created () { },
  mounted () {
    console.log(this.option)
  },
  computed: {},
  watch: {
  },
  methods: {
    toggleChange () {
      this.isOpen = !this.isOpen
      this.$emit('toggleChange', this.isOpen)
    }
  }
}
</script>
<style scoped lang="less">
.lebo-query {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: relative;
    padding: 0px 10px;

    .open_box {
        font-size: 12px;
        color: #cccccc;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 20px;
        background-color: #fff;
        position: absolute;
        left: 50%;
        bottom: -10px;
        transform: translate(-50%, 0);
        border-radius: 10px;
        box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
        cursor: pointer;

    }

}
.lebo-card {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(1, 1, 1, 0.1);
    padding: 10px;
    box-sizing: border-box;

    .card_heard_box {
        padding-bottom: 8px;
        border-bottom: solid 2px RGBA(204, 237, 254, .8);
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;

        .title {
            padding: 0px 10px;
            font-size: 16px;
            font-weight: 700;
            // font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            color: #01A5FA;
            border-left: 4px solid #01a6fb;
            border-radius: 3px;
        }
    }
}
.shadow_box {
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
}</style>
