/**
 * 安防
 */

const security = [
  // 人脸识别一体机
  {
    path: '/face-device',
    name: 'faceRecognition',
    component: () => import('../view/facialAccessControl/views/equipmentManagement/faceRecognition/index.vue')
  },
  // // 人脸识别一体机--查看
  // {
  //   path: '/look-face-recognition/:id',
  //   name: 'lookFaceRecognition',
  //   component: () => import('../view/security/lookFaceRecognition.vue'),
  //   props: true
  // },
  // 人脸识别一体机--配置设备
  // {
  //     path: '/face_device_config/:id',
  //     name: 'faceDeviceConfig',
  //     component: () => import('../view/security/faceDeviceConfig.vue'),
  //     props: true
  // },
  // {
  //   path: '/face_device_config',
  //   name: 'faceDeviceConfig',
  //   component: () => import('../view/security/faceDeviceConfig/index.vue')
  // },
  // 通行策略
  {
    path: '/access-group',
    name: 'accessStrategy',
    component: () => import('../view/facialAccessControl/views/accessStrategy/accessStrategy/index.vue')
  },
  // // 通行策略 -- 新增编辑
  // {
  //   path: '/access-strategy-edit/:id',
  //   name: 'accessStrategyEdit',
  //   component: () => import('../view/security/accessStrategyEdit.vue'),
  //   props: true
  // },
  // 出入记录
  {
    path: '/access-records',
    name: 'securityRecord',
    component: () => import('../view/facialAccessControl/views/accessRecords/securityRecord/index.vue')
  },
  // // 出入记录 -- 查看
  // {
  //   path: '/search-security-record/:id',
  //   name: 'searchSecurityRecord',
  //   component: () => import('../view/security/searchSecurityRecord.vue'),
  //   props: true
  // },
  // 以图搜图
  {
    path: '/face-search',
    name: 'faceSearch',
    component: () => import('../view/facialAccessControl/views/accessRecords/faceSearch/index.vue')
  },
  // 设备日志---设备日志
  {
    path: '/device-log',
    name: 'deviceLog',
    component: () => import('../view/facialAccessControl/views/deviceLog/deviceLog/index.vue')
  }
]
export default security
