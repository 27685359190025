/**
 * 行政
 */

const administration = [
  // 组织管理 -- 组织
  {
    path: '/organization-manage',
    name: 'organizationManage',
    component: () => import('../view/humanResourcesAdministrationManagement/views/organizationalManagement/organization/index.vue')
  },
  // 人员管理 -- 组织成员
  {
    path: '/organization-member',
    name: 'organizationMember',
    component: () => import('../view/humanResourcesAdministrationManagement/views/personnelManagement/organizationMember/index.vue')
  },
  // // 人员管理 -- 组织成员 -- 新增
  // {
  //   path: '/add-organization-member',
  //   name: 'addOrganizationMember',
  //   component: () => import('../view/administration/personManagement/addOrganizationMember.vue')
  // },
  // // 人员管理 -- 组织成员 -- 查看
  // {
  //   path: '/look-organization-member/:id',
  //   name: 'lookOrganizationMember',
  //   component: () => import('../view/administration/personManagement/lookOrganizationMember.vue'),
  //   props: true
  // },
  // 人员管理 -- 黑名单
  {
    path: '/organization-blacklist',
    name: 'organizationBlacklist',
    component: () => import('../view/humanResourcesAdministrationManagement/views/personnelManagement/organizationBlacklist/index.vue')
  },
  // 人员管理 -- 人员审核
  {
    path: '/person-audit',
    name: 'personAudit',
    component: () => import('../view/humanResourcesAdministrationManagement/views/personnelManagement/personAudit/index.vue')
  },
  // 访客管理  —— 来访人员
  {
    path: '/visitor-person',
    name: 'visitorPerson',
    component: () => import('../view/humanResourcesAdministrationManagement/views/visitorManagement/visitorPerson/index.vue')
  },
  // // 访客管理  —— 来访人员 -- 新增
  // {
  //   path: '/add-visitor-person',
  //   name: 'addVisitorPerson',
  //   component: () => import('../view/administration/visitorManage/addVisitorPerson.vue')
  // },
  // // 访客管理  —— 来访人员 -- 查看
  // {
  //   path: '/look-visitor-person/:id',
  //   name: 'lookVisitorPerson',
  //   component: () => import('../view/administration/visitorManage/lookVisitorPerson.vue'),
  //   props: true
  // },
  // 访客管理  —— 访客审核  /visitor-check
  {
    path: '/visitor-check',
    name: 'visitorCheck',
    component: () => import('../view/humanResourcesAdministrationManagement/views/visitorManagement/visitorCheck/index.vue'),
    props: true
  },
  // // 访客管理  —— 访客审核详情  /look-visitor-check
  // {
  //   path: '/look-visitor-check',
  //   name: 'lookVisitorCheck',
  //   component: () => import('../view/administration/visitorManage/lookVisitorCheck.vue')
  // },
  // 访客管理  —— 访客登记 /visitor-register
  {
    path: '/visitor-register',
    name: 'visitorRegister',
    component: () => import('../view/humanResourcesAdministrationManagement/views/visitorManagement/visitorRegister/index.vue'),
    props: true
  },
  // 巡检员管理 --  巡检人员 process inspector
  {
    path: '/process-inspector',
    name: 'processInspector',
    component: () => import('../view/systemManagement/views/accountManagement/processInspector/index.vue')
  },
  // 账号管理 --  个人账号 personal account
  {
    path: '/personal-account',
    name: 'personalAccount',
    component: () => import('../view/systemManagement/views/accountManagement/personalAccount/index.vue')
  },
  // 巡检员管理 -- 异常处理 exception-handling
  {
    path: '/exception-handling',
    name: 'exceptionHandling',
    component: () => import('../view/maintenanceManagement/views/dataVerification/exceptionHandling/index.vue')
  },
  // // 巡检员管理 -- 异常处理详情 exception-details
  // {
  //   path: '/exception-details/:id',
  //   name: 'exceptionDetails',
  //   component: () => import('../view/administration/processInspector/exceptionDetails.vue'),
  //   props: true
  // },
  // 考勤打卡 -- 考勤规则   /checking-in-rule
  {
    path: '/checking-in-rule',
    name: 'checkingInRule',
    component: () => import('../view/humanResourcesAdministrationManagement/views/attendanceClockIn/checkingInRule/index.vue'),
    props: true
  },
  // // 考勤打卡 -- 新增考勤规则   /add-checking-in-rule
  // {
  //   path: '/add-checking-in-rule',
  //   name: 'addCheckingInRule',
  //   component: () => import('../view/administration/attendanceClock/addCheckingInRule.vue'),
  //   props: true
  // },
  // 考勤打卡 -- 区域规则   /area-rule
  {
    path: '/area-rule',
    name: 'areaRule',
    component: () => import('../view/humanResourcesAdministrationManagement/views/attendanceClockIn/areaRule/index.vue'),
    props: true
  },
  // // 考勤打卡 -- 新增区域规则   /add-area-rule
  // {
  //   path: '/add-area-rule/:id',
  //   name: 'addAreaRule',
  //   component: () => import('../view/administration/attendanceClock/addAreaRule.vue'),
  //   props: true
  // },
  // 考勤打卡 --  考勤组     /checking-in-group
  {
    path: '/checking-in-group',
    name: 'checkingInGroup',
    component: () => import('../view/humanResourcesAdministrationManagement/views/attendanceClockIn/checkingInGroup/index.vue'),
    props: true
  },
  // 考勤打卡 --  考勤统计  /checking-in-statistics
  {
    path: '/checking-in-statistics',
    name: 'checkingInStatistics',
    component: () => import('../view/humanResourcesAdministrationManagement/views/attendanceClockIn/checkingInStatistics/index.vue'),
    props: true
  },
  // 考勤打卡 --  打卡记录 process-sign
  {
    path: '/process-sign',
    name: 'processSign',
    component: () => import('../view/humanResourcesAdministrationManagement/views/attendanceClockIn/processSign/index.vue')
  },
  // // 考勤打卡 -- 打卡详情 sign-details
  // {
  //   path: '/sign-details',
  //   name: 'signDetails',
  //   component: () => import('../view/administration/attendanceClock/signDetails.vue')
  // },
  //  电子围栏---- 围栏策略  /rail-sstrategy
  {
    path: '/rail-sstrategy',
    name: 'railStrategy',
    component: () => import('../view/humanResourcesAdministrationManagement/views/electronicFence/railStrategy/index.vue')
  },
  // //  电子围栏---- 围栏策略 -- 新增策略  /add-generate-fence
  // {
  //   path: '/add-generate-fence/:id',
  //   name: 'addoreditElectricFence',
  //   component: () => import('../view/administration/electronicFence/addoreditElectricFence.vue'),
  //   props: true
  // },
  //  电子围栏---- 行为轨迹  /behavior-trace
  {
    path: '/behavior-trace',
    name: 'behaviorTrace',
    component: () => import('../view/humanResourcesAdministrationManagement/views/electronicFence/behaviorTrace/index.vue')
  },
  //  车主管理 ---- 车主  /owner-management
  {
    path: '/owner-management',
    name: 'ownermanagement',
    component: () => import('../view/systemManagement/views/accountManagement/ownermanagement/index.vue')
  }
  // // 车主管理 -  查看
  // {
  //   path: '/search-owner-management/:id',
  //   name: 'searchownermanagement',
  //   component: () => import('../view/administration/ownermanage/searchownermanagement.vue'),
  //   props: true
  // }
]
export default administration
