/**
 * 觉悟大模型
 */

const juewuAiChatList = [
  // 觉悟大模型
  {
    path: '/miracle-chat',
    name: 'miracleChat',
    component: () => import('../view/homeNavigation/views/miracleChat/index.vue'),
    children: [
      // 新增对话
      {
        path: '/miracle-chat',
        name: 'addnewchat',
        component: () => import('../view/homeNavigation/views/miracleChat/components/addNewChat/index.vue')
      },
      // 新增智囊团
      {
        path: '/add-brain-trust',
        name: 'addbraintrust',
        component: () => import('../view/homeNavigation/views/miracleChat/components/addBrainTrust/index.vue')
      },
      // 普通大模型对话
      {
        path: '/commone-chat',
        name: 'commoneChat',
        component: () => import('../view/homeNavigation/views/miracleChat/modelComponents/commoChatModel/index.vue')
      },
      // 智囊团对话
      {
        path: '/brain-chat',
        name: 'brainChat',
        component: () => import('../view/homeNavigation/views/miracleChat/modelComponents/brainChatModel/index.vue')
      }
    ]
  }

]
export default juewuAiChatList
